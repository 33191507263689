import { createSlice } from '@reduxjs/toolkit'

const BookAvailability = createSlice({
  name: 'BookAvailability',
  initialState: {
    firstDay: '',
    spotsLeft: 0,
    loaded: false,
    timeLeft: {
      hours: 0,
      minutes: 0
    }
  },
  reducers: {
    setAvailability: (state, action) => {
      state.firstDay = action.payload.firstDay
      state.spotsLeft = action.payload.spotsLeft
      state.timeLeft = action.payload.timeLeft
      state.loaded = true
    },
    setLoaded: (state) => {
      state.loaded = true
    }
  }
})

export const { setAvailability, setLoaded } = BookAvailability.actions
export default BookAvailability.reducer
