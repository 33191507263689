import { createContext, useContext, useState, ReactNode } from 'react'

export type mobileMenuContext = {
  isActive: boolean,
  toggle(): void,
  hide(): void
}

const mobileMenuContextDefaultValue: mobileMenuContext = {
  isActive: false,
  toggle() {
    this.isActive ? this.isActive = false : this.isActive = true
  },
  hide() {
    this.isActive = false
  }
}

const MobileMenuContext = createContext<mobileMenuContext>(mobileMenuContextDefaultValue)

export function useMobileMenu() {
  return useContext(MobileMenuContext)
}

type Props = {
  children: ReactNode
}

export function MobileMenuProvider({ children }: Props) {
  const [isActive, setStatus] = useState<boolean>(false)

  const toggle = () => {
    isActive ? setStatus(false) : setStatus(true)
  }

  const hide = () => {
    setStatus(false)
  }

  const value = {
    isActive,
    toggle,
    hide
  }

  return (
    <>
      <MobileMenuContext.Provider value={value}>
        {children}
      </MobileMenuContext.Provider>
    </>
  )
}
