import { createSlice } from '@reduxjs/toolkit'

const numberOfClients = createSlice({
  name: 'numberOfClients',
  initialState: {
    clients: 4652,
    loaded: false
  },
  reducers: {
    setNumberOfClients: (state, action) => {
      state.clients = action.payload
      state.loaded = true
    },
    setLoaded: (state) => {
      state.loaded = true
    }
  }
})

export const { setNumberOfClients, setLoaded } = numberOfClients.actions
export default numberOfClients.reducer
