import { fetchWithTimeoutErrorHandling } from '@/utils/http'

export class ReferralService {
  static async check(referralId: string, referralToken: string, plan: string) {
    const res = await fetchWithTimeoutErrorHandling(`${process.env.NEXT_PUBLIC_BACKEND_URL}/referral/check`, {
      body: JSON.stringify({
        plan: plan,
        referralId: referralId,
        referralToken: referralToken
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST'
    })

    return res
  }
}
