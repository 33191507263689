import styles from '@/styles/components/accordion/AccordionNew.module.scss'
import { AccordionNewItem } from './AccordionNewItem'

interface Props {
  items: Item[],
}

interface Item {
  header: JSX.Element,
  body: JSX.Element,
  active: boolean,
  onChange?: (active: boolean, el?: HTMLElement | null) => void
}

export const AccordionNew: React.FC<Props> = ({ items }) => {
  return (
    <div className={styles.accordion_new}>
      {
        items.map((item, i) => {
          return (
            <AccordionNewItem key={i} {...item}/>
          )
        })
      }
    </div>
  )
}
