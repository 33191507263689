import { createSlice } from '@reduxjs/toolkit'

const cardComponent = createSlice({
  name: 'cardComponent',
  initialState: {
    ready: false,
  },
  reducers: {
    setReady: (state) => {
      state.ready = true
    }
  }
})

export const { setReady } = cardComponent.actions
export default cardComponent.reducer
