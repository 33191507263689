import styles from '@/styles/components/cta/DiscountBadge.module.scss'
import { useEffect, useState } from 'react'
import { emitter } from '@/utils/emitter'
import { CrossBtn } from '@/components/controls/CrossBtn'
import { Button } from '@/components/controls/Button'
import { useQuery } from '@/utils/url'
import { Headline2 } from '@/components/common/Headline2'
import { setReferralIfAvailable } from '@/store/actions/checkout'
import { useDispatch, useSelector } from 'react-redux'
import { setClosedState } from '@/store/slices/discountBadge'
import { RootState } from '@/store/reducers'
import { CSSTransition } from 'react-transition-group'

export const DiscountBadge = () => {
  const [delayDone, setDelayDone] = useState(false)
  const checkoutState = useSelector((state: RootState) => state.checkout)
  const badgeState = useSelector((state: RootState) => state.discountBadge)
  const checkoutLink = useQuery('/checkout')
  const dispatch = useDispatch()

  const closeHandler = () => {
    dispatch(setClosedState(true))
    emitter.emit('discount-badge-closed')
  }

  const showDiscountIfAvailable = async () => {
    if (!checkoutState.referral.referralId && !checkoutState.referral.loaded) {
      const action = await setReferralIfAvailable()
      dispatch(action)

      return
    }
  }

  useEffect(() => {
    const checkAvailability = async () => {
      await showDiscountIfAvailable()
    }
    checkAvailability()

    setTimeout(() => {
      setDelayDone(true)
    }, 1000)
  }, [])

  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      appear={checkoutState.referral.available && !badgeState.closed && delayDone}
      in={checkoutState.referral.available && !badgeState.closed && delayDone}
      timeout={1000}
      classNames="fade-up"
    >
      <div className={styles.discount_badge_wrapper}>
        <div className={styles.discount_badge}>
          <CrossBtn className={styles.discount_badge_close} onClick={() => {
            closeHandler()
          }}/>
          {!checkoutState.referral.gift && <Headline2 tag="h3" className="text-[36px]" color="white">{checkoutState.referral.discount.monthly.toFixed(2)}$ <em className="font--primary font-extralight">off!</em></Headline2>}
          {checkoutState.referral.gift && <Headline2 tag="h3" className="text-[26px] mb-[10px]" color="white"><em className="font--primary font-extralight">Your first month free <br/></em>(${checkoutState.referral.discount.monthly.toFixed(2)} value)</Headline2>}
          <p className="mb-[20px]"><em>Thanks to {checkoutState.referral.referralName}</em></p>
          <div className="flex justify-center">
            <Button disableArrow={true} height="sm" href={checkoutLink} type="black" onClick={() => { emitter.emit('discount-badge-button-clicked') }}>Go to Payment Page</Button>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}
