export class Utk {
  uuid() {
    if (window.navigator.userAgent.indexOf('googleweblight') > -1) return this._mathRandomUuid()
    //@ts-ignore
    const t = window.crypto || window.msCrypto

    return void 0 !== t && void 0 !== t.getRandomValues && void 0 !== window.Uint16Array ? this._cryptoUuid() : this._mathRandomUuid()
  }

  _mathRandomUuid() {
    let t = new Date().getTime()

    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (e) {
      const i = (t + 16 * Math.random()) % 16 | 0
      t = Math.floor(t / 16)

      return ('x' === e ? i : (3 & i) | 8).toString(16)
    })
  }

  _cryptoUuid() {
    //@ts-ignore
    const t = window.crypto || window.msCrypto, e = new Uint16Array(8)
    t.getRandomValues(e)

    const i = function (t: number) {
      let a = t.toString(16)
      for (; a.length < 4; ) a = '0' + a
      return a
    }

    //@ts-ignore
    return i(e[0]) + i(e[1]) + i(e[2]) + i(e[3]) + i(e[4]) + i(e[5]) + i(e[6]) + i(e[7])
  }
}
