import queryString from 'query-string'
import { ReferralService } from '@/services/referral'
import { CouponService } from '@/services/coupon'
import { handleResponse } from '@/utils/http'

export type StepOneCheckoutType = {
  firstName: string,
  lastName: string,
  email: string
}

export type PhoneDataType = {
  phone: string,
  phoneCountryCode: string
}

export type StepThreeCheckoutType = {
  address1?: string,
  address2?: string,
  zip?: string,
  region?: string,
  city?: string
}

export type Prices = {
  annual: number,
  monthly: number
}

export const setPhoneIsVerified = (value: boolean) => {
  return {
    type: 'SET_PHONE_IS_VERIFIED',
    payload: value
  }
}

export const setPhoneVerificationSkipped = () => {
  return {
    type: 'SET_PHONE_VERIFICATION_SKIPPED',
    payload: true
  }
}

export const setPhoneData = (payload: PhoneDataType) => {
  return {
    type: 'SET_PHONE_DATA',
    payload: payload
  }
}

export const setPhoneFullData = (payload: string) => {
  return {
    type: 'SET_PHONE_FULL_DATA',
    payload: payload
  }
}

export const setStepOneCheckout = (payload: StepOneCheckoutType) => {
  return {
    type: 'SET_STEP_ONE_DATA',
    payload: payload
  }
}

export const setStepTwoCheckout = (plan: string) => {
  return {
    type: 'SET_STEP_TWO_DATA',
    payload: plan
  }
}

export const setStepTwoCheckoutSetted = () => {
  return {
    type: 'SET_STEP_TWO_SETTED',
    payload: true
  }
}

export const setStepThreeCheckout = (payload: StepThreeCheckoutType) => {
  return {
    type: 'SET_STEP_THREE_DATA',
    payload: payload
  }
}

export const setTouchedStep = (number: number) => {
  return {
    type: 'SET_TOUCHED_STEP',
    payload: number
  }
}

export const setPrices = (payload: Prices) => {
  return {
    type: 'SET_PRICES',
    payload: payload
  }
}

export const setCountry = (payload: { label: string, value: string }) => {
  return {
    type: 'SET_COUNTRY',
    payload: payload
  }
}

export const setState = (payload: { label: string, value: string }) => {
  return {
    type: 'SET_STATE',
    payload: payload
  }
}

export const setRegion = (payload: string) => {
  return {
    type: 'SET_REGION',
    payload: payload
  }
}

export const setChargebeeLoaded = () => {
  return {
    type: 'SET_CHARGEBEE_LOADED',
    payload: true
  }
}

export const setEmailIsValidating = (payload: boolean) => {
  return {
    type: 'SET_EMAIL_IS_VALIDATING',
    payload: payload
  }
}

export const activateStep = (step: number) => {
  return {
    type: 'SET_ACTIVE_STEP',
    payload: step
  }
}

export const setProgress = (val: number) => {
  return {
    type: 'SET_PROGRESS',
    payload: val
  }
}

export const setFastSignupUsed = () => {
  return {
    type: 'SET_FAST_SIGNUP_USED'
  }
}

export const setEmailRecheckTrue = () => {
  return {
    type: 'SET_EMAIL_RECHECK_TRUE'
  }
}

export const setEmailRecheckFalse = () => {
  return {
    type: 'SET_EMAIL_RECHECK_FALSE'
  }
}

export const setReferralIfAvailable = async () => {
  const query = queryString.parse(location.search)

  if (query.utm_campaign === 'gift' || query.utm_campaign === 'referral' && query.utm_source && query.token) {
    const res = await ReferralService.check(
      Array.isArray(query.utm_source) ? query.utm_source[0] || '' : query.utm_source || '',
      Array.isArray(query.token) ? query.token[0] || '' : query.token || '',
      query.utm_campaign === 'gift' ? 'referral': ''
    )

    const buildName = (value: string | (string | null)[] | null) => {
      if (Array.isArray(value)) {
        return value[0] ? value[0].replace(/_/g, ' ') : 'Anonymous'
      }
      return value ? value.replace(/_/g, ' ') : 'Anonymous'
    }

    try {
      const handledResponse = await handleResponse(res) as any

      if (handledResponse.available) {
        return {
          type: 'SET_REFERRAL',
          payload: {
            ...res,
            referralToken: query.token,
            available: true,
            referralName: buildName(query.content),
            loading: false,
            loaded: true,
            gift: query.utm_campaign === 'gift',
            discount: {
              monthly: res.discount.monthly,
              annual: res.discount.annual
            }
          }
        }
      }

      return {
        type: 'SET_REFERRAL',
        payload: {
          loading: false,
          loaded: true,
          gift: false,
          available: false,
          referralId: null,
          referralToken: null,
          referralName: null,
          discount: {
            monthly: 0,
            annual: 0
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    type: 'SET_REFERRAL',
    payload: {
      loading: false,
      loaded: true,
      gift: false,
      available: false,
      referralId: null,
      referralToken: null,
      referralName: null,
      discount: {
        monthly: 0,
        annual: 0
      }
    }
  }
}

export const setCouponIfAvailable = async (value?: string) => {
  const query = queryString.parse(location.search)

  if (query.coupon || value) {
    let res, res2

    if (value) {
      res = await CouponService.check(
        value,
        'monthly'
      )
    } else {
      res = await CouponService.check(
        Array.isArray(query.coupon) ? query.coupon[0] || '' : query.coupon || '',
        'monthly'
      )
      res2 = await CouponService.getMeta(Array.isArray(query.coupon) ? query.coupon[0] || '' : query.coupon || '')
    }

    try {
      const handledResponse = await handleResponse(res) as any

      if (!handledResponse.available) {
        return {
          type: 'SET_COUPON',
          payload: {
            loading: false,
            available: false,
            value: null,
            annualDiscount: null,
            monthlyDiscount: null,
            meta: {}
          }
        }
      }

      if (handledResponse.available) {
        return {
          type: 'SET_COUPON',
          payload: {
            loading: false,
            available: true,
            value: handledResponse.coupon,
            annualDiscount: handledResponse.annualDiscount,
            monthlyDiscount: handledResponse.monthlyDiscount,
            meta: res2
          }
        }
      }
    } catch (error) {
      console.error(error)

      return {
        type: 'SET_COUPON',
        payload: {
          loading: false,
          available: false,
          value: null,
          annualDiscount: null,
          monthlyDiscount: null,
          meta: {}
        }
      }
    }
  }

  return {
    type: 'SET_COUPON',
    payload: {
      loading: false,
      available: false,
      value: null,
      annualDiscount: null,
      monthlyDiscount: null,
      meta: {}
    }
  }
}
