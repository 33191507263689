import { toast } from 'react-toastify'
import fetchRetry from 'fetch-retry'

export function handleResponse(response: any, successMessage?: string, showSuccess?: boolean) {
  return new Promise((resolve, reject) => {
    if (!response) {
      const error = 'No response from server. Please contact us and we will resolve this issue'

      toast.error(error)

      reject(error)

      return
    }

    if (response.error || response.statusCode >= 500 || response.statusCode >= 400) {
      if (response.message) {
        toast.error(Array.isArray(response.message) ? response.message[0] : response.message)
      } else {
        toast.error(response.error)
      }

      reject(response.error || response.message)

      return
    }

    if (successMessage) {
      toast(response.message || successMessage)
    }

    if (showSuccess) {
      if (response.message || successMessage) {
        toast(response.message || successMessage)
      }
    }

    resolve(response)
  })
}

interface Options {
  body?: any,
  method?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE' | 'HEAD',
  timeout?: number,
  headers?: any
}

export async function fetchWithTimeout(resource: string, options: Options) {
  const timeout = options.timeout || 8000

  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  })

  clearTimeout(id)

  return response
}

export async function fetchWithTimeoutErrorHandling(resource: string, options: Options) {
  try {
    const res = await fetchWithTimeout(resource, options)
    const json = await res.json()

    return json
  } catch (error: any) {
    if (error.name === 'AbortError') {
      return { error: 'Ohh, server not responding! Try to reload a page and repeat. Or contact us.' }
    }
  }
}

export async function fetchWithRetries(url: string,  options?: Options, retries = 3, delay = 400) {
  const timeout = options?.timeout || 8000
  const controller = new AbortController()
  let id: number

  const fetchWrapped = fetchRetry(fetch, {
    retries: retries,
    retryDelay: delay,
    retryOn(attempt: number, error: any, response: any) {
      if (id) clearTimeout(id)
      id = window.setTimeout(() => controller.abort(), timeout)

      if (attempt > retries - 1) return false
      if (!response) return true

      if (error || response.status >= 400) {
        return true
      }
    }
  })

  try {
    const res = await fetchWrapped(url, {
      ...options,
      signal: controller.signal
    })
    const json = await res.json()

    return json
  } catch (error) {
    console.error('fetchWithRetries', error)
  }
}
