import { scrollToAnimationLess } from '@/utils/scroll'
import { useRouter } from 'next/router'

export const useScrollToSection = (id: string, pathname = '', page = '/') => {
  const router = useRouter()

  const scrollHandler = () => {
    setTimeout(() => {
      scrollToAnimationLess(id)
      router.events.off('routeChangeComplete', scrollHandler)
    }, 100)
  }

  return (e: any) => {
    e.preventDefault()

    if (router.pathname === pathname || router.pathname === '/') {
      scrollToAnimationLess(id)
      return
    }

    router.push({
      pathname: pathname + page,
      query: router.query
    })

    router.events.on('routeChangeComplete', scrollHandler)
  }
}
