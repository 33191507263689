import styles from '@/styles/components/accordion/AccordionNew.module.scss'
import { useRef, useState } from 'react'

interface Props {
  header: JSX.Element,
  body: JSX.Element,
  active: boolean,
  onChange?: (active: boolean, el?: HTMLElement | null) => void
}

export const AccordionNewItem: React.FC<Props> = ({
  header,
  body,
  active,
  onChange
}) => {
  const [ height, setHeight ] = useState(0)
  const [ isActive, setActive ] = useState(active)
  const bodyRef = useRef<HTMLElement>(null)
  const itemRef = useRef<HTMLDListElement>(null)

  const toggle = () => {
    if (!isActive) {
      if (bodyRef.current) {
        setHeight(bodyRef.current.scrollHeight)
      }
    } else {
      setHeight(0)
    }

    setActive((prevState) => {
      onChange && onChange(!prevState, itemRef.current)

      return !prevState
    })
  }

  return (
    <dl className={styles.accordion_new__item} ref={itemRef}>
      <dt onClick={toggle} className={styles.accordion_new__title}>
        {header}
      </dt>
      <dd ref={bodyRef} className={styles.accordion_new__body} style={{height: height + 'px'}}>
        {body}
      </dd>
    </dl>
  )
}
