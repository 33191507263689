import Link from 'next/link'
import { RefObject } from 'react'

interface Props {
  id?: string,
  type: string,
  className?:  string,
  maxWidth?: number,
  fontSize?: number,
  textCenter?: boolean,
  bg?: string,
  fullWidth?: boolean,
  boldFont?: boolean,
  backArrow?: boolean,
  borderBgSec?: boolean,
  padding?: string,
  rawPadding?: string,
  height?: 'sm' | 'lg',
  textStyle?: React.CSSProperties,
  target?: string,
  isSubmit?: boolean,
  disabled?: boolean,
  disableArrow?: boolean,
  chevron?: boolean,
  secondArrow?: boolean,
  smallArrow?: boolean,
  noWrap?: boolean,
  customElement?: JSX.Element,
  buttonRef?: RefObject<HTMLButtonElement>,
  href?: string | { pathname: string, query: {[key: string]: string | string[] | undefined} },
  onClick?(e: any | undefined): void
}

export const Button: React.FC<Props> = ({
  id,
  className = '',
  padding,
  rawPadding = '',
  height = '',
  bg = '',
  href,
  type,
  onClick,
  maxWidth,
  children,
  fontSize,
  customElement,
  target = '',
  backArrow = false,
  disabled = false,
  borderBgSec = false,
  textCenter = false,
  isSubmit = false,
  fullWidth = false,
  secondArrow = false,
  disableArrow = false,
  noWrap = false,
  smallArrow = false,
  chevron = false,
  boldFont = false,
  buttonRef = undefined,
  textStyle = undefined
}) => {
  const style = {} as any
  let textClasses = 'flex w-full items-center justify-between'

  const renderArrow = () => {
    if (disableArrow) return null

    if (secondArrow) {
      return (
        <span className="button__arrow_sec">
          <span></span>
          <span></span>
        </span>
      )
    }

    if (customElement) {
      return customElement
    }

    return <span className={`button__arrow ${smallArrow ? 'button__arrow_small' : ''} ${chevron ? 'button__chevron' : ''}`}></span>
  }

  if (maxWidth) {
    style.maxWidth = maxWidth
  }

  if (rawPadding) {
    style.padding = rawPadding
  }

  if (fontSize) {
    style.fontSize = fontSize + 'px'
  }

  if (textCenter) {
    textClasses = 'flex justify-center'
  }

  if (boldFont) {
    textClasses += ' button--bold-font'
  }

  if (noWrap) {
    textClasses += ' whitespace-nowrap'
  }

  if (bg) {
    style.backgroundColor = bg
  }

  if (href) {
    return <Link href={href} passHref>
      <a style={style}
        target={target || undefined}
        className={
          `${className}
          button--link
          ${fullWidth ? 'button--full-width' : ''}
          ${style.maxWidth ? 'button--max-w' : ''}`
        }
        id={id || undefined}
      >
        <button
          ref={buttonRef}
          type={isSubmit ? 'submit' : undefined}
          style={style}
          className={
            `button button--${type}
            ${textCenter ? '!justify-center' : ''}
            ${borderBgSec ? 'button--border-bg-sec' : ''}
            ${height ? 'button--' + height + '-height' : ''}
            ${padding ? 'button--' + padding + '-pad' : ''}
            ${fullWidth ? 'button--full-width' : ''}
            ${style.maxWidth ? 'button--max-w' : ''}`
          }
          onClick={onClick}
          disabled={disabled}>
          <span className={textClasses} style={textStyle}>
            {children}
            { !disableArrow && <span className={`button__arrow ${backArrow ? 'button__arrow-back' : ''} ${smallArrow ? 'button__arrow_small' : ''} ${chevron ? 'button__chevron' : ''}`}></span> }
          </span>
        </button>
      </a>
    </Link>
  }

  return <button
    ref={buttonRef}
    id={id || undefined}
    type={isSubmit ? 'submit' : undefined}
    style={style}
    className={
      `button button--${type}
      ${borderBgSec ? 'button--border-bg-sec' : ''}
      ${textCenter ? '!justify-center' : ''}
      ${height ? 'button--' + height + '-height' : ''}
      ${padding ? 'button--' + padding + '-pad' : ''}
      ${fullWidth ? 'button--full-width' : ''}
      ${className}
      ${style.maxWidth ? 'button--max-w' : ''}`
    }
    onClick={onClick}
    disabled={disabled}>
    <span className={`${textClasses} items-center`} style={textStyle}>
      {children}
      { renderArrow() }
    </span>
  </button>
}
