import Logo from '@/components/common/Logo'
import Link from 'next/link'
import ConditionalRender from '@/components/utils/ConditionalRender'
import { MobileMenu } from '@/components/modal/MobileMenu'
import { buildQueryString, useQuery } from '@/utils/url'
import { useScrollToSection } from '@/hooks/scrollToSection'
import { emitter } from '@/utils/emitter'

interface Props {
  transparent?: boolean,
  paddingBottomOnly?: boolean
}

export default function Footer({ transparent = false, paddingBottomOnly = false }: Props) {
  const scienceLink = useQuery('#hard-science-open')

  return (
    <>
      <footer className={`${transparent ? 'site-footer--transparent' : ''} site-footer ${paddingBottomOnly ? 'pb-8' : 'pt-8 pb-8 larger:pb-14 larger:pt-14'}  bg--primary`}>
        <div className="container flex justify-between lg:items-center">
          <nav className="order-2">
            <div className="nav nav--secondary flex">
              <div className="mr-[15px] tiny2:mr-[20px] lg:mr-0 lg:flex">
                <div className="nav__item nav__item--secondary hover-underline-animation">
                  <a className="flex items-center" href="/" onClick={useScrollToSection('why-section')}><span className="arrow-right"></span><span>How It Works</span><span className="dot"></span></a>
                </div>
                <div className="nav__item nav__item--secondary hover-underline-animation">
                  <span className="flex items-center"><span className="arrow-right"></span><Link href={useQuery('/terms-of-service')}>Terms &amp; Conditions</Link><span className="dot"></span></span>
                </div>
                <div className="nav__item nav__item--secondary hover-underline-animation">
                  <span className="flex items-center"><span className="arrow-right"></span><Link href={useQuery('/payment-policy')}>30 Day Guarantee</Link><span className="dot"></span></span>
                </div>
              </div>
              <div className="lg:flex">

                <div className="nav__item nav__item--secondary hover-underline-animation">
                  <span className="flex items-center"><span className="arrow-right"></span><Link href={useQuery('/privacy-policy')}>Privacy Policy</Link><span className="dot"></span></span>
                </div>
                <div className="nav__item nav__item--secondary hover-underline-animation">
                  <span className="flex items-center"><span className="arrow-right"></span><Link href={useQuery('/careers')}>Careers</Link><span className="dot"></span></span>
                </div>
                <div className="nav__item nav__item--secondary hover-underline-animation">
                  <span className="flex items-center"><span className="arrow-right"></span><Link href={useQuery('/contact')}>Contact</Link></span>
                </div>
              </div>
            </div>
          </nav>
          <div className="flex flex-col items-start order-1 w-[100px] xl:w-auto mr-[15px] mt-[-7px]">
            <ConditionalRender server>
              <Logo isVertical={false} className='scale-[0.74] xl:scale-[1] origin-left'/>
            </ConditionalRender>
            <ConditionalRender client>
              <Logo isVertical={false} className='scale-[0.74] xl:scale-[1] origin-left'/>
            </ConditionalRender>
            <p className="site-footer__copyright mt-1 larger:mt-2">Commit Action © {(new Date).getFullYear()}</p>
          </div>
        </div>
      </footer>
      <MobileMenu/>
    </>
  )
}
