import cn from 'classnames'
import { CSSProperties } from 'react'

interface Props {
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
  className?: string,
  color?: 'primary' | 'secondary' | 'white' | 'black',
  weight?: 200 | 400 | 500 | 600 | 700 | 800,
  style?: CSSProperties,
  fontPrimary?: boolean
}

export const Headline2: React.FC<Props> = ({
  tag,
  className = '',
  children,
  color,
  weight,
  style,
  fontPrimary = false
}) => {
  const CustomTag = tag

  const classesItem = cn({
    'headline2__item': true,
    'headline2__font_primary': fontPrimary,
    ['headline2_color_' + color]: color,
    ['headline2_weight_' + weight]: weight,
  })

  return (
    <div className={`headline2 ${className}`} style={style || {}}>
      <CustomTag className={classesItem}>{ children }</CustomTag>
    </div>
  )
}
