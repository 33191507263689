import { createSlice } from '@reduxjs/toolkit'

const clientLocation = createSlice({
  name: 'clientLocation',
  initialState: { country_code: '', country_name: '', calling_code: '', is_eu: false, loaded: false, time_zone: {name: '', offset: '', abbr: ''} },
  reducers: {
    setClientLocation: (state, action) => {
      state.country_code = action.payload.country_code
      state.country_name = action.payload.country_name
      state.calling_code = action.payload.calling_code
      state.is_eu = action.payload.is_eu
      state.time_zone = action.payload.time_zone
      state.loaded = true
    }
  }
})

export const { setClientLocation } = clientLocation.actions
export default clientLocation.reducer
