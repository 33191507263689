import { createSlice } from '@reduxjs/toolkit'

const discountBadge = createSlice({
  name: 'discountBadge',
  initialState: {
    closed: false,
  },
  reducers: {
    setClosedState: (state, action) => {
      state.closed = action.payload
    }
  }
})

export const { setClosedState } = discountBadge.actions
export default discountBadge.reducer
