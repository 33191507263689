import { createSlice } from '@reduxjs/toolkit'

const freeCallLead = createSlice({
  name: 'freeCallLead',
  initialState: {
    step: 1,
    firstName: '',
    lastName: '',
    email: '',
    phoneLocal: '',
    phone: '',
    phoneCountryCode: '',
    comment: '',
    duration: 45,
    timezone: {
      value: '',
      label: ''
    },
    availableTime: [],
    availableTimeLoaded: false,
    selectedClassAndTime: {
      classId: 0,
      time: ''
    },
    backPath: [] as string[],
    questionAnswers: {
      businessOwner: null,
      monthlyRevenue: null,
      role: null,
      annualRevenue: null,
      annualBudget: null,
      isRaisedMoney: null,
      fundingRound: null,
      whenStartCoaching: null,
      entrepreneurType: null,
      interests: [] as string[]
    },
    isNonqualifiedLead: false,
    selectedCoachName: '',
    selectedCoachHubspotId: '',
    date: new Date(),
    time: null,
  },
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload.step
    },
    setLeadInformation: (state, action) => {
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
      state.email = action.payload.email
      state.phone = action.payload.phone
      state.phoneLocal = action.payload.phoneLocal
      state.comment = action.payload.comment
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload.firstName
    },
    setLastName: (state, action) => {
      state.lastName = action.payload.lastName
    },
    setComment: (state, action) => {
      state.comment = action.payload.comment
    },
    setEmail: (state, action) => {
      state.email = action.payload.email
    },
    setPhone: (state, action) => {
      state.phone = action.payload.phone
      state.phoneLocal = action.payload.phoneLocal
    },
    setPhoneCountryCode: (state, action) => {
      state.phoneCountryCode = action.payload.phoneCountryCode
    },
    setTimezone: (state, action) => {
      state.timezone = action.payload.timezone
    },
    setDate: (state, action) => {
      state.date = action.payload.date
    },
    setTime: (state, action) => {
      state.time = action.payload.time
    },
    setAvailableTime: (state, action) => {
      state.availableTime = action.payload.availableTime
      state.availableTimeLoaded = true
    },
    setSelectedClassAndTime: (state, action) => {
      state.selectedClassAndTime = action.payload.selectedClassAndTime
    },
    setSelectedCoach: (state, action) => {
      state.selectedCoachName = action.payload.name
      state.selectedCoachHubspotId = action.payload.hbId
    },
    setQuestionAnswer: (state, action) => {
      //@ts-ignore
      state.questionAnswers[action.payload.question] = action.payload.answer
    },
    addToBackPath: (state, action) => {
      state.backPath.push(action.payload)
    },
    removeLastFromBackPath: (state) => {
      state.backPath.pop()
    },
    setIsNonqualifiedLead: (state) => {
      state.isNonqualifiedLead = true
    }
  }
})

export const {
  setLeadInformation,
  setTimezone,
  setDate,
  setPhoneCountryCode,
  setTime,
  setStep,
  setAvailableTime,
  setSelectedClassAndTime,
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  setComment,
  setSelectedCoach,
  setQuestionAnswer,
  addToBackPath,
  removeLastFromBackPath,
  setIsNonqualifiedLead
} = freeCallLead.actions
export default freeCallLead.reducer
