import Link from 'next/link'
import { useQuery } from '@/utils/url'
import { useMobileMenu } from '@/context/MobileMenuContext'

interface Props {
  isVertical?: boolean,
  color?: 'white' | 'black',
  logoColor?: 'white' | 'black' | 'default',
  className?: string
  link?: string
}

const Logo: React.FC<Props> = ({ isVertical = false, color = 'white', logoColor = 'default', className = '', link = '' }) => {

  const { hide } = useMobileMenu()

  return (
    <div className={`${className} logo`} onClick={hide}>
      <Link href={useQuery( link ? link : '/')} passHref>
        <a>
          { isVertical ?
            <svg width="92" height="82" viewBox="0 0 92 82" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_122_512)">
                <path d="M55.9252 5.29829L58.0035 5.2013C58.5443 5.19961 59.0715 5.37061 59.5093 5.68959C59.947 6.00858 60.2726 6.45905 60.4389 6.97603L61.0212 8.78309C61.0762 8.95165 61.1812 9.09929 61.3221 9.20619C61.463 9.31309 61.6331 9.37416 61.8095 9.38116H61.9253L62.1827 9.35528L63.3409 0.00317383L55.0501 4.40931L55.1176 4.66466C55.1662 4.84368 55.2711 5.00214 55.4166 5.11634C55.5622 5.23053 55.7405 5.29436 55.9252 5.29829Z" fill={`${logoColor === 'default' ? '#BCA36B': logoColor}`}/>
                <path d="M47.3287 27.4972C47.6151 27.7558 47.9046 28.0176 48.191 28.2633C48.4773 28.509 48.8054 28.7902 49.1239 29.0521L58.322 9.35862C58.4017 9.17522 58.4344 8.97467 58.4169 8.77531C58.3995 8.57594 58.3326 8.38412 58.2223 8.21748C58.1046 8.0387 57.9451 7.89161 57.7578 7.78905C57.5705 7.68649 57.361 7.6316 57.1477 7.62915C56.9297 7.62973 56.7156 7.6877 56.5268 7.79726C56.3902 7.87342 56.2646 7.96804 56.1536 8.0785L42.2648 22.3669C42.8825 23.0684 43.4166 23.66 43.9345 24.1869L51.8554 16.5546L47.3287 27.4972Z" fill={`${logoColor === 'default' ? '#BCA36B': logoColor}`}/>
                <path d="M56.1954 21.5716L55.6742 20.9639L55.449 21.3615C55.2445 21.7216 55.1455 22.1325 55.1638 22.5467C55.182 22.9609 55.3166 23.3614 55.552 23.702C58.5633 28.0596 60.3778 31.4927 60.9859 33.9043C61.4588 35.8213 60.9859 36.513 60.7736 36.7199C60.4871 36.9371 60.1305 37.0397 59.773 37.0076C58.6406 37.0076 56.9676 36.4548 55.063 35.4527C53.6468 34.6856 52.2849 33.8215 50.9867 32.8666C50.2436 32.3332 49.4714 31.7351 48.6864 31.0983C48.2521 30.7427 47.821 30.3807 47.3995 30.0154C46.6789 29.3915 45.9389 28.7223 45.1989 28.0111L44.0536 26.8926C43.6257 26.4594 43.1881 26.0101 42.7667 25.5543C42.4192 25.1857 42.0622 24.7946 41.6761 24.3582C41.0326 23.6405 40.4149 22.91 39.8358 22.1923C38.2078 20.2178 36.7758 18.0881 35.5601 15.8336C33.9515 12.782 33.7681 10.7131 34.2732 10.1215C34.8716 9.41676 36.2357 9.66894 36.4963 9.70773C39.2503 10.115 43.2782 12.5169 45.1828 13.7486C45.5233 13.9685 45.9289 14.0634 46.3311 14.0175C46.7332 13.9716 47.1072 13.7875 47.3899 13.4964L47.6247 13.2572C44.7968 10.9943 41.9721 9.20987 39.6138 8.18511C37.9473 7.46099 36.4867 7.0957 35.2706 7.0957C34.0544 7.0957 33.0571 7.45778 32.3461 8.16897C29.3605 11.1721 33.218 18.326 37.8154 24.0091C38.417 24.7526 39.0476 25.4961 39.6878 26.2137C40.0835 26.6566 40.4471 27.0575 40.801 27.4325C41.2321 27.8947 41.6729 28.3505 42.1136 28.7934C42.3549 29.0359 43.423 30.0865 43.7222 30.3548C44.4526 31.0401 45.2118 31.7158 45.9743 32.3655C46.4408 32.7632 46.8848 33.1284 47.2901 33.4517C48.0719 34.0788 48.8666 34.6801 49.6516 35.2394C53.686 38.1132 57.27 39.694 59.7376 39.694C60.2676 39.7185 60.7972 39.6354 61.2946 39.45C61.7921 39.2645 62.2473 38.9803 62.6331 38.6143C65.905 35.401 61.2014 27.4131 56.1954 21.5716Z" fill={`${logoColor === 'default' ? '#BCA36B': logoColor}`}/>
                <path d="M46.2831 35.1294C46.0901 34.9807 45.897 34.8321 45.7072 34.6801C45.3855 34.428 45.0638 34.1467 44.7002 33.8525L42.9951 37.9677C42.915 38.1599 42.8835 38.3691 42.9033 38.5765C42.923 38.7839 42.9935 38.9831 43.1085 39.1565C43.2235 39.33 43.3793 39.4721 43.5621 39.5704C43.745 39.6687 43.9492 39.72 44.1565 39.7199L46.2831 35.1294Z" fill={`${logoColor === 'default' ? '#BCA36B': logoColor}`}/>
                <path d="M39.2342 28.7191L38.9318 28.3958C38.5586 28.0014 38.1789 27.5844 37.7703 27.1254L37.7092 27.051L25.3647 39.7167H26.0821C27.1982 39.7162 28.2712 39.2832 29.0774 38.5076L39.2342 28.7191Z" fill={`${logoColor === 'default' ? '#BCA36B': logoColor}`}/>
                <path d="M7.64094 62.3485C5.5433 62.3485 3.83816 61.702 2.57057 60.4671C1.28237 59.1665 0.559174 57.4062 0.559174 55.5713C0.559174 53.7363 1.28237 51.976 2.57057 50.6754C3.87544 49.4449 5.60724 48.7755 7.39642 48.8101C8.44561 48.8292 9.47956 49.0658 10.4335 49.5051V52.7023C10.0483 52.3055 9.58797 51.9901 9.07966 51.7747C8.57135 51.5593 8.0253 51.4482 7.47363 51.448C6.97049 51.4321 6.46925 51.5172 5.99917 51.6981C5.5291 51.879 5.0996 52.1523 4.73576 52.5018C4.33038 52.9094 4.00982 53.394 3.79274 53.9274C3.57567 54.4607 3.46643 55.0322 3.47141 55.6084C3.45418 56.1575 3.54886 56.7042 3.7497 57.2151C3.95054 57.726 4.25325 58.1902 4.63923 58.5792C5.41695 59.3272 6.45846 59.7342 7.53474 59.7107C8.62169 59.6959 9.66022 59.2565 10.4303 58.4855V61.6762C9.56958 62.1272 8.61158 62.3581 7.64094 62.3485Z" fill={color}/>
                <path d="M22.2086 62.3485C20.3482 62.3983 18.5423 61.715 17.1768 60.4444C16.5333 59.8213 16.0232 59.0727 15.678 58.2444C15.3328 57.4161 15.1597 56.5257 15.1692 55.6278C15.1945 53.7955 15.9054 52.0401 17.1607 50.7109C18.0229 49.8445 19.5994 48.8101 22.2086 48.8101C24.2386 48.8101 25.918 49.4566 27.2017 50.6947C27.839 51.3347 28.3446 52.0947 28.6896 52.9312C29.0346 53.7676 29.2121 54.6642 29.2121 55.5696C29.2121 56.475 29.0346 57.3716 28.6896 58.208C28.3446 59.0445 27.839 59.8044 27.2017 60.4444C25.8923 61.7084 24.2225 62.3485 22.2086 62.3485ZM22.1892 51.4091C21.6378 51.3906 21.0886 51.4871 20.5762 51.6926C20.0638 51.898 19.5993 52.208 19.212 52.6028C18.8248 52.9977 18.5232 53.4689 18.3263 53.9868C18.1294 54.5046 18.0414 55.0579 18.068 55.6116C18.05 56.1746 18.1498 56.7351 18.3609 57.2568C18.5721 57.7786 18.8898 58.25 19.2937 58.6406C19.6736 59.0115 20.1242 59.3013 20.6186 59.4924C21.1129 59.6835 21.6406 59.772 22.1699 59.7526C22.7168 59.7701 23.2616 59.6772 23.7722 59.4796C24.2828 59.2819 24.7488 58.9835 25.1427 58.6018C25.5345 58.209 25.8406 57.7386 26.0417 57.2205C26.2427 56.7023 26.3343 56.1478 26.3105 55.5922C26.3325 54.4676 25.913 53.3794 25.1427 52.5632C24.3394 51.8163 23.2837 51.4038 22.1892 51.4091Z" fill={color}/>
                <path d="M46.0322 62.0543L44.9576 53.9273L41.5634 62.0543H40.6369L37.2427 53.9273L36.1681 62.0543H33.3498L35.4185 49.1074H37.7768L41.1002 57.1309L44.4236 49.1074H46.7818L48.8505 62.0543H46.0322Z" fill={color}/>
                <path d="M66.4423 62.0543L65.3645 53.9273L61.9736 62.0543H61.047L57.6528 53.9273L56.575 62.0543H53.76L55.8254 49.1074H58.1869L61.5103 57.1309L64.8337 49.1074H67.1919L69.2574 62.0543H66.4423Z" fill={color}/>
                <path d="M77.7026 49.1042H74.8811V62.0511H77.7026V49.1042Z" fill={color}/>
                <path d="M92 49.1074H83.3553V51.4673H86.2669V62.0543H89.0884V51.4673H92V49.1074Z" fill={color}/>
                <path d="M24.8982 81.9999C22.8005 81.9999 21.0954 81.3534 19.8278 80.1185C18.5396 78.8179 17.8164 77.0576 17.8164 75.2226C17.8164 73.3876 18.5396 71.6273 19.8278 70.3267C21.1327 69.0962 22.8645 68.4269 24.6537 68.4615C25.7027 68.4821 26.7363 68.7186 27.6907 69.1565V72.3536C27.3051 71.9574 26.8447 71.6424 26.3365 71.427C25.8283 71.2117 25.2824 71.1003 24.7309 71.0993C24.2277 71.0835 23.7265 71.1685 23.2564 71.3495C22.7863 71.5304 22.3568 71.8036 21.993 72.1532C21.5876 72.5608 21.267 73.0454 21.05 73.5788C20.8329 74.1121 20.7236 74.6836 20.7286 75.2598C20.7114 75.8088 20.8061 76.3556 21.0069 76.8665C21.2078 77.3774 21.5105 77.8416 21.8965 78.2306C22.6742 78.9786 23.7157 79.3855 24.792 79.3621C25.8789 79.3473 26.9175 78.9078 27.6875 78.1369V81.3308C26.8267 81.7811 25.8686 82.011 24.8982 81.9999Z" fill={color}/>
                <path d="M52.5342 68.759H49.7127V81.7059H52.5342V68.759Z" fill={color}/>
                <path d="M65.4643 82.0001C63.6039 82.05 61.798 81.3666 60.4325 80.0961C59.7894 79.4733 59.2796 78.7252 58.9344 77.8975C58.5892 77.0698 58.4158 76.18 58.4249 75.2826C58.4495 73.4503 59.1606 71.6945 60.4164 70.3657C61.2786 69.4961 62.8551 68.4617 65.4643 68.4617C67.4943 68.4617 69.1737 69.1082 70.4574 70.3463C71.0949 70.9866 71.6005 71.7468 71.9455 72.5836C72.2905 73.4203 72.4681 74.3171 72.4681 75.2228C72.4681 76.1285 72.2905 77.0253 71.9455 77.862C71.6005 78.6988 71.0949 79.459 70.4574 80.0993C69.1641 81.3762 67.4847 82.0001 65.4643 82.0001ZM65.4449 71.0607C64.8938 71.0432 64.345 71.1404 63.833 71.3462C63.321 71.552 62.857 71.862 62.4699 72.2566C62.0829 72.6513 61.7813 73.1221 61.584 73.6395C61.3868 74.1569 61.2981 74.7097 61.3237 75.2632C61.3055 75.8262 61.4052 76.3867 61.6163 76.9085C61.8275 77.4303 62.1454 77.9017 62.5494 78.2922C62.9292 78.6634 63.3798 78.9535 63.8741 79.1452C64.3684 79.3369 64.8962 79.4261 65.4256 79.4075C65.9732 79.4242 66.5186 79.3307 67.0296 79.1325C67.5407 78.9343 68.0072 78.6354 68.4016 78.2534C68.7922 77.8599 69.0973 77.3892 69.2978 76.8712C69.4982 76.3532 69.5896 75.7991 69.5662 75.2438C69.5879 74.1208 69.1696 73.0342 68.4016 72.218C67.6028 71.4737 66.5536 71.0603 65.4643 71.0607H65.4449Z" fill={color}/>
                <path d="M87.7307 81.7059L80.4823 73.0132V81.7059H77.6608V68.759H80.1413L87.3897 77.4097V68.759H90.2112V81.7059H87.7307Z" fill={color}/>
                <path d="M36.9885 81.7059V71.1189H34.0769V68.759H42.7216V71.1189H39.81V81.7059H36.9885Z" fill={color}/>
                <path d="M6.57603 72.0952L10.2308 81.7059H13.1521L7.95622 68.759H5.19583L0 81.7059H6.47951L7.66023 79.3428H3.82207L6.57603 72.0952Z" fill={color}/>
              </g>
              <defs>
                <clipPath id="clip0_122_512">
                  <rect width="92" height="82" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            :
            <svg width="135" height="39" viewBox="0 0 135 39" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M52.4921 19.8172C50.4405 19.8172 48.7728 19.1879 47.533 17.9859C46.2754 16.7187 45.5697 15.0057 45.5697 13.2203C45.5697 11.435 46.2754 9.72198 47.533 8.45475C48.8096 7.25759 50.5032 6.60613 52.253 6.63913C53.28 6.65912 54.292 6.88937 55.2266 7.31567V10.4277C54.8487 10.0421 54.3978 9.73557 53.9003 9.52596C53.4027 9.31635 52.8684 9.20784 52.3285 9.2068C51.8364 9.19138 51.3462 9.27416 50.8864 9.45029C50.4267 9.62641 50.0066 9.89235 49.6507 10.2326C49.2542 10.6294 48.9407 11.1011 48.7284 11.6202C48.5161 12.1394 48.4092 12.6956 48.4141 13.2565C48.3973 13.7909 48.4899 14.3232 48.6863 14.8205C48.8827 15.3178 49.1788 15.7696 49.5563 16.1483C50.3176 16.8754 51.3358 17.2714 52.3883 17.2496C53.4514 17.2352 54.4671 16.8075 55.2203 16.057V19.1659C54.3781 19.6036 53.4412 19.8273 52.4921 19.8172Z" fill={color}/>
              <path d="M66.7401 19.8175C64.9208 19.8643 63.1551 19.1994 61.8187 17.9642C61.1897 17.358 60.6911 16.6298 60.3535 15.8241C60.0159 15.0184 59.8463 14.1523 59.8552 13.2788C59.8793 11.4953 60.5748 9.78627 61.803 8.49278C62.6463 7.64633 64.1913 6.6394 66.7401 6.6394C68.7256 6.6394 70.3682 7.26876 71.6237 8.47392C72.2471 9.09719 72.7417 9.83718 73.0791 10.6516C73.4166 11.4661 73.5903 12.339 73.5903 13.2206C73.5903 14.1022 73.4166 14.9751 73.0791 15.7896C72.7417 16.604 72.2471 17.344 71.6237 17.9673C70.3524 19.1945 68.7099 19.8175 66.7401 19.8175ZM66.7212 9.16931C66.1821 9.15224 65.6454 9.24685 65.1446 9.44718C64.6439 9.6475 64.19 9.9492 63.8114 10.3334C63.4329 10.7175 63.1379 11.1758 62.945 11.6795C62.752 12.1831 62.6653 12.7212 62.6904 13.2599C62.6724 13.8082 62.7701 14.3542 62.9772 14.8622C63.1843 15.3702 63.4961 15.8289 63.8924 16.2083C64.263 16.5698 64.7031 16.8525 65.1861 17.039C65.6691 17.2256 66.1849 17.3123 66.7023 17.2939C67.2379 17.3104 67.7713 17.2194 68.2712 17.0265C68.7711 16.8336 69.2273 16.5426 69.613 16.1706C69.9955 15.7878 70.2946 15.3298 70.4912 14.8255C70.6878 14.3213 70.7777 13.7818 70.7552 13.2411C70.7752 12.1476 70.365 11.0899 69.613 10.2958C68.827 9.56686 67.7932 9.16415 66.7212 9.16931Z" fill={color}/>
              <path d="M90.0441 19.531L88.9899 11.6235L85.6702 19.531H84.764L81.4475 11.6235L80.3933 19.531H77.64L79.6602 6.92871H81.9667L85.2171 14.7418L88.4676 6.92871H90.7772L92.7974 19.531H90.0441Z" fill={color}/>
              <path d="M110.003 19.531L108.952 11.6235L105.632 19.531H104.726L101.407 11.6235L100.356 19.531H97.5991L99.6224 6.92871H101.929L105.179 14.7418L108.43 6.92871H110.736L112.76 19.531H110.003Z" fill={color}/>
              <path d="M121.016 6.92871H118.257V19.531H121.016V6.92871Z" fill={color}/>
              <path d="M135 6.92871H126.545V9.2289H129.393V19.531H132.152V9.2289H135V6.92871Z" fill={color}/>
              <path d="M64.4185 37.1145C65.6582 38.3196 67.3259 38.9489 69.3775 38.9489C70.3268 38.9576 71.2637 38.7328 72.1057 38.2944V35.1856C71.353 35.9372 70.3372 36.366 69.2737 36.3813C68.2212 36.4031 67.203 36.0071 66.4417 35.2799C66.0645 34.9011 65.7687 34.4492 65.5722 33.9519C65.3758 33.4547 65.283 32.9226 65.2995 32.3882C65.2946 31.8268 65.4014 31.2701 65.6137 30.7504C65.826 30.2307 66.1396 29.7585 66.5361 29.3611C66.8921 29.0213 67.3123 28.7558 67.7721 28.5802C68.2319 28.4046 68.722 28.3225 69.2139 28.3385C69.7539 28.3386 70.2885 28.4467 70.7862 28.6564C71.2839 28.866 71.7346 29.1731 72.112 29.5594V26.4473C71.1779 26.0196 70.1656 25.7893 69.1384 25.7708C67.389 25.7366 65.6954 26.387 64.4185 27.5833C63.1608 28.8505 62.4551 30.5635 62.4551 32.3489C62.4551 34.1342 63.1608 35.8472 64.4185 37.1145Z" fill={color}/>
              <path d="M93.6401 26.057H96.3998V38.6593H93.6401V26.057Z" fill={color}/>
              <path d="M109.033 28.2973L109.035 28.2974H109.025L109.033 28.2973Z" fill={color}/>
              <path fillRule="evenodd" clipRule="evenodd" d="M109.057 38.9487C107.237 38.9973 105.471 38.3321 104.135 37.0954C103.506 36.4885 103.007 35.7598 102.669 34.9537C102.331 34.1476 102.161 33.281 102.169 32.4069C102.193 30.6233 102.888 28.9144 104.116 27.6209C104.96 26.7776 106.505 25.7706 109.057 25.7706C111.042 25.7706 112.685 26.3999 113.937 27.602C114.561 28.2252 115.055 28.9653 115.393 29.7797C115.73 30.5942 115.904 31.4671 115.904 32.3487C115.904 33.2303 115.73 34.1032 115.393 34.9177C115.055 35.7321 114.561 36.4721 113.937 37.0954C112.666 38.3194 111.023 38.9487 109.057 38.9487ZM109.033 28.2973C108.495 28.2805 107.958 28.3751 107.458 28.5753C106.957 28.7756 106.503 29.0773 106.125 29.4614C105.746 29.8456 105.451 30.3039 105.258 30.8075C105.066 31.3112 104.979 31.8493 105.004 32.388C104.986 32.9363 105.084 33.482 105.292 33.9899C105.499 34.4979 105.81 34.9566 106.206 35.3364C106.577 35.7008 107.018 35.9858 107.503 36.1741C107.988 36.3623 108.506 36.4499 109.025 36.4314C109.561 36.4486 110.095 36.358 110.595 36.165C111.094 35.972 111.551 35.6807 111.936 35.3081C112.318 34.9258 112.617 34.4683 112.814 33.9646C113.01 33.4609 113.1 32.922 113.078 32.3817C113.098 31.2873 112.688 30.2288 111.936 29.4333C111.149 28.6983 110.11 28.292 109.033 28.2973Z" fill={color}/>
              <path d="M123.735 30.2011L130.825 38.6624H133.254V26.057H130.494V34.4805L123.405 26.057H120.976V38.6624H123.735V30.2011Z" fill={color}/>
              <path d="M81.1993 38.6593V28.3572H78.3516V26.057H86.8034V28.3572H83.9557V38.6593H81.1993Z" fill={color}/>
              <path d="M55.0253 38.6593L51.4508 29.3075L48.7572 36.3622H52.5112L51.3595 38.6593H45.019L50.104 26.057H52.8007L57.8856 38.6593H55.0253Z" fill={color}/>
              <path d="M29.8899 5.15104C29.9151 5.15104 31.8817 5.05666 31.9226 5.05666C32.4518 5.05572 32.9676 5.22278 33.3957 5.5338C33.8237 5.84483 34.142 6.28377 34.3046 6.78731L34.8742 8.54627C34.928 8.71035 35.0306 8.85406 35.1684 8.95811C35.3062 9.06216 35.4726 9.12157 35.6451 9.12839H35.7584L36.0101 9.10323L37.1429 0L29.034 4.28886L29.1001 4.53431C29.1467 4.70907 29.249 4.86388 29.3915 4.97519C29.5341 5.0865 29.7091 5.14823 29.8899 5.15104Z" fill={`${logoColor === 'default' ? '#BCA36B': logoColor}`}/>
              <path d="M21.482 26.7619C21.7621 27.0136 22.0452 27.2654 22.3253 27.5077C22.6054 27.75 22.9263 28.0174 23.2378 28.2754L32.2466 9.10617C32.3246 8.9271 32.3565 8.73136 32.3395 8.5368C32.3224 8.34224 32.257 8.15504 32.1491 7.99225C32.0338 7.81862 31.8778 7.6759 31.6945 7.5766C31.5113 7.47731 31.3065 7.42449 31.0981 7.42273C30.8849 7.4233 30.6755 7.47968 30.4908 7.58632C30.3576 7.66129 30.234 7.75215 30.1227 7.85693L16.5418 21.7651C17.1459 22.4479 17.6683 23.0237 18.1749 23.5398L25.9219 16.1106L21.482 26.7619Z" fill={`${logoColor === 'default' ? '#BCA36B': logoColor}`}/>
              <path d="M30.151 20.9815L29.6444 20.3899L29.421 20.7769C29.2213 21.1277 29.1251 21.5279 29.1435 21.931C29.1619 22.3342 29.2941 22.724 29.5248 23.0551C32.4701 27.2968 34.2448 30.6385 34.8395 32.9828C35.302 34.8519 34.8395 35.5221 34.6318 35.7266C34.3516 35.938 34.0028 36.0378 33.6532 36.0067C32.5456 36.0067 30.9093 35.4686 29.0465 34.49C27.6615 33.7502 26.3294 32.9154 25.0597 31.9916C24.3329 31.4692 23.5745 30.8902 22.8099 30.2672C22.3851 29.9242 21.9635 29.5718 21.5512 29.2162C20.8464 28.6089 20.1227 27.9576 19.3989 27.2653C19.3989 27.2653 18.4738 26.3717 18.2787 26.1766C17.8602 25.7549 17.4323 25.3175 17.0201 24.8739C16.6803 24.512 16.331 24.1312 15.9502 23.7096C15.3209 23.0079 14.7199 22.2999 14.1535 21.6014C12.5611 19.6783 11.1605 17.6043 9.97163 15.4088C8.41405 12.4415 8.21895 10.4277 8.71298 9.85184C9.29825 9.16273 10.6324 9.4113 10.8842 9.44906C13.5808 9.84239 17.5173 12.1835 19.3989 13.3824H19.4147C19.7477 13.5964 20.1444 13.6888 20.5377 13.6441C20.931 13.5994 21.2968 13.4202 21.5733 13.1369L21.803 12.9041C19.0371 10.7014 16.2712 8.96449 13.9679 7.96701C12.3379 7.26216 10.9093 6.90345 9.71675 6.90345C8.52418 6.90345 7.55502 7.25586 6.85961 7.95126C3.93954 10.8745 7.71235 17.838 12.2089 23.3698C12.7973 24.0935 13.4141 24.8172 14.0402 25.5158C14.4273 25.9469 14.7828 26.3339 15.129 26.702C15.5506 27.1489 15.9817 27.5957 16.4128 28.0268C16.6488 28.2628 17.6935 29.2854 17.9861 29.5466C18.7004 30.2137 19.443 30.8713 20.1887 31.5038C20.645 31.8877 21.0792 32.2433 21.4757 32.5611C22.2404 33.1684 23.0176 33.7537 23.7822 34.298C27.7312 37.0954 31.2366 38.6373 33.65 38.6373C34.1712 38.6624 34.6922 38.582 35.1815 38.4009C35.6709 38.2197 36.1186 37.9416 36.4978 37.5831C39.6475 34.4554 35.0503 26.6769 30.151 20.9815Z" fill={`${logoColor === 'default' ? '#BCA36B': logoColor}`}/>
              <path d="M20.4594 34.1913C20.2706 34.0434 20.0818 33.9018 19.8962 33.7539C19.5815 33.5053 19.2669 33.2347 18.9113 32.9484L17.2436 36.954C17.1653 37.1411 17.1344 37.3446 17.1538 37.5465C17.1731 37.7484 17.2421 37.9424 17.3545 38.1112C17.4669 38.28 17.6193 38.4184 17.7982 38.5141C17.977 38.6097 18.1767 38.6596 18.3795 38.6595L20.4594 34.1913Z" fill={`${logoColor === 'default' ? '#BCA36B': logoColor}`}/>
              <path d="M13.5651 27.9513L13.2662 27.6367C12.9043 27.2528 12.533 26.8468 12.1334 26.4L12.0736 26.3276L0 38.6561H0.701703C1.79332 38.6557 2.84269 38.2342 3.63122 37.4793L13.5651 27.9513Z" fill={`${logoColor === 'default' ? '#BCA36B': logoColor}`}/>
            </svg>
          }
        </a>
      </Link>
    </div>
  )
}

export default Logo
