import { createSlice } from '@reduxjs/toolkit'

const cookieConsent = createSlice({
  name: 'cookieConsent',
  initialState: { acceptAllFired: false, fromCookieFired: false },
  reducers: {
    setAcceptAllFired: (state) => {
      state.acceptAllFired = true
    },
    setFromCookieFired: (state) => {
      state.fromCookieFired = true
    }
  }
})

export const { setAcceptAllFired, setFromCookieFired } = cookieConsent.actions
export default cookieConsent.reducer
