import { createSlice } from '@reduxjs/toolkit'

const splitTest = createSlice({
  name: 'splitTest',
  initialState: {
    value: false,
  },
  reducers: {
    setValue: (state, value) => {
      state.value = value.payload
    }
  }
})

export const { setValue } = splitTest.actions
export default splitTest.reducer
