import styles from '@/styles/components/popups/CookieConsent.module.scss'
import { Headline2 } from '@/components/common/Headline2'
import { Button } from '@/components/controls/Button'
import { imageLoader } from '@/loaders/index'
import cookie_img from '@/public/images/cookie.png'
import { AccordionNew } from '@/components/accordion-new/AccordionNew'
import Image from 'next/image'
import Toggle from 'react-toggle'
import { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import ConditionalRender from '@/components/utils/ConditionalRender'
import { emitter } from '@/utils/emitter'
import { useClientLocation } from '@/hooks/ClientLocation'
import { setClientLocation } from '@/store/slices/clientLocation'
import { setAcceptAllFired, setFromCookieFired } from '@/store/slices/cookieConsent'
import { RootState } from '@/store/reducers'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'

interface Props {
  className?: string,
}

export const CookieConsent: React.FC<Props> = () => {
  const [opened, setOpen] = useState(false)
  const [active, setActive] = useState(true)
  const [ready, setReady] = useState(false)
  const [bodyHeight, setBodyHeight] = useState(0)
  const bodyRef = useRef<HTMLDivElement>(null)
  const mediaMd = useMediaQuery({ minWidth: 768 })
  const mediaLg = useMediaQuery({ minWidth: 1024 })
  const [consentState, setConsentState] = useState({
    ad_storage: 'granted',
    analytics_storage: 'granted',
    personalization_storage: 'granted'
  })
  const clientLocationState = useSelector((state: RootState) => state.clientLocation)
  const cookieConsentState = useSelector((state: RootState) => state.cookieConsent)
  const location = useClientLocation()
  const dispatch = useDispatch()

  let cookieConsent: any = null

  try {
    cookieConsent = Cookies.get('cookie-consent')

    if (cookieConsent) {
      cookieConsent = JSON.parse(cookieConsent)
    }
  } catch (error) {
    console.error(error)
  }

  const acceptAllHandler = () => {
    close()
    emitter.emit('cookie-consent-accept-all')
  }

  const rejectAllHandler = () => {
    close()
    emitter.emit('cookie-consent-reject-all')
  }

  const saveHandler = () => {
    close()
    emitter.emit('cookie-consent-configure', consentState)
  }

  const close = () => {
    setActive(false)
  }

  const accordionChangeHandler = (isActive: boolean, el?: HTMLElement | null | undefined) => {
    if (el) {
      const chevronEl = el.querySelector('.chevron')

      if (isActive) {
        chevronEl?.classList.add('chevron--top')
      } else {
        chevronEl?.classList.remove('chevron--top')
      }
    }
  }

  useEffect(() => {
    if (!opened) {
      if (bodyRef.current) {
        setBodyHeight(bodyRef.current.offsetHeight)
      }
    } else {
      setBodyHeight(0)
    }
  })

  useEffect(() => {
    if (location.country_code) {
      dispatch(setClientLocation(location))
    }
  }, [location.country_code])

  useEffect(() => {
    setTimeout(() => {
      setReady(true)
    }, 3000)
  }, [])

  useEffect(() => {
    if (cookieConsent && !cookieConsentState.fromCookieFired) {
      emitter.emit('cookie-consent-set-from-cookies', cookieConsent)
      dispatch(setFromCookieFired())
    }
  }, [])

  useEffect(() => {
    if (!clientLocationState.is_eu && clientLocationState.loaded && !cookieConsentState.fromCookieFired && !cookieConsentState.acceptAllFired) {
      emitter.emit('cookie-consent-accept-all')
      dispatch(setAcceptAllFired())
      dispatch(setFromCookieFired())
    }
  }, [clientLocationState.loaded])

  if (cookieConsent) return null

  if (!clientLocationState.is_eu) return null

  if (!active) return null

  return (
    <div
      className={styles.cookie_consent}
      style={{
        visibility: ready ? 'visible' : 'hidden',
        opacity: ready ? '1' : '0',
        transform: `translate3d(0, ${bodyHeight}px, 0)`
      }}
    >
      <div className="flex w-full lg:w-[93%] mx-auto">
        <div className="w-[16px] md:w-[24px] shrink-0">
          <Image
            loader={imageLoader}
            src={cookie_img}
            alt="cookie image"
            priority={false}
          />
        </div>
        <div className="flex flex-col w-full ml-[10px] md:ml-[20px]">
          <div className={`${styles.cookie_consent_header} md:items-center flex-col md:flex-row`}>
            <div className="max-w-[460px]">
              <p className="text-[10px] md:text-[11px] font--accent"><b className="text-[11px] md:text-[12px]">We use cutting-edge marketing technology + follow privacy best practices</b> We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking &#34;Accept All&#34;, you consent to our use of cookies.</p>
            </div>
            <div className="flex lg:w-[390px] md:ml-[20px] mt-[16px] md:mt-0 shrink-0">
              <ConditionalRender client>
                <Button className="mr-[4px] md:mr-[10px]" type="white" padding="sm" disableArrow={true} height="sm" maxWidth={mediaLg ? 140 : 0} textCenter={true} fontSize={mediaMd ? 12 : 10} onClick={acceptAllHandler}>Accept All</Button>
                <Button className="mr-[4px] md:mr-[10px]" type="secondary-white" padding="sm" disableArrow={true} height="sm" fontSize={mediaMd ? 12 : 10} onClick={rejectAllHandler}>Reject All</Button>
                <Button type="secondary-white" padding="sm" height="sm" fontSize={mediaMd ? 12 : 10} onClick={() => {
                  setOpen((prev) => {
                    return !prev
                  })
                }}
                customElement={<span className={`${opened ? '' : 'chevron--top'} chevron ml-[10px]`}></span>}
                >Configure</Button>
              </ConditionalRender>
            </div>
          </div>
          <div className={styles.cookie_consent_body} ref={bodyRef}>
            <Headline2 tag="h3" className="text-[14px] mb-[10px]" color="white">Customize Consent Preferences</Headline2>
            <p className="text-[11px] font--accent mb-[20px]">
              We use cookies to help you navigate efficiently and perform certain functions. You will find detailed information about all cookies under each consent category below.<br/><br/>
              The cookies that are categorized as &#34;Necessary&#34; are stored on your browser as they are essential for enabling the basic functionalities of the site.<br/><br/>
              We also use third-party cookies that help us analyze how you use this website, store your preferences, and provide the content and advertisements that are relevant to you. These cookies will only be stored in your browser with your prior consent.<br/><br/>
              You can choose to enable or disable some or all of these cookies but disabling some of them may affect your browsing experience.
            </p>
            <AccordionNew items={[
              {
                header: (
                  <div className={`${styles.accordion_header} pr-[5px]`}>
                    <div className="flex items-center">
                      <Headline2 tag="h4" className="text-[14px] mr-[10px]" color="white">Necessary</Headline2>
                      <span className="chevron"></span>
                    </div>
                    <div>
                      <Toggle
                        icons={false}
                        disabled={true}
                        defaultChecked={true}
                      />
                    </div>
                  </div>
                ),
                body: (
                  <div className={styles.accordion_body}>
                    <p className="text-[11px] font--accent">Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.</p>
                  </div>
                ),
                active: false,
                onChange: accordionChangeHandler
              },
              {
                header: (
                  <div className={`${styles.accordion_header} pr-[5px]`}>
                    <div className="flex items-center">
                      <Headline2 tag="h4" className="text-[14px] mr-[10px]" color="white">Functional</Headline2>
                      <span className="chevron"></span>
                    </div>
                    <div onClick={(e) => e.stopPropagation()}>
                      <Toggle
                        icons={false}
                        checked={consentState.personalization_storage === 'granted'}
                        onChange={() => {
                          setConsentState((prevStat) => {
                            if (prevStat.personalization_storage === 'granted') {
                              return {
                                ad_storage: prevStat.ad_storage,
                                analytics_storage: prevStat.analytics_storage,
                                personalization_storage: 'denied'
                              }
                            }

                            return {
                              ad_storage: prevStat.ad_storage,
                              analytics_storage: prevStat.analytics_storage,
                              personalization_storage: 'granted'
                            }
                          })
                        }}
                      />
                    </div>
                  </div>
                ),
                body: (
                  <div className={styles.accordion_body}>
                    <p className="text-[11px] font--accent">Functional cookies help perform certain functionalities like sharing the content of the website on social media platforms, collecting feedback, and other third-party features.</p>
                  </div>
                ),
                active: false,
                onChange: accordionChangeHandler
              },
              {
                header: (
                  <div className={`${styles.accordion_header} pr-[5px]`}>
                    <div className="flex items-center">
                      <Headline2 tag="h4" className="text-[14px] mr-[10px]" color="white">Analytics</Headline2>
                      <span className="chevron"></span>
                    </div>
                    <div onClick={(e) => e.stopPropagation()}>
                      <Toggle
                        icons={false}
                        checked={consentState.analytics_storage === 'granted'}
                        onChange={() => {
                          setConsentState((prevStat) => {
                            if (prevStat.analytics_storage === 'granted') {
                              return {
                                ad_storage: prevStat.ad_storage,
                                analytics_storage: 'denied',
                                personalization_storage: prevStat.personalization_storage
                              }
                            }

                            return {
                              ad_storage: prevStat.ad_storage,
                              analytics_storage: 'granted',
                              personalization_storage: prevStat.personalization_storage
                            }
                          })
                        }}
                      />
                    </div>
                  </div>
                ),
                body: (
                  <div className={styles.accordion_body}>
                    <p className="text-[11px] font--accent">Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.</p>
                  </div>
                ),
                active: false,
                onChange: accordionChangeHandler
              },
              {
                header: (
                  <div className={`${styles.accordion_header} ${styles.accordion_header_last} pr-[5px]`}>
                    <div className="flex items-center">
                      <Headline2 tag="h4" className="text-[14px] mr-[10px]" color="white">Advertisement</Headline2>
                      <span className="chevron"></span>
                    </div>
                    <div onClick={(e) => e.stopPropagation()}>
                      <Toggle
                        icons={false}
                        checked={consentState.ad_storage === 'granted'}
                        onChange={() => {
                          setConsentState((prevStat) => {
                            if (prevStat.ad_storage === 'granted') {
                              return {
                                ad_storage: 'denied',
                                analytics_storage: prevStat.analytics_storage,
                                personalization_storage: prevStat.personalization_storage
                              }
                            }

                            return {
                              ad_storage: 'granted',
                              analytics_storage: prevStat.analytics_storage,
                              personalization_storage: prevStat.personalization_storage
                            }
                          })
                        }}
                      />
                    </div>
                  </div>
                ),
                body: (
                  <div className={styles.accordion_body}>
                    <p className="text-[11px] font--accent">Advertisement cookies are used to provide visitors with customized advertisements based on the pages you visited previously and to analyze the effectiveness of the ad campaigns.</p>
                  </div>
                ),
                active: false,
                onChange: accordionChangeHandler
              }
            ]}
            />
            <div className={`${styles.cookie_consent_footer} flex justify-end pt-[20px] mb-[20px]`}>
              <Button className="mr-[10px]" type="white" padding="sm" disableArrow={true} height="sm" maxWidth={140} textCenter={true} fontSize={12} onClick={saveHandler}>Save</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
