import { easeOutQuart } from '@/utils/math'

export function animateScroll(targetPosition: number, initialPosition: number, duration: number) {
  let start: number
  let position
  let animationFrameId: number

  // maximum amount of pixels we can scroll
  const maxAvailableScroll =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight

  const amountOfPixelsToScroll = initialPosition - targetPosition

  function step(timestamp: number) {
    if (start === undefined) {
      start = timestamp
    }

    const elapsed = timestamp - start

    // this just gives us a number between 0 (start) and 1 (end)
    const relativeProgress = elapsed / duration

    // ease out that number
    const easedProgress = easeOutQuart(relativeProgress)

    // calculate new position for every tick of the requesAnimationFrame
    position = initialPosition - amountOfPixelsToScroll * Math.min(easedProgress, 1)

    // set the scrollbar position
    window.scrollTo(0, position)

    // Stop when max scroll is reached
    if (
      initialPosition !== maxAvailableScroll &&
      window.scrollY === maxAvailableScroll
    ) {
      window.cancelAnimationFrame(animationFrameId)
      return
    }

    // repeat until the end is reached
    if (elapsed < duration) {
      animationFrameId = window.requestAnimationFrame(step)
    }
  }

  animationFrameId = window.requestAnimationFrame(step)
}
