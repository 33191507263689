import { createSlice } from '@reduxjs/toolkit'
import Link from 'next/link'

const Quiz = createSlice({
  name: 'Quiz',
  initialState: {
    choices: [
      'Strongly disagree',
      'Disagree',
      'Neutral',
      'Agree',
      'Strongly agree'
    ],
    questions: [
      'I get angry easily|Neuroticism|Volatility|+',
      'I am filled with doubts about things|Neuroticism|Withdrawal|+',
      'I am the type of person that feels others emotions|Agreeableness|Compassion|+',
      'I respect authority|Agreeableness|Politeness|+',
      'I carry out my plans|Conscientiousness|Industriousness|+',
      'I like order in my life|Conscientiousness|Orderliness|+',
      'I make friends easily|Extraversion|Enthusiasm|+',
      'I am the type of person who likes to take charge|Extraversion|Assertiveness|+',
      'I am quick to understand things|Openness to Experience|Intellect|+',
      'I enjoy the beauty of nature|Openness to Experience|Openness|+',
      'I get upset easily|Neuroticism|Volatility|+',
      'I feel threatened easily|Neuroticism|Withdrawal|+',
      'I inquire about others well being|Agreeableness|Compassion|+',
      'I am the type of person that hates to seem pushy|Agreeableness|Politeness|+',
      'I finish what I start|Conscientiousness|Industriousness|+',
      'I like to keep things tidy|Conscientiousness|Orderliness|+',
      'I warm up quickly to others|Extraversion|Enthusiasm|+',
      'I have a strong personality|Extraversion|Assertiveness|+',
      'I can handle a lot of information|Openness to Experience|Intellect|+',
      'I believe in the importance of art|Openness to Experience|Openness|+',
      'I change my mood a lot|Neuroticism|Volatility|+',
      'I worry about things|Neuroticism|Withdrawal|+',
      'I sympathize with others feelings|Agreeableness|Compassion|+',
      'I avoid imposing my will on others|Agreeableness|Politeness|+',
      'I get things done quickly|Conscientiousness|Industriousness|+',
      'I follow a schedule|Conscientiousness|Orderliness|+',
      'I show my feelings when I am happy|Extraversion|Enthusiasm|+',
      'I know how to captivate people|Extraversion|Assertiveness|+',
      'I like to solve complex problems|Openness to Experience|Intellect|+',
      'I love to reflect on things|Openness to Experience|Openness|+',
      'I am a person whose moods go up and down easily|Neuroticism|Volatility|+',
      'I am easily discouraged|Neuroticism|Withdrawal|+',
      'I take an interest in other peoples lives|Agreeableness|Compassion|+',
      'I rarely put people under pressure|Agreeableness|Politeness|+',
      'I always know what I am doing|Conscientiousness|Industriousness|+',
      'I want everything to be "just right"|Conscientiousness|Orderliness|+',
      'I have a lot of fun|Extraversion|Enthusiasm|+',
      'I see myself as a good leader|Extraversion|Assertiveness|+',
      'I have a rich vocabulary|Openness to Experience|Intellect|+',
      'I get deeply immersed in music|Openness to Experience|Openness|+',
      'I am a person that gets easily agitated|Neuroticism|Volatility|+',
      'I become overwhelmed by events|Neuroticism|Withdrawal|+',
      'I like to do things for others|Agreeableness|Compassion|+',
      'I like to insult people|Agreeableness|Politeness|-',
      'I waste my time|Conscientiousness|Industriousness|-',
      'I see that the rules are observed|Conscientiousness|Orderliness|+',
      'I laugh a lot|Extraversion|Enthusiasm|+',
      'I can talk others into doing things|Extraversion|Assertiveness|+',
      'I think quickly|Openness to Experience|Intellect|+',
      'I see beauty in things that others might not notice|Openness to Experience|Openness|+',
      'I am a person that can be stirred up easily|Neuroticism|Volatility|+',
      'I am afraid of many things|Neuroticism|Withdrawal|+',
      'I am not interested in other peoples problems|Agreeableness|Compassion|-',
      'I believe that I am better than others|Agreeableness|Politeness|-',
      'I find it difficult to get down to work|Conscientiousness|Industriousness|-',
      'I want every detail taken care of|Conscientiousness|Orderliness|+',
      'I am hard to get to know|Extraversion|Enthusiasm|-',
      'I am the first to act|Extraversion|Assertiveness|+',
      'I formulate ideas clearly|Openness to Experience|Intellect|+',
      'I need a creative outlet|Openness to Experience|Openness|+',
      'I rarely get irritated|Neuroticism|Volatility|-',
      'I seldom feel blue|Neuroticism|Withdrawal|-',
      'I can’t be bothered with others needs|Agreeableness|Compassion|-',
      'I take advantage of others|Agreeableness|Politeness|-',
      'I mess things up|Conscientiousness|Industriousness|-',
      'I leave my belongings around|Conscientiousness|Orderliness|-',
      'I keep others at a distance|Extraversion|Enthusiasm|-',
      'I do not have an assertive personality|Extraversion|Assertiveness|-',
      'I have difficulty understanding abstract ideas|Openness to Experience|Intellect|-',
      'I do not like poetry|Openness to Experience|Openness|-',
      'I keep my emotions under control|Neuroticism|Volatility|-',
      'I feel comfortable with myself|Neuroticism|Withdrawal|-',
      'I am indifferent to the feelings of others|Agreeableness|Compassion|-',
      'I actively seek conflict|Agreeableness|Politeness|-',
      'I don’t put my mind on the task at hand|Conscientiousness|Industriousness|-',
      'I am not bothered by messy people|Conscientiousness|Orderliness|-',
      'I reveal little about myself|Extraversion|Enthusiasm|-',
      'I lack the talent for influencing people|Extraversion|Assertiveness|-',
      'I avoid philosophical discussions|Openness to Experience|Intellect|-',
      'I seldom get lost in thought|Openness to Experience|Openness|-',
      'I rarely lose my composure|Neuroticism|Volatility|-',
      'I rarely feel depressed|Neuroticism|Withdrawal|-',
      'I take no time for others|Agreeableness|Compassion|-',
      'I love a good fight|Agreeableness|Politeness|-',
      'I postpone decisions|Conscientiousness|Industriousness|-',
      'I am not bothered by disorder|Conscientiousness|Orderliness|-',
      'I rarely get caught up in the excitement|Extraversion|Enthusiasm|-',
      'I wait for others to lead the way|Extraversion|Assertiveness|-',
      'I avoid difficult reading materials|Openness to Experience|Intellect|-',
      'I seldom daydream|Openness to Experience|Openness|-',
      'I am not easily annoyed|Neuroticism|Volatility|-',
      'I am not embarrassed easily|Neuroticism|Withdrawal|-',
      'I don’t have a softside|Agreeableness|Compassion|-',
      'I am out for my own personal gain|Agreeableness|Politeness|-',
      'I am easily distracted|Conscientiousness|Industriousness|-',
      'I dislike routine|Conscientiousness|Orderliness|-',
      'I am not a very enthusiastic person|Extraversion|Enthusiasm|-',
      'I hold back my opinions|Extraversion|Assertiveness|-',
      'I learn things slowly|Openness to Experience|Intellect|-',
      'I seldom notice the emotional aspects of paintings and pictures|Openness to Experience|Openness|-'
    ],
    parts: [
      {
        finishTitle: 'One down, nine to go',
        finishSubtitle: 'You’re doing great!',
        finishButton: 'Let’s go',
        finished: false
      },
      {
        finishTitle: 'Second section complete',
        finishSubtitle: 'Keep going!',
        finishButton: 'Keep going',
        finished: false
      },
      {
        finishTitle: 'Part 3 finished...',
        finishSubtitle: 'Section 4 awaits!',
        finishButton: 'Let’s go',
        finished: false
      },
      {
        finishTitle: '40% of the way there!',
        finishSubtitle: 'That’s almost half way',
        finishButton: 'Let’s go',
        finished: false
      },
      {
        finishTitle: 'Five out of Ten!',
        finishSubtitle: 'The midpoint.',
        finishButton: 'Let’s go',
        finished: false
      },
      {
        finishTitle: 'Part six is a wrap!',
        finishSubtitle: 'You are here.',
        finishButton: 'Let’s go',
        finished: false
      },
      {
        finishTitle: 'Seventh inning stretch!',
        finishSubtitle: 'There’s no crying in baseball!',
        finishButton: 'Let’s go',
        finished: false
      },
      {
        finishTitle: 'Eight sections mooving backwards in time',
        finishSubtitle: 'There’s no crying in baseball!',
        finishButton: 'Let’s go',
        finished: false
      },
      {
        finishTitle: 'One. Section. Left',
        finishSubtitle: 'Let’s do this',
        finishButton: 'Let’s go',
        finished: false
      },
      {
        finishTitle: 'Congratulations! You have just completed the Commit Action Founder Assessment.',
        finishSubtitle: 'Your results will be broken down... Sit amet volutpat consequat mauris nunc congue nisi vitae suscipit. Laoreet id donec ultrices tincidunt. Suspendisse ultrices gravida dictum fusce ut placerat orci nulla pellentesque.',
        finishButton: 'Let’s go',
        finished: false
      }
    ],
    currentPersonalQuestion: 0,
    personalQuestions: [
      {
        question: 'How long have you been self employed?',
        description: 'Your total experience in your journey is a critical factor in our personality model’s science.',
        choices: [
          'Less than 1 year',
          '1 - 2 years',
          '2 - 5 years',
          '5 - 10 years',
          '10+ years'
        ]
      },
      {
        question: 'How many employees do you currently manage?',
        description: 'Your personal productivity habits are force multipliers when you lead others.',
        choices: [
          'Just me',
          '1 - 5',
          '5 - 25',
          '25 - 100',
          '100+ employees'
        ]
      },
      {
        question: 'What is your current monthly revenue?',
        description: 'Financial results aren’t the only measure of productivity, but sharing a ballpark of your revenue helps us make our model more predictive. This data is anonymized and never shared with anyone.',
        choices: [
          'Less than $5,000',
          '$5,000 - $10,000',
          '$10,000 - $25,000',
          '$25,000 - $100,000',
          '$100,000+'
        ]
      }
    ],
    currentQuestion: 0,
    currentPart: 0,
    currentAnswer: -1,
    currentPersonalAnswer: -1,
    pdfCreated: false,
    personalAnswers: [],
    questionsPerPart: 10,
    answers: [],
    email: '',
    phoneNumber: '',
    phoneCountryCode: '',
    result: [
      { title: 'Agreeableness', percent: 0, color: '#E684AE', inner: [ { title: 'Compassion', percent: 0 }, { title: 'Politeness', percent: 0 } ] },
      { title: 'Conscientiousness', percent: 0, color: '#BE55F0', inner: [ { title: 'Industriousness', percent: 0 }, { title: 'Orderliness', percent: 0 } ] },
      { title: 'Extraversion', percent: 0, color: '#2E6EF3', inner: [ { title: 'Enthusiasm', percent: 0 }, { title: 'Assertiveness', percent: 0 } ] },
      { title: 'Neuroticism', percent: 0, color: '#77A1D3', inner: [ { title: 'Volatility', percent: 0 }, { title: 'Withdrawal', percent: 0 } ] },
      { title: 'Openness to Experience', percent: 0, color: '#79CBCA', inner: [ { title: 'Intellect', percent: 0 }, { title: 'Openness', percent: 0 } ] }
    ],
    exampleResults: [
      [
        { title: 'Agreeableness', percent: 18, color: '#E684AE', inner: [ { title: 'Compassion', percent: 0 }, { title: 'Politeness', percent: 0 } ] },
        { title: 'Conscientiousness', percent: 65, color: '#BE55F0', inner: [ { title: 'Industriousness', percent: 0 }, { title: 'Orderliness', percent: 0 } ] },
        { title: 'Extraversion', percent: 87, color: '#2E6EF3', inner: [ { title: 'Enthusiasm', percent: 0 }, { title: 'Assertiveness', percent: 0 } ] },
        { title: 'Neuroticism', percent: 53, color: '#77A1D3', inner: [ { title: 'Volatility', percent: 0 }, { title: 'Withdrawal', percent: 0 } ] },
        { title: 'Openness to Experience', percent: 92, color: '#79CBCA', inner: [ { title: 'Intellect', percent: 0 }, { title: 'Openness', percent: 0 } ] }
      ],
      [
        { title: 'Agreeableness', percent: 68, color: '#E684AE', inner: [ { title: 'Compassion', percent: 0 }, { title: 'Politeness', percent: 0 } ] },
        { title: 'Conscientiousness', percent: 24, color: '#BE55F0', inner: [ { title: 'Industriousness', percent: 0 }, { title: 'Orderliness', percent: 0 } ] },
        { title: 'Extraversion', percent: 73, color: '#2E6EF3', inner: [ { title: 'Enthusiasm', percent: 0 }, { title: 'Assertiveness', percent: 0 } ] },
        { title: 'Neuroticism', percent: 47, color: '#77A1D3', inner: [ { title: 'Volatility', percent: 0 }, { title: 'Withdrawal', percent: 0 } ] },
        { title: 'Openness to Experience', percent: 59, color: '#79CBCA', inner: [ { title: 'Intellect', percent: 0 }, { title: 'Openness', percent: 0 } ] }
      ],
      [
        { title: 'Agreeableness', percent: 33, color: '#E684AE', inner: [ { title: 'Compassion', percent: 0 }, { title: 'Politeness', percent: 0 } ] },
        { title: 'Conscientiousness', percent: 17, color: '#BE55F0', inner: [ { title: 'Industriousness', percent: 0 }, { title: 'Orderliness', percent: 0 } ] },
        { title: 'Extraversion', percent: 82, color: '#2E6EF3', inner: [ { title: 'Enthusiasm', percent: 0 }, { title: 'Assertiveness', percent: 0 } ] },
        { title: 'Neuroticism', percent: 61, color: '#77A1D3', inner: [ { title: 'Volatility', percent: 0 }, { title: 'Withdrawal', percent: 0 } ] },
        { title: 'Openness to Experience', percent: 45, color: '#79CBCA', inner: [ { title: 'Intellect', percent: 0 }, { title: 'Openness', percent: 0 } ] }
      ]
    ],
    resultProductivityType:
    {
      description20Percent: {
        warnings: [
          {
            title: 'Bright Shiny Object Syndrome',
            description: 'Easily distracted by new ideas and projects. Working with a coach can help mitigate distractions and see goals to completion.'
          },
          {
            title: 'High risk for ADHD-type symptoms',
            description: 'People with your productivity type are more likely to be diagnosed with ADD/ADHD in their lifetimes. Working with a coach can unlock latent potential by providing tactical high functioning habits and planning rituals.'
          },
          {
            title: 'Trouble Accessing Willpower',
            description: 'Finding the raw will and energy to get started and finish things is difficult. Adding external accountability with pro coaching will ensure the structure that makes willpower unnecessary is a constant in your life.'
          }
        ],
        content: (
          <div className='text-[17px]'>
            <p>Your score reveals a productivity style that is instinctual, spontaneous and opportunistic, often driven by the possibilities of the moment. In a world dominated by plans and schedules, your unique approach allows you to adapt quickly and take advantage of opportunities that others might overlook.</p><br/>
            <p>In order to be the most effective version of yourself, you should utilize social support in the form of coaching, peer-support (via masterminding etc) or mentorship to channel your spontaneity into actionable steps. This might involve:</p><br/>
            <p className="list"><span><b>Establishing a flexible structure:</b> While traditional, rigid schedules might feel stifling, a basic structure can help ensure you’re moving in the right direction. Have someone work with you to develop a ’loose’ framework that leaves room for creativity and spur-of-the-moment decisions.</span></p>
            <p className="list"><span><b>Prioritizing tasks:</b> While you’re not one for to-do lists, understanding what tasks hold the most value can help you channel your energy more effectively. The key is identifying these priority tasks by triaging your best opportunities into top priorities vs things to ignore for now.</span></p>
            <p className="list"><span><b>Building habits:</b> While spontaneity is a strength, certain habits can also support your productivity. Small, manageable habits that compound over time will move you closer to idealized self and make deeper, focused work easier.</span></p>
            <p className='mt-[20px]'><span className='link'><Link href="/">Commit Action’s accountability coaching</Link></span> you with the ideal support to help you harness your natural energy and creativity, while providing the structure and direction you need to be more consistently productive.</p>
          </div>
        )
      },
      description20To40Percent: {
        warnings: [
          {
            title: 'Only motivated when passionate',
            description: 'Lacking discipline to take action even when you’re not totally a-flame with passion for the project is a major weakness. An external accountability coach can provide the structure and planning rituals that makes disciplined execution easier even when passion is missing.'
          },
          {
            title: 'Overwhelm Freeze',
            description: 'High risk or busy situations can quickly paralyze you with overwhelm. Working with a coach will talk through acute challenges to get you taking the first (hardest) step faster.'
          }
        ],
        content: (
          <div className='text-[17px]'>
            <p>Your score reveals a productivity style that is flexible and adaptable, yet also recognizes the value of some level of structure and planning. You are able to respond to changing circumstances with ease, yet you also understand the need for a certain level of organization and foresight.</p><br/>
            <p>To optimize this balance and enhance your productivity, consider the following:</p><br/>
            <p className="list"><span><b>Incorporating Time Blocking:</b> Though you value flexibility, integrating time blocking into your week will be beneficial. Your weeks tend to get away from you, so carving out “sacred time” for your top priorities will ensure those goals are not compromised.</span></p>
            <p className="list"><span><b>Establishing Routine:</b> Developing a weekly planning routine can help create a sense of stability and drive productivity. Dedicate some time to planning the optimal use of ALL your time.</span></p>
            <p className="list"><span><b>Developing Accountability Systems:</b> Having someone to hold you accountable can be a powerful motivator. Whether through coaching, a peer group, or mentorship, sharing your intentions and plans creates an external expectation that can push you to stay focused on your priorities.</span></p>
            <p className='mt-[20px]'><span className='link'><Link href="/">Commit Action’s coaches</Link></span> are skilled in helping entrepreneurs like you add more structure to your natural adaptability and spontaneity, unlocking your latent potential to achieve your biggest goals.</p>
          </div>
        )
      },
      description40To60Percent: {
        warnings: [
          {
            title: 'Start-Stop Sabotage',
            description: 'You’re at risk for sabotaging behavior where you try new ideas but don’t ship/finish. Work with a coach to add additional accountability to now allow yourself to slow down on good ideas when they’re 90% complete.'
          },
          {
            title: 'Action-Result Gap',
            description: 'Long term projects that require sustained work without immediate success indicators or feedback can be problematic for you. Work with a coach to gamify your short term action goals to create productive habits to ship long term big projects.'
          }
        ],
        content: (
          <div className='text-[17px]'>
            <p>Your score suggests a balanced productivity style. You value both structure and spontaneity, and understand that both discipline AND flexibility are essential to success.</p><br/>
            <p>To optimize this balance and foster consistent productivity, consider the following:</p><br/>
            <p className="list"><span><b>Implementing Time Blocking:</b> Scheduling specific times for important tasks ensures that you’re giving adequate attention to your priorities, reducing the chance of overlooking important tasks even when you’re responding to urgent inbound items.</span></p>
            <p className="list"><span><b>Cultivating Discipline:</b> There might be times when you need to push through resistance and get things done. Relying on willpower alone can fail you, instead look to build practices to ship bold, creative work regularly to build your mental muscle for it.</span></p>
            <p className="list"><span><b>Engaging Social Accountability:</b> Share your plans and goals with a coach, accountability group, or mentor. This added layer of social expectation can bolster your motivation to maintain your productive habits, even when you may not feel like it or are otherwise struggling to be your best self.</span></p>
            <p className='mt-[20px]'><span className='link'><Link href="/">Commit Action’s accountability coaching</Link></span> would pair you with the ideal support to help you harness your natural energy and creativity, while optimizing your discipline with evidence based structure and accountability.</p>
          </div>
        )
      },
      description60To80Percent: {
        warnings: [
          {
            title: 'Busy Not Effective',
            description: 'You’re not afraid of hard work but can get caught up deep-working too thoroughly on the wrong things. Working with a coach will get you strategic priorities optimized so you can throw yourself into execution, confident you’re focused on the right project.'
          },
          {
            title: 'Grass-is-greener syndrome',
            description: 'You often find yourself fantasizing about switching fields, goals or business models — when you see others succeeding you get tempted to emulate their path. Working with a coach will clarify and affirm your own self-designed path, creating alignment and internally powered motivation.'
          }
        ],
        content: (
          <div className='text-[17px]'>
            <p>Your score reveals a productivity style that is methodical, diligent, and structured. You have a impressive capacity to plan and execute. Being strategic and judicious about where you channel your energy is critical for someone with your focus and discipline.</p><br/>
            <p>To ensure that your high level of conscientiousness is channeled in the most effective way, consider the following:</p><br/>
            <p className="list"><span><b>Weekly prioritization ritual:</b> You’re good at putting your head down to work, so add structure of a habitual weekly ritual to think big picture about where you’re channeling that energy. Schedule an appointment with yourself (or with someone else who can facilitate) to think critically about your biggest opportunities and best uses of time. Do this before you start sprinting on a project.</span></p>
            <p className="list"><span><b>Avoiding Burnout:</b> You are inclined to work hard, which can sometimes lead to overworking. Recognize when it’s time to step back and recharge to maintain consistent productivity. You’re a natural “sprinter”, but big goals are marathons.</span></p>
            <p className="list"><span><b>Leverage Social Accountability:</b> While you’re capable of maintaining high levels of productivity, sharing your plans and progress with a coach or accountability group can provide an added layer of mental optimization. They can help you stay focused on the highest leverage activities over the long term, preventing burn out and mental noise from your inner-critic distracting you.</span></p>
            <p className='mt-[20px]'><span className='link'><Link href="/">Commit Action’s coaches</Link></span> are purpose-trained to support high achieving entrepreneurs, creatives and execs like you to maximize their personal effectiveness. The right weekly planning ritual facilitated by a pro keep you in the deep-work zone at all times.</p>
          </div>
        )
      },
      description80To100Percent: {
        warnings: [
          {
            title: 'Perfectionism tendencies',
            description: 'You often hold unreasonably high expectations for yourself. You know you’re capable of more, which makes it hard to finish projects. Working with a coach will provide external accountability that ensures you don’t unconsciously move the goal posts—you’ll ship more work, faster.'
          },
          {
            title: 'Overactive Inner Critic',
            description: 'Your internal mental dialogue can be devastatingly harsh, leading to sabotaging negative emotional states. Working with a coach adds an authoritative, external cheerleader to balance and optimize your mindset.'
          },
          {
            title: 'Burn Out Risk',
            description: 'You’re so good at pushing yourself to work hard and delay gratification that self-care doesn’t always come naturally to you. Working with a coach will add external support and structure that creates sustainable motivation and drive. Think marathons not sprints.'
          }
        ],
        content: (
          <div className='text-[17px]'>
            <p>Your score reveals an exceptional productivity style that is highly focused, organized, and diligent. You have an extraordinary ability to plan, prioritize, and execute tasks. Delaying gratification—sacrificing today for tomorrow’s goals—is something that comes so naturally to you that you sometimes need help with slowing down, enjoying the moment and taking care of yourself.</p><br/>
            <p>To manage this intense level of hard-working focus and drive and ensure it doesn’t lead to burnout, consider the following:</p><br/>
            <p className="list"><span><b>Weekly prioritization ritual:</b> You’re good at putting your head down to work, so add structure of a habitual weekly ritual to think big picture about where you’re channeling that energy. Schedule an appointment with yourself (or with someone else who can facilitate) to think critically about your biggest opportunities and best uses of time. Your ability to execute on ideas is a weapon, be mindful where you aim it!</span></p>
            <p className="list"><span><b>Balancing Work and Rest:</b> Your natural drive might push you to keep working, but everyone needs rest. The biggest and most fulfilling goals in life require many years of work, even for highly effective people. Identifying when you’re running a metaphorical marathon vs sprinting on a short term objective is critical for you.</span></p>
            <p className="list"><span><b>Implementing Social Accountability:</b> Despite your high level of self-discipline, sharing your plans with a coach, accountability group, or mentor can ensure that you’re focusing on the best opportunities, offer a sounding board for your high expectations, and provide the support you need to prevent burnout.</span></p>
            <p className='mt-[20px]'><span className='link'><Link href="/">Commit Action</Link></span> supports highly focused and driven entrepreneurs, creatives and executives by providing an evidence based methodology that identifies the best, highest-leverage use of your time. A highly trained accountability coach will ensure you’re aimed, optimized and working in precisely the right direction—while also adding the accountability you need to make sure self care and mindfulness doesn’t slip by the wayside.</p>
          </div>
        )
      }
    },
    resultDescription: [
      {
        title: 'Agreeableness',
        descriptionGeneral: `
        Trait agreeableness gauges the way you relate to people — both how you connect with
        individuals and how you fit into society. It’s a measure of niceness, tolerance for conflict, how
        well you work in teams, submissiveness/dominance, orientation toward others, and respect for
        the traditional institutional pecking order. <br/><br/>
        Overall, agreeableness is generally correlated negatively with entrepreneurial success. On an
        individual level, entrepreneurs don’t shy away from conflict and competition, especially when it
        comes to their goals. They also aren’t willing to sacrifice things they want and need for the
        benefit of others. On a larger scale, entrepreneurs tend to balk at the bureaucracy and hierarchy
        in education and the corporate world. They’re skeptical of most people in positions of authority
        until their respect is earned—they’re unlikely to defer to someone else based on their age,
        position or rank in an organization. Entrepreneurs are often motivated to break free from
        traditional hierarchical constraints, to build something on their own and be recognized for it. <br/><br/>
        Agreeableness is made up of the aspects of:<br/><br/>
        <p class="list">Compassion — a measure of your orientation toward the problems and needs of others,
        and the extent of your sphere of humanization of others.</p>
        <p class="list">Politeness — not etiquette or manners, but an index of your respect for authority and
        institutional hierarchy.</p>
        <p class="mt-6">If a successful entrepreneur scores high on agreeableness, it’s often because a very high
        compassion aspect outweighs a lower politeness score. After all, many entrepreneurs are driven
        by a desire to help people and improve the world. Very few entrepreneurs score high on
        politeness.</p>
        `,
        description20Percent: `
        You are exceptionally low in agreeableness, which is the index of how you relate to others in the
        Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you are most likely to be described as stubborn, competitive, and dominant.
        Internally, you’re aware of many zero-sum games and aren’t afraid to play them. You’re deeply
        machiavellian though you may choose to hide it. You shun the hierarchy of traditional
        institutions in education and the corporate world and are unlikely to defer to someone purely
        because of their place in the pecking order.<br/><br/>
        You dislike working in a team setting, preferring instead to work as an individual contributor or
        by delegating to a subordinate team.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">You possess strong leadership traits and are very confident in your skills and ability</p>
        <p class="list">You’re extremely straightforward, which eliminates any ambiguity between you and
        others</p>
        <p class="list">You don’t shy away from conflict, which is often an unavoidable part of navigating
        early-stage startup life</p>
        <p class="list">You’re a vocal advocate for yourself</p>
        <p class="list">You can easily and happily operate outside of the hierarchy of the more traditional
        business world</p>
        <p class="list">You can produce results regardless of the circumstances</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:
        <br/><br/>
        <p class="list">Be perceived as difficult to work with</p>
        <p class="list">Be harsh with others</p>
        <p class="list">Prematurely discount the wisdom and experience of your seniors</p>
        <p class="list">Lose rapport with others who perceive you as machiavellian</p>
        <br/>
        When you are at your best, you can navigate any conflict with ease, unafraid of others’
        judgments of you. You stand by your ideas no matter what. Partnerships can be volatile for you,
        but it doesn’t matter because you bet on yourself.
        `,
        description20To40Percent: `
        You are moderately low in agreeableness, which is the index of how you relate to others in the
        Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you may be described as stubborn, competitive, and dominant. Internally,
        you can spot zero-sum business games and can even be willing to play them. You can be
        machiavellian at times, though you may choose to hide it. You don’t put much stock in the
        hierarchy of traditional institutions in education and the corporate world and are unlikely to defer
        to someone purely because of their place in the pecking order.<br/><br/>
        You may dislike working in a team setting, preferring instead to work as an individual contributor
        or by delegating to subordinates.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">You are quite confident in your skills and ability</p>
        <p class="list">You’re often straightforward, which eliminates any ambiguity between you and others</p>
        <p class="list">You aren’t particularly conflict averse, which can come in handy in the world of startups</p>
        <p class="list">You can operate outside of the hierarchy of the more traditional business world</p>
        <p class="list">You can produce results regardless of the circumstances</p>
        <br/>
        <p>The challenges you’ll face in life and business stem from your tendencies to</p>
        <br/>
        <p class="list">Be perceived as volatile difficult to work with</p>
        <p class="list">Be harsh with others</p>
        <p class="list">Prematurely discount the wisdom and experience of your seniors</p>
        <br/>
        When you are at your best, you can navigate mody conflict with ease. You’re rarely unafraid of
        others’ judgments of you. You stand by your ideas no matter what. Partnerships can sometimes
        be volatile for you, but it doesn’t matter because you’re usually betting on yourself more than
        others.
        `,
        description40To60Percent: `
        You are about typical or average in agreeableness, which is the index of how you relate to
        others in the Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you are as likely as the average person to be described as kind,
        team-oriented, thoughtful, and cooperative. You are somewhat interested in bringing out the
        best in others, sometimes trust those around you, and are generally tolerant of others’ quirks
        and differences.<br/><br/>
        You are about as conflict-averse as the average person and may dislike competitive, cutthroat
        environments. The idea of seeming too assertive or self-aggrandizing is something you flirt with
        but can also be off-putting to you, sometimes at the expense of sharing your ideas and goals.
        You’re as likely as average to be described as being “too nice.”<br/><br/>
        Here are some tendencies you are likely to possess in similar amounts to the average person:
        <br/><br/>
        <p class="list">You are likeable and work well with others</p>
        <p class="list">You can often find the right balance, between pushy and friendly, to sell effectively</p>
        <p class="list">You want to help the people around you</p>
        <p class="list">You’re empathetic to the needs of others</p>
        <br/>
        The following areas will be a comfortable balance for you as an entrepreneur:
        <br/><br/>
        <p class="list">Confrontation vs. avoiding confrontation</p>
        <p class="list">Prioritizing yourself vs. prioritizing others</p>
        <p class="list">Negotiating on your own behalf vs. keeping the peace</p>
        <p class="list">Obeying authority figures vs. questioning their decisions, experience and motives</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your agreeableness puts you at risk of not prioritizing
        yourself enough to get clear on what you need to do to move forward. Over time, you could burn
        out.
        <br/><br/>
        When you are at your best, you are able to delve into people’s problems to figure out how to
        solve them, are great at engaging and connecting people, and can foster cooperation in groups
        to get things done! You can step up to lead and assert yourself when necessary.
        `,
        description60To80Percent: `
        You are moderately high in agreeableness, which is the index of how you relate to others in the
        Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you are more likely than the average person to be described as kind,
        team-oriented, thoughtful, and cooperative. You are interested in bringing out the best in others,
        trust those around you, and are generally tolerant of others’ quirks and differences. Your teams
        that work for you often report that working with you is a warm, caring and overall positive
        experience.<br/><br/>
        You tend to avoid conflict when you can and dislike competitive, cutthroat environments. The
        idea of seeming too assertive or self-aggrandizing is off-putting to you, sometimes at the
        expense of sharing your ideas and goals. You’re more likely than average to be described as
        “too nice.”<br/><br/>
        You’re likely to be drawn to impact related projects and missions. You’re sensitive to suffering
        and are motivated to make the world a better place.
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">You are likeable and work well with others</p>
        <p class="list">You want to help the people around you</p>
        <p class="list">You’re empathetic to the needs of others</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:
        <br/><br/>
        <p class="list">Dislike confrontation</p>
        <p class="list">Sometimes prioritize others above yourself</p>
        <p class="list">Occasionally fail to negotiate on your own behalf to keep the peace</p>
        <p class="list">Default to obeying authority figures without much questioning of their decisions</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your agreeableness puts you at risk of not prioritizing
        yourself enough to get clear on what you need to do to move forward. You can martyr yourself
        to others and their causes. Over time, this could lead to disillusionment and burn out.<br/><br/>
        When you are at your best, you are able to delve into people’s problems to figure out how to
        solve them, are great at engaging and connecting people, and can foster cooperation in groups
        to get things done!
        `,
        description80To100Percent: `
        You are exceptionally high in agreeableness, which is the index of how you relate to others in
        the Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you are most likely to be described as kind, team-oriented, thoughtful, and
        cooperative. You want to bring out the best in others, trust easily, and are extremely tolerant of
        others’ quirks and differences. Your teams routinely report that working with you is a warm,
        caring and overall positive experience.<br/><br/>
        You tend to avoid conflict at all costs and strongly dislike competitive, cutthroat environments.
        The idea of seeming too assertive or self-aggrandizing is extremely distasteful to you,
        sometimes at the expense of openly sharing your ideas and goals. You’re often described as
        “too nice.”<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">You work extremely well with others and are very likeable</p>
        <p class="list">You are passionate about helping others</p>
        <p class="list">You’re deeply empathetic to the needs of others</p>
        <p class="list">You’re an empath deeply attuned to the emotions of others</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:
        <br/><br/>
        <p class="list">Avoid confrontation, even when it’s necessary</p>
        <p class="list">Prioritize others above yourself</p>
        <p class="list">Fail to negotiate on your own behalf</p>
        <p class="list">Obey authority figures without questioning their decisions or motives</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your high agreeableness puts you at risk of not
        prioritizing yourself enough to get clear on what you need to do to move forward. You can
        martyr yourself to others and their causes without adequately taking care of your needs. Over
        time, this often leads to disillusionment and burn out.<br/><br/>
        When you are at your best, you are able to delve deep into people’s problems to figure out how
        to solve them, are an expert at engaging and connecting people, and can foster cooperation in
        groups to get things done. Everyone who works with you finds you delightful!
        `,
        inner: [
          {
            title: 'Compassion',
            descriptionGeneral: `
            Compassion is one of two aspects of agreeableness, which is the index of how you relate to
            others in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs who score high on compassion are conscious and empathetic of others. They
            have a high level of concern for their fellow human beings and living beings in general. They will
            happily invest their time and efforts to ensure that the people around them are happy and
            comfortable.<br/><br/>
            People who work with them or know them personally report that they are very easy to talk to,
            listen well, and are sympathetic and/or empathetic.<br/><br/>
            Very compassionate people are at risk of prioritizing others to such a degree that their own
            needs, desires, and ambitions fall by the wayside.<br/><br/>
            `,
            description20Percent: `
            You are very low on compassion, which is one of two aspects of agreeableness in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are very focused on themselves rather than focusing on others. You are
            unaffected by the feelings of others or their stories, and you rarely care to do anything to make
            someone feel better unless it also benefits you.<br/><br/>
            You have no issue causing unhappiness for others if it means you can get what you want. It’s
            borderline impossible for anyone to take advantage of you.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your ability to insist on getting what you’re owed</p>
            <p class="list">Being able to disregard the feelings of others when making decisions</p>
            <p class="list">Your straightforwardness and honesty at all costs, regardless of how what you say could affect someone else</p>
            <p class="list">Your love of being #1</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Come off as mean, which can impact your connections with others</p>
            <p class="list">Have difficulty sympathy/empathy for other people</p>
            <p class="list">Thrive on conflict</p>
            `,
            description20To40Percent: `
            You are moderately low on compassion, which is one of two aspects of agreeableness in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you tend to focus more on yourself than others compared to the average
            person. You aren’t particularly affected by the feelings of others or their stories, and you’re
            unlikely to go to great lengths to make someone feel better.<br/><br/>
            You aren’t afraid of stepping on anyone’s toes in your quest to get what you want, which makes
            it very hard for anyone to take advantage of you.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your ability to advocate for yourself to get what you’re owed</p>
            <p class="list">Being emotionally unshakeable</p>
            <p class="list">Your straightforwardness — you tell it like it is, even if it could hurt someone’s feelings</p>
            <p class="list">Your love of competition</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Come off as mean, which can impact your connections with others</p>
            <p class="list">Have difficulty empathizing/sympathizing for other people</p>
            <p class="list">Tolerate or even enjoy conflict</p>
            `,
            description40To60Percent: `
            You are typical or average in compassion, which is one of two aspects of agreeableness in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are about as in tune with the needs, desires, and happiness of others as
            the average person. You are about as likely to have difficulty advocating for yourself as is
            typical.<br/><br/>
            Generally, you care about the emotional experiences of other people, but you’re not going to let
            them get in the way of getting what you feel you deserve.<br/><br/>
            Here are some tendencies you are likely to possess in similar amounts to the average person:<br/><br/>
            <p class="list">Sympathy and empathy for others</p>
            <p class="list">Investment in other people’s problems</p>
            <p class="list">Kindness and givingness</p>
            <br/>
            The following areas will be a comfortable balance for you as an entrepreneur:<br/><br/>
            <p class="list">Winning vs. ensuring other people have a fair shot</p>
            <p class="list">Helping others vs. looking out for yourself</p>
            <p class="list">Making sacrifices vs. doing what you need to do to put yourself first</p>
            <p class="list">Advocating for yourself vs. letting things go</p>
            <p class="list">Telling the truth vs. sugarcoating things to avoid conflict</p>
            `,
            description60To80Percent: `
            You are moderately high in compassion, which is one of two aspects of agreeableness in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are more in tune with the emotional experiences of the people around
            you than the average person. You are willing to work to ensure the happiness of others, even
            occasionally to your own detriment. You’re a good listener and can access your soft side.<br/><br/>
            You may end up missing out on the rewards of your hard work because you don’t always want
            to advocate for what you deserve.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your reputation as thoughtful, caring, and kind</p>
            <p class="list">Your excellent listening skills</p>
            <p class="list">Your orientation toward solving the problems of the world</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Put others’ needs and wants above your own often</p>
            <p class="list">Difficulty negotiating for what you deserve in uncomfortable situations</p>
            <p class="list">Avoidance of others’ discomfort, even when it’s necessary</p>
            `,
            description80To100Percent: `
            You are exceptionally high in compassion, which is one of two aspects of agreeableness in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are extremely in tune with the emotional experiences of the people
            around you. You will go to great lengths to ensure the happiness of others, even to your own
            detriment. You have a very soft side and people are likely to seek you out as a fantastic listener.<br/><br/>
            You hate conflict of any kind, and will do everything in your power to avoid it. Asking for what
            you want and need is extremely difficult for you, especially if it could inconvenience others at all.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your reputation as extremely thoughtful, caring, and kind</p>
            <p class="list">Your superb listening skills</p>
            <p class="list">Your orientation toward solving the problems of the world</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Put others’ needs and wants above your own every time</p>
            <p class="list">Difficulty negotiating for what you deserve in any situation</p>
            <p class="list">Avoidance of others’ discomfort at all costs</p>
            `
          },
          {
            title: 'Politeness',
            descriptionGeneral: `
            Politeness is one of two aspects of agreeableness, which is the index of how you relate to
            others in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs who score high on politeness have inherent respect for authority figures and are
            very hesitant to upset existing hierarchies. They are obedient and respectful, sometimes to a
            fault.<br/><br/>
            People who work with them or know them personally say that they are happy to pay their dues
            by putting in the time in junior positions. They enjoy knowing their place, working their way
            patiently up and love to contribute as a part of a larger institution. They are known for avoiding
            conflict as much as possible.<br/><br/>
            `,
            description20Percent: `
            You are exceptionally low on politeness, which is one of two aspects of agreeableness in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you have strong disdain for authority and traditional hierarchies. You are very
            unlikely to obey someone or give them respect unless they deserve it in your eyes. If you don’t
            agree with someone, you will resist strongly and without hesitation.<br/><br/>
            You never suffer fools gladly. You default to being extremely skeptical of other people’s
            intelligence and standing. Everyone must thoroughly prove themselves in your eyes.
            You are generally dominant in both social and professional situations, and you are happy to
            confront others early and often.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">You’re unafraid to fight for what you think is right, regardless of who your opponent is</p>
            <p class="list">You hate institutional hierarchies and structures of power and are willing to go to great
            lengths to break out of them</p>
            <p class="list">You are naturally skeptical and are unwilling to do anything just because someone told
            you to</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Overpower others in social and professional situations</p>
            <p class="list">Infuriate the “old guard” and people in positions of power with your insolence</p>
            <p class="list">Fail to see the point of “paying your dues”</p>
            `,
            description20To40Percent: `
            You are moderately low on politeness, which is one of two aspects of agreeableness in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you dislike authority and traditional hierarchies. You are unlikely to obey
            someone or give them respect unless they deserve it in your eyes. If you don’t agree with
            someone, you are not afraid to resist and share your point of view.<br/><br/>
            You seldom suffer fools gladly. You tend to be skeptical of other people’s intelligence and
            standing. Almost everyone must prove themselves in your eyes.<br/><br/>
            You are sometimes dominant in both social and professional situations, and you may find you
            rub authority figures the wrong way.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">You’re rarely afraid to fight for what you think is right</p>
            <p class="list">You’re naturally inclined to try to break out of traditional hierarchies and structures of
            power</p>
            <p class="list">You are naturally skeptical and are unlikely to do something just because someone told
            you to</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Sometimes overpower others in social and professional situations</p>
            <p class="list">Frustrate people in positions of power with your skeptical attitude</p>
            <p class="list">Struggle to see the point of “paying your dues”</p>
            `,
            description40To60Percent: `
            You are typical or average on politeness, which is one of two aspects of agreeableness in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are as likely to respect authority and traditional hierarchies as the
            average person. You often obey the requests and demands of others, though you may push
            back if you have serious reservations.<br/><br/>
            You may find it difficult to stand up for yourself against other people, but you can when the
            situation calls for it.<br/><br/>
            Here are some tendencies you are likely to possess in similar amounts to the average person:<br/><br/>
            <p class="list">Deference to people who rank above you</p>
            <p class="list">Affinity for institutions and willingness to pay your dues</p>
            <p class="list">Aversion to confrontation</p>
            <br/>
            The following areas will be a comfortable balance for you as an entrepreneur:<br/><br/>
            <p class="list">Respect for authority vs. going your own way</p>
            <p class="list">Avoiding conflict vs. having healthy conflict when necessary</p>
            `,
            description60To80Percent: `
            You are moderately high in politeness, which is one of two aspects of agreeableness in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are often deferential to people higher up on the ladder than you are. You
            hesitate to make waves when possible, preferring instead to go along to get along.<br/><br/>
            You enjoy being a part of something bigger, working with senior people and knowing your place
            as a part of a greater whole.<br/><br/>
            Confrontation is not your strong suit, and you often do what you can to avoid butting heads with
            others.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your respect for others, especially those who outrank you in age or experience</p>
            <p class="list">Your ability to operate within the confines of bureaucracy when necessary</p>
            <p class="list">Your desire to mitigate conflict when you can</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Avoid challenging others</p>
            <p class="list">Hate conflict, even when it may be necessary</p>
            `,
            description80To100Percent: `
            You are exceptionally high in politeness, which is one of two aspects of agreeableness in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are instinctively deferential to people higher up on the ladder than you
            are. You hesitate to make waves for any reason, preferring instead to acquiesce to the requests
            of others.<br/><br/>
            You love being a part of something bigger, working with senior people and knowing your place
            as a part of a greater whole.<br/><br/>
            Many people would call your desire to avoid confrontation excessive, and you’ll do anything in
            your power to avoid butting heads with others.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your (blind) respect for others, especially those who outrank you in age or experience</p>
            <p class="list">Your ease of operation within the confines of bureaucracy</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Avoid challenging others, even when it’s necessary</p>
            <p class="list">Hate conflict, even when it’s crucial to move your business forward or do the right thing</p>
            `
          }
        ]
      },
      {
        title: 'Conscientiousness',
        descriptionGeneral: `
        Trait conscientiousness is a measure of an entrepreneur’s ability to accept delayed gratification
        in exchange for long-term gains. It is often acknowledged to be the strongest indicator of
        success in most areas of life. It gauges an entrepreneur’s patience, work ethic, self-discipline,
        organization, dependability, orientation toward detail, and determination.<br/><br/>
        A conscientious person makes a plan, then executes that plan right on schedule. They embrace
        temporary discomfort, obstacles, and focus now for a payoff much later. They are masters of
        delayed gratification. Decision-making comes easily to them, and they operate with an eye to
        the future.<br/><br/>
        Conscientious entrepreneurs hate to waste time and they rarely fall victim to procrastination.
        Disorder and physical and mental clutter irritate them, and they are more likely to see the world
        in black and white.<br/><br/>
        Conscientiousness is made up of of the aspects of:<br/><br/>
        <p class="list"><b>Industriousness</b> — Diligence, willingness to apply oneself, penchant for hard work,
        sacrifice of the present for future results</p>
        <p class="list"><b>Orderliness</b> — Organization, distaste for chaos, likelihood to judge others, affinity for
        lists, calendars, spreadsheets, and routine more generally</p>
        `,
        description20Percent: `
        You are exceptionally low in conscientiousness, the primary index of ability to accept delayed
        gratification in exchange for long-term gains in the Commit Action entrepreneurial traits
        model.<br/><br/>
        Entrepreneurs who score low in conscientiousness tend to take a relaxed approach to their work
        and personal obligations. They’re much less driven by a sense of duty, responsibility and
        commitment than the average person.<br/><br/>
        Those who work with you will likely say you have a tendency to flout authority and play by your
        own rules. They may be hesitant to rely on you to follow through on your promises. You’re likely
        disorganized (and don’t care that you are), and think of yourself generally as a nonconformist.
        <br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">You’re spontaneous and can seize opportunities when they come along</p>
        <p class="list">You’re a committed hedonist who always prioritizes fun in the present</p>
        <p class="list">You don’t care at all about being perfectly organized</p>
        <p class="list">You scorn the idea of a 5- or 10-year plan and can be flexible easily</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:
        <br/><br/>
        <p class="list">Fail to deliver work on time or as expected</p>
        <p class="list">Not care about your responsibility or duty to others</p>
        <p class="list">Be willing to miss out on or ruin work and relationship opportunities</p>
        <p class="list">Miss things because of your lack of a schedule or calendar</p>
        <p class="list">Be unable to wrap your head around the concept of sacrificing now for success in the
        future</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your low conscientiousness may cause you to make
        impulsive decisions that might feel good now but will have poor long term consequences.
        <br/><br/>
        When you are at your best, you may perversely find arbitrage business ideas and the people
        you need to do the execution to build them.
        `,
        description20To40Percent: `
        You are moderately low in conscientiousness, the primary index of ability to accept delayed
        gratification in exchange for long-term gains in the Commit Action entrepreneurial traits
        model.<br/><br/>
        Entrepreneurs who score low in conscientiousness tend to take a more relaxed approach to
        their work and personal obligations than average. They’re less driven by a sense of duty,
        responsibility and commitment than the typical professional.<br/><br/>
        Those who work with you will likely say you have a tendency not to worry about authority and
        play by your own rules. They may be hesitant to rely on you to follow through on your promises.
        You’re likely disorganized and think of yourself generally as a nonconformist.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">You’re spontaneous and can seize opportunities when they come along</p>
        <p class="list">You’re a hedonist who knows how to have fun</p>
        <p class="list">You don’t get too hung up on being perfectly organized</p>
        <p class="list">You aren’t married to a 5- or 10-year plan and can be flexible</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:
        <br/><br/>
        <p class="list">Often fail to deliver work on time or be hard pressed by deadlines</p>
        <p class="list">Feel little in terms of responsibility or duty to others</p>
        <p class="list">Have a devil-may-care attitude about your work and relationships</p>
        <p class="list">Miss things because of your lack of a schedule or calendar</p>
        <p class="list">Struggle to sacrifice now for success in the future</p>
        <br/><br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your low conscientiousness may cause you to make
        impulsive decisions that you’ll ultimately regret.<br/><br/>
        When you are at your best, you may perversely find arbitrage business ideas and push yourself
        to do the work you need to make money while you sleep.
        `,
        description40To60Percent: `
        You are typical or average in conscientiousness, the primary index of ability to accept delayed
        gratification in exchange for long-term gains in the Commit Action entrepreneurial traits
        model.<br/><br/>
        You can handle failure about as well as others, and take accountability for your actions relatively
        seriously. Your commitments are about as important to you as the average person, and though
        you may procrastinate, you generally complete work on time and in good shape.<br/><br/>
        You’re reasonably diligent and work hard when necessary, though you feel that delivering
        excellent work is far from the most important thing in life. Disorganization and clutter may slightly
        bother you, but don’t cripple you in the moment.<br/><br/>
        Here are some tendencies you are likely to possess in similar amounts to the average person:
        <br/><br/>
        <p class="list">Orientation toward detail</p>
        <p class="list">Willingness to struggle in the present with an eye toward achievement in the future</p>
        <p class="list">A strong work ethic</p>
        <p class="list">A sense of duty</p>
        <p class="list">Ability to deliver great work on time</p>
        <p class="list">Resisting procrastination</p>
        <p class="list">Decisiveness</p>
        <br/>
        The following areas will be a comfortable balance for you as an entrepreneur:
        <br/><br/>
        <p class="list">Overwork vs. negligence of work</p>
        <p class="list">Managing vs. being managed</p>
        <p class="list">Feeling guilty over failure vs. forgiving yourself</p>
        <p class="list">Working hard vs. getting lucky</p>
        <p class="list">Putting things off vs. completing them on strict deadlines</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your typical conscientiousness may allow you to
        balance feeling upset or guilty about being in a rut with diligently trying to figure out how to move
        forward.<br/><br/>
        When you are at your best, you balance your ability to deliver great work on time with the
        flexibility of not being overly attached to a schedule that may need to shift.
        `,
        description60To80Percent: `
        You are moderately high in conscientiousness, the primary index of ability to accept delayed
        gratification in exchange for long-term gains in the Commit Action entrepreneurial traits
        model.<br/><br/>
        You are extremely driven by a sense of obligation to those you work with and for. You deliver
        high-quality results on time and under budget every time. You always want to be doing
        something productive, or you may feel lazy otherwise. Others often describe you as “living to
        work.”<br/><br/>
        You’re more organized, thoughtful in your work, and diligent than the average person. Disorder
        and lack of routine and organization are irritating and distracting to you. It can be difficult for you
        to leave things to chance or go with the flow.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">Your ability to focus whatever is most important</p>
        <p class="list">Your reliability</p>
        <p class="list">Your ability, generally, to prioritize future success over present discomfort</p>
        <p class="list">Your strong work ethic</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:
        <br/><br/>
        <p class="list">Get upset or feel guilty when things go wrong</p>
        <p class="list">Get frustrated with disorganization or mental/physical clutter</p>
        <p class="list">Occasionally judge others for perceived incompetence or sloppiness</p>
        <p class="list">Fail to see shades of gray when making decisions</p>
        <p class="list">Be too attached to routine and schedules</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your high conscientiousness may cause you to be
        overly perfectionist. You may fail to see shortcuts and lateral opportunities when you are too
        focused on grinding through work you’ve committed yourself to.<br/><br/>
        When you are at your best, you can work hard and persevere through difficulty in the present
        because you have the future in view. You can outsmart and outwork your competitors.
        `,
        description80To100Percent: `
        You are exceptionally high in conscientiousness, the primary index of ability to accept delayed
        gratification in exchange for long-term gains in the Commit Action entrepreneurial traits
        model.<br/><br/>
        You are extremely driven by a sense of obligation to those you work with and for. That
        obligation extends to your future self as you willingly sacrifice the present for that future. You
        deliver high-quality output whatever you’re working on. You always want to be doing something
        productive and you even get a slightly masochistic kick out of “the grind”. Others often describe
        you as “living to work.”<br/><br/>
        You’re extremely organized, thoughtful in your work, and diligent. Disorder and lack of routine
        and organization drive you crazy. It’s extremely difficult for you to leave anything to chance or
        go with the flow.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">Your laser focus on whatever is most important</p>
        <p class="list">Your reliability regardless of the circumstances</p>
        <p class="list">Your ability and even satisfaction to prioritize future success over present discomfort</p>
        <p class="list">Your relentless work ethic</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:
        <br/><br/>
        <p class="list">Beat yourself up when things go wrong</p>
        <p class="list">Get bogged down by disorganization or mental/physical clutter</p>
        <p class="list">Judge others for perceived incompetence or sloppiness</p>
        <p class="list">Be harsh on yourself and your own worst critic</p>
        <p class="list">See things as all-or-nothing</p>
        <p class="list">Be inflexible about plans and schedules</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your high conscientiousness may cause you to
        castigare yourself because things are going wrong or see the situation, others or yourself as not
        good enough.<br/><br/>
        When you are at your best, you can work hard and persevere through difficulty in the present
        because you have the future in view. Everyone around you trusts you to come through for them
        no matter what. You get more done in a week than most do in a month.
        `,
        inner: [
          {
            title: 'Industriousness:',
            descriptionGeneral: `
            Industriousness is one of two aspects of conscientiousness, which is the primary index of ability
            to accept delayed gratification in exchange for long-term gains in the Commit Action
            entrepreneurial traits model.<br/><br/>
            Entrepreneurs who score high on industriousness are paragons of productivity—they prefer to
            spend all of their time doing “productive” things and derive most of their fulfillment from their
            work. They do not procrastinate and are constantly trying to figure out ways to be more efficient.<br/><br/>
            People who work with them or know them personally report that they are extremely reliable and
            may look down upon others who they think aren’t pulling their weight. They have an almost
            masochistic desire to sacrifice the present for the long term.<br/><br/>
            Extremely industrious people are at risk of working themselves to the point of severe burnout.<br/><br/>
            `,
            description20Percent: `
            You are exceptionally low in industriousness, one of the two aspects of conscientiousness in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are not at all concerned with delivering well done work on time. You
            rarely feel the desire to be doing something useful, and productivity and efficiency are irrelevant
            to you. You love your free time outside of work and will do whatever is necessary to extend it as
            long as possible.<br/><br/>
            You rarely pressure yourself to perform well, and when you don’t, you don’t feel bad about it.
            The opportunities that are interesting to you are typically “arbitrage” style projects to make
            money “while you sleep”.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your lack of anxiety about delivering perfect results</p>
            <p class="list">Being extremely non-judgmental toward others as well as yourself</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Slack off on work or fail to complete it entirely</p>
            <p class="list">Procrastinate to an absurd degree</p>
            <p class="list">Feel no guilt about neglecting your responsibilities in your work and personal lives</p>
            `,
            description20To40Percent: `
            You are moderately low in industriousness, one of the two aspects of conscientiousness in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are not generally concerned with delivering well done work on time.
            You don’t often feel the desire to be doing something useful, and productivity and efficiency
            don’t matter much to you. You enjoy your free time outside of work and do what you can to
            extend it as long as possible.<br/><br/>
            You don’t often feel pressure to perform well, and when you don’t, any guilt you may feel passes
            quickly.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your low level of anxiety about delivering perfect results</p>
            <p class="list">Being generally non-judgmental toward others as well as yourself</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Be lackadaisical about your responsibilities</p>
            <p class="list">Procrastinate and postpone tasks</p>
            `,
            description40To60Percent: `
            You are typical or average in industriousness, one of the two aspects of conscientiousness in
            the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are about as productive and work-focused as the average person. Your
            work-life balance is generally good—you neither work to live or live to work and enjoy your time
            off as much as you do your working hours.<br/><br/>
            When others around you fail to deliver on their responsibilities, you are as likely as is typical to
            judge them for it. But being judgmental or yourself and the people around you is not a hallmark
            of your personality.<br/><br/>
            You are generally good at completing the tasks you’re responsible for well and on time, but you
            occasionally fail to do one or both.<br/><br/>
            Here are some tendencies you are likely to possess in similar amounts to the average person:<br/><br/>
            <p class="list">Productivity and the drive to get more done in less time</p>
            <p class="list">The likelihood of feeling guilty if you finish work late or poorly</p>
            <p class="list">The desire to feel useful all or most of the time</p>
            <br/>
            The following areas will be a comfortable balance for you as an entrepreneur:<br/><br/>
            <p class="list">Work vs. play</p>
            <p class="list">Efficiency vs. inefficiency</p>
            <p class="list">Guilt vs. apathy about making a mistake</p>
            `,
            description60To80Percent: `
            You are moderately high in industriousness, one of the two aspects of conscientiousness in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are highly focused on work and productivity. You find the majority of your
            joy and fulfillment from work tasks, and strategizing about how you can increase your efficiency
            is a hobby for you. Your work-life balance leans heavily in the direction of work.<br/><br/>
            You rarely, if ever, submit poorly done work or work that’s past its due date. When others
            around you fail to deliver on their responsibilities, you are more likely than the average person to
            consider it a moral failing. You can be a harsh judge, both of yourself and of others, and you are
            likely to feel guilty if you make a mistake.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your steadfastness and reliability in almost every situation</p>
            <p class="list">Your high standards for yourself and others</p>
            <p class="list">Your efficiency and commitment to your work</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Castigate yourself for making mistakes, completing something late, or running behind
            schedule</p>
            <p class="list">Judge others (and yourself)</p>
            <p class="list">Work too much</p>
            `,
            description80To100Percent: `
            You are exceptionally high in industriousness, one of the two aspects of conscientiousness in
            the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are about hyper-focused on work and productivity. You fail to find joy in
            your off time, instead getting all your joy from work tasks and strategizing about how you can
            increase your efficiency. You aren’t familiar with the concept of work-life balance and have no
            desire to be.<br/><br/>
            You would never dream of delivering poorly done work or work that’s past its due date. When
            others around you fail to deliver on their responsibilities, you are likely to consider it a moral
            failing. You judge yourself as harshly as you judge others and are likely to be wracked with guilt
            if you make a mistake.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your steadfastness and reliability</p>
            <p class="list">Your extremely high standards for yourself and others</p>
            <p class="list">Your efficiency and commitment to your work</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Castigate yourself for making mistakes, completing something late, or running behind
            schedule</p>
            <p class="list">Judge others (and yourself) harshly</p>
            <p class="list">Be unable to turn off and take time away from your work</p>
            `
          },
          {
            title: 'Orderliness',
            descriptionGeneral: `
            Orderliness is one of two aspects of conscientiousness, which is the primary index of ability to
            accept delayed gratification in exchange for long-term gains in the Commit Action
            entrepreneurial traits model.<br/><br/>
            Entrepreneurs who score high on orderliness are masters of structure, organization and
            cleanliness. This tendency will manifest at work and at home alike as an powerful motivational
            drive: People who score extremely high on orderliness will be upset, or even appalled, by what
            they perceive to be chaotic or disorganized. Physical and mental clutter can derail them and
            keep them from completing the task at hand until it’s removed or resolved. They tend to have an
            all-or-nothing attitude about most things, which leaves little room for nuance and shades of gray.<br/><br/>
            People who work with them or know them personally report that they like things to be “just so,”
            and that they live and die by their schedules and routines. Orderliness as such can be a
            powerful driver of work results, particularly in stable environments that make it easier to maintain
            structured schedules and routines.<br/><br/>
            Very orderly people are at risk of stifling their natural creativity, since the creative process
            requires leaving room for some unpredictability and disorder.<br/><br/>
            `,
            description20Percent: `
            You are exceptionally low in orderliness, which is one of the two aspects of conscientiousness
            in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are completely unfazed by mental and physical clutter, disorganization,
            and chaotic situations. You’ve found that rigid schedules and detailed planning don’t serve you,
            so you always seek to avoid them and just go with the flow. Distraction from your work is a
            frequent—even constant—problem for you.<br/><br/>
            You have a nuanced view of the world that is reflected in the nonjudgmental way you assess the
            people around you.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your ability to work through and rise above uncertainty, hecticness, and disorganization</p>
            <p class="list">Your willingness to embrace creativity, despite the disorder that the creative process
            requires</p>
            <p class="list">Your open-mindedness toward yourself and others</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Lose track of your responsibilities due to lack of organization</p>
            <p class="list">Be easily distracted</p>
            <p class="list">Frustrate others by being a whirlwind of disorder</p>
            <p class="list">Lack attention to detail</p>
            `,
            description20To40Percent: `
            You are moderately low in orderliness, which is one of the two aspects of conscientiousness in
            the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are rarely fazed by mental and physical clutter, disorganization, and
            chaotic situations. You’ve found that rigid schedules and detailed planning don’t particularly
            serve you, so you generally skip them and just go with the flow. Distraction from your work is a
            frequent problem for you, which can be difficult to overcome.<br/><br/>
            You have a more nuanced than average view of the world that is reflected in the nonjudgmental
            way you assess the people around you.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your ability to work through uncertainty, hecticness, and disorganization</p>
            <p class="list">Your willingness to embrace creativity, despite the disorder that the creative process
            requires</p>
            <p class="list">Your open-mindedness toward yourself and others in most situations</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Sometimes lose track of your responsibilities due to lack of organization</p>
            <p class="list">Be easily distracted in many situations</p>
            <p class="list">Frustrate others with your carefree attitude about disorder</p>
            <p class="list">Lack attention to detail</p>
            `,
            description40To60Percent: `
            You are typical or average in orderliness, which is one of the two aspects of conscientiousness
            in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are about as dedicated to organization and routine as the average
            person. Physical and mental clutter may bother you, though not to the point that it gets in the
            way of your progress.<br/><br/>
            You may occasionally have issues focusing on the task at hand, but you can usually hunker
            down when the situation calls for it.<br/><br/>
            Here are some tendencies you are likely to possess in similar amounts to the average person:<br/><br/>
            <p class="list">The ability to put “chaos” aside to make room for creative breakthroughs</p>
            <p class="list">A thoughtful, nuanced view of the world</p>
            <p class="list">An ability to go with the flow in many situations</p>
            <br/>
            The following areas will be a comfortable balance for you as an entrepreneur:<br/><br/>
            <p class="list">Order vs. disorder</p>
            <p class="list">Organization vs. disorganization</p>
            <p class="list">Routine vs. unpredictability</p>
            `,
            description60To80Percent: `
            You are moderately high in orderliness, which is one of the two aspects of conscientiousness in
            the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are sensitive to tidiness, organization, and order. Mental and physical
            clutter are frustrating to you, and the presence of either can temporarily derail your productivity.
            Your dislike for disruption of your routines makes it difficult for you to make room for new,
            creative ideas.<br/><br/>
            Your view of the world can lack nuance, and you often see things through an all-or-nothing lens.
            People, actions, and decisions are often either right or wrong in your eyes—shades of gray are
            few and far between.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your productivity and ability to stay on task</p>
            <p class="list">Your orientation toward detail in most situations</p>
            <p class="list">Your skill at not letting things fall through the cracks</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Be distracted and upset by chaos and disorder when you come upon it</p>
            <p class="list">Sometimes miss aha moments because of your adherence to a set schedule</p>
            <p class="list">Often get hung up on spontaneous or unexpected events</p>
            `,
            description80To100Percent: `
            You are exceptionally high in orderliness, which is one of the two aspects of conscientiousness
            in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are extremely sensitive to and fixated on tidiness, organization, and
            order. Mental and physical clutter are horrifying to you, and the presence of either can derail
            your productivity. Your complete intolerance for disruption of your routines makes it nearly
            impossible for you to make room for new, creative ideas.<br/><br/>
            Your view of the world lacks nuance, and you tend to see things through an all-or-nothing lens.
            People, actions, and decisions are either right or wrong in your eyes.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your consistent productivity and ability to stay on task</p>
            <p class="list">Your orientation toward detail</p>
            <p class="list">Your never letting anything fall through the cracks</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Be incapacitated by chaos and disorder when you come upon it</p>
            <p class="list">Miss aha moments because of your strict adherence to a set schedule</p>
            <p class="list">Get hung up on anything spontaneous or unexpected</p>
            `
          }
        ]
      },
      {
        title: 'Extraversion',
        descriptionGeneral: `
        Trait extraversion—sometimes known as “the leadership trait”—is a measure of emotional and
        social deftness. They are outgoing, optimistic, talkative, engaging, and persuasive.<br/><br/>
        Rather than being a fixed characteristic or having to do with how you recharge, like the
        “introversion” and “extroversion” of the Myers-Briggs personality assessment, extraversion in
        this model is an index of social deftness similar to a skill set. People who score highly in trait
        extraversion can toggle enthusiasm and assertiveness when the situation requires it.<br/><br/>
        Extraverted entrepreneurs are gregarious in social situations and can often be found at the
        center of a spellbound group of people, telling a story. They find great joy in facilitating
        relationships and connecting people who can help each other, and often take it upon
        themselves to ensure that other people are enjoying events.<br/><br/>
        Extraversion is made up of the aspects of :<br/><br/>
        <p class="list">Enthusiasm — Happy-go-luckiness, bubbliness, warmth, optimism, and a strong
        orientation toward social engagement</p>
        <p class="list">Assertiveness — Social leadership with a strong inclination to take charge, desire to put
        forth opinions, facilitate experience for others and generally dominate social situations</p>
        <br/>
        In general, people have a bias toward extraversion in others, and people who score highly in
        trait extraversion tend to enjoy the benefits and privileges of network. A bubbly, warm person
        who creates a strong sense of connection and rapport right off the bat will often fare better in an
        interview, for example, than someone who is less enthusiastic.<br/><br/>
        It is worth noting that some entrepreneurs with very low extraversion find creative ways to
        circumvent social interactions normally required to win in business.
        `,
        description20Percent: `
        You are exceptionally low in extraversion, which is the index of social and emotional deftness in
        the Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you are most likely to be described as very quiet, introverted, or shy. You find
        it very difficult to warm up to others and tend to work more effectively on your own than you do
        in a larger group. In group settings, you may decline to share what you’re thinking, even if
        asked.<br/><br/>
        You don’t warm up quickly to others and tend to keep information to yourself. Your ideal team
        environment is a small group of known and trusted colleagues. You thrive when you have the
        opportunity to quietly focus, as you aren’t distracted by others.<br/><br/>
        You are more likely than the average person to experience frequent bouts of depression and
        anxiety, and you may have a more negative self-image than is typical particularly if you are also
        low in trait emotional stability.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">You are inscrutable as you often don’t speak up and people cannot guess what you are
        thinking</p>
        <p class="list">You can work easily in solitude, chipping away at important tasks without needing to take
        breaks to socialize</p>
        <p class="list">Social stimuli from others rarely impacts your focus</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:
        <br/><br/>
        <p class="list">Be low-energy</p>
        <p class="list">Be perceived as cold in your interactions with others</p>
        <p class="list">Lack enthusiasm about most things</p>
        <p class="list">Feel exhausted by interacting with people for any length of time</p>
        <p class="list">Be unable to fake enthusiasm about other people’s interests, stories, and bids for
        connection</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your low extraversion puts you at risk of shutting down
        in social situations at work and in your personal life. Your relationships could suffer, which could
        in turn negatively impact your business.<br/><br/>
        When you are at your best, you leverage your lack of spontaneity and love of solitude to
        carefully plan your strategic moves and work maniacally away at achieving them, taking people
        by surprise when you launch.
        `,
        description20To40Percent: `
        You are moderately low in extraversion, which is the index of social and emotional deftness in
        the Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you are likely to be described as reserved, introverted, or shy. You’re slow to
        warm up to others and tend to work more effectively on your own than you do in a larger group.
        In group settings, you may decline to share what you’re thinking unless directly asked.<br/><br/>
        You are more likely than the average person to experience frequent bouts of depression and
        anxiety, and you may have a more negative self-image than is typical.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">You can work more easily in solitude than the average person, chipping away at
        important tasks without needing to take breaks to socialize</p>
        <p class="list">You can be inscrutable as you often don’t speak up and people cannot guess what you
        are thinking</p>
        <p class="list">Social stimuli from others is less likely than average to impact your focus</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:
        <br/><br/>
        <p class="list">Lack energy, especially in social situations</p>
        <p class="list">Lack warmth in your interactions with others</p>
        <p class="list">Fail to be excited by things that don’t inherently interest you</p>
        <p class="list">Feel tired after interacting with people</p>
        <p class="list">Have difficulty faking enthusiasm about other people’s interests, stories, and bids for
        connection</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your low extraversion puts you at risk of appearing cold
        or withdrawn in social situations at work and in your personal life. Your relationships could
        suffer, which could in turn negatively impact your business.<br/><br/>
        When you are at your best, you leverage your affinity for solitude to carefully plan your strategic
        moves and work away at achieving them, taking people by surprise when you launch.
        `,
        description40To60Percent: `
        You are typical or average in extraversion, which is the index of social and emotional deftness in
        the Commit Action entrepreneurial traits model.<br/><br/>
        Colleagues and acquaintances would be unlikely to characterize you as either particularly chatty
        or very reserved. You may not be the first to raise your hand to volunteer your opinion or
        suggestion, but you probably wouldn’t be the last, either.<br/><br/>
        You generally feel equally comfortable working in groups as you do on your own, and
        moderately enjoy both socializing and spending time independently. You’re more likely to be a
        party attendee than a party planner.<br/><br/>
        Here are some tendencies you are likely to possess in similar amounts to the average person:<br/><br/>
        <p class="list">Communicating your feelings both in professional and personal situations</p>
        <p class="list">The ability and inclination to make people laugh</p>
        <p class="list">Ability to take the lead</p>
        <br/>
        The following areas will be a comfortable balance for you as an entrepreneur:<br/><br/>
        <p class="list">Being surrounded by people vs. isolation</p>
        <p class="list">Speaking up in groups vs. letting others dominate the conversation</p>
        <p class="list">Sharing information about yourself vs. keeping things to yourself</p>
        <br/>
        As an even-keeled person comfortable with socialization and solitude, you may notice from time
        to time that you lack rapport with both wildly extraverted individuals and their opposites, the
        quietest solo operators. At your best, you can stretch yourself behaviorally to connect with both.
        `,
        description60To80Percent: `
        You are moderately high in extraversion, which is the index of social and emotional deftness in
        the Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you are more likely than the average person to be described as enthusiastic,
        a social butterfly, upbeat, and extremely optimistic. Your teams that work for you report that you
        are easy to get along with, fun, chatty, and an open book.<br/><br/>
        You usually dislike working in isolation, preferring instead to be on a team working face-to-face
        with colleagues and clients. In both personal and professional social gatherings, you generally
        like to run the show.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">You’re moderately persuasive, engaging, and can get others to open up</p>
        <p class="list">You’re good at networking and connecting others</p>
        <p class="list">You enjoy public speaking, even in front of large groups</p>
        <p class="list">You’re rarely shy about sharing your ideas and opinions with others</p>
        <p class="list">You often take the lead on solving a problem</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:
        <br/><br/>
        <p class="list">Be overly optimistic about the future and/or look at the past through rose-colored glasses</p>
        <p class="list">Waste time socializing</p>
        <p class="list">Have difficulty being discreet</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your extraversion can lead you to be distracted from
        work by socializing, and more inclined to have fun in the present than prepare for the future.
        <br/><br/>
        When you are at your best, you take the lead, build rapport with your team and your clients
        more easily than most, and are so pleasant to work with that plenty of people want to work with
        you!
        `,
        description80To100Percent: `
        You are exceptionally high in extraversion, which is the index of social and emotional deftness
        in the Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you are most likely to be described as enthusiastic, a social butterfly, upbeat,
        and extremely optimistic. Your teams that work for you routinely report that you are charismatic,
        easy to get along with, fun, chatty, and an open book.<br/><br/>
        You deeply dislike working in isolation, preferring instead to be on a team working face-to-face
        with colleagues and clients. In both personal and professional social gatherings, you like to run
        the show. You enjoy being “host”.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">You’re persuasive, engaging, and can get others to open up</p>
        <p class="list">You’re great at networking and connecting others</p>
        <p class="list">You excel at public speaking, even in front of very large groups</p>
        <p class="list">You’re not shy about sharing your ideas and opinions with others</p>
        <p class="list">You have no issue taking the lead on solving a problem</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:
        <br/><br/>
        <p class="list">Be overly optimistic about the future and/or look at the past through rose-colored glasses</p>
        <p class="list">Waste time socializing</p>
        <p class="list">Have difficulty being discreet</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your high extraversion can lead you to be easily
        distracted from work by socializing, and too focused on fun in the present to prepare for the
        future.<br/><br/>
        When you are at your best, you take the lead, easily build rapport with your team and your
        clients, and are such a joy to work with that plenty of people want to work with you!
        `,
        inner: [
          {
            title: 'Enthusiasm',
            descriptionGeneral: `
            Enthusiasm is one of the two aspects of extraversion, which is the index of social and emotional
            deftness in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs who score high on enthusiasm are easily excited, cheerful, and chatty. They
            don’t have much in terms of boundaries with other people—they crave connection, happily
            share personal information, and constantly want to be surrounded by others.<br/><br/>
            People who work with them or know them personally report that they are fun, optimistic, and
            love to make people laugh. They’re described as bubble, gregarious and energetic.<br/><br/>
            Very enthusiastic people are at risk of letting their love of socializing and parties take
            precedence over more important things.<br/><br/>
            `,
            description20Percent: `
            You are exceptionally low in enthusiasm, which is one of the two aspects of extraversion in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you rarely get excited or visibly enthusiastic about their interests or other
            people. In social situations, you can be a brick wall—difficult to penetrate in order to get to know
            you. You would much rather be alone than around others.<br/><br/>
            You find optimism tiresome and are much more likely to have a realistic or pessimistic view of
            the world.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">You’re not distracted by the prospect of socializing with others</p>
            <p class="list">You don’t look at the world through rose-colored glasses</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Struggle to find any enjoyment in being around others</p>
            <p class="list">Lack excitement about things other than your narrow set of interests</p>
            `,
            description20To40Percent: `
            You are moderately low in enthusiasm, which is one of the two aspects of extraversion in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you get less excited about things than the average person. In social
            situations, you can be tricky to get to know—and you’re unlikely to ever be the one making the
            effort. Though you can enjoy socializing occasionally, you would rather be alone than around
            others.<br/><br/>
            Your worldview leans more toward realism/pessimism than optimism.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">You’re not as distracted by the prospect of socializing with others as is typical</p>
            <p class="list">You usually don’t look at the world through rose-colored glasses</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Struggle to seek out social opportunities where you could build the relationships
            necessary for a business to succeed</p>
            <p class="list">Have to fake excitement about things other than your narrow set of interests</p>
            `,
            description40To60Percent: `
            You are typical or average in enthusiasm, which is one of the two aspects of extraversion in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are as happy, fun-loving, and social as the average person. Though you
            don’t have too much difficulty warming up to people, you know when to keep things to yourself
            and don’t overshare.<br/><br/>
            You have a balanced worldview and aren’t particularly optimistic or pessimistic.<br/><br/>
            Here are some tendencies you are likely to possess in similar amounts to the average person:<br/><br/>
            <p class="list">Loving people and social gatherings</p>
            <p class="list">Making people laugh, and laughing often yourself</p>
            <p class="list">Getting to know people with ease</p>
            <br/>
            The following areas will be a comfortable balance for you as an entrepreneur:<br/><br/>
            <p class="list">Being surrounded by people vs. taking time to yourself</p>
            <p class="list">Being the life of the party vs. being a quiet listener</p>
            <p class="list">Positivity vs. negativity</p>
            `,
            description60To80Percent: `
            You are moderately high in enthusiasm, which is one of the two aspects of extraversion in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you love interacting with other people and prefer to be surrounded by others
            as much as possible. You can usually build excitement about anything and often find yourself
            naturally spreading that energy to people around you.<br/><br/>
            You have a positive and optimistic outlook on the world that can generally overcome any
            pessimism that you may feel.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">You love people and build relationships easily</p>
            <p class="list">You’re interested in a lot of things and can build excitement about something new</p>
            <p class="list">You tend to assume that things will turn out in a positive way</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Waste time socializing rather than on more productive activities</p>
            <p class="list">Be slightly too optimistic about the future</p>
            <p class="list">Not be taken seriously by those around you due to your bubbliness</p>
            `,
            description80To100Percent: `
            You are exceptionally high in enthusiasm, which is one of the two aspects of extraversion in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you feed off interactions with other people and love to be surrounded by
            others as much as possible. You seem to be exuberantly excited about any and everything and
            always effortlessly spread that energy to everyone in your orbit.<br/><br/>
            You have an overly positive and optimistic outlook that can sometimes border on delusional.
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">You love people and build relationships quickly and easily</p>
            <p class="list">You’re passionate about a lot of things and can easily build excitement about something
            new</p>
            <p class="list">You visualize positive results for your endeavors, which can help bring them to life</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Waste time socializing rather than on more productive activities</p>
            <p class="list">Be too optimistic about the future</p>
            <p class="list">Not be taken seriously by those around you due to your extreme bubbliness</p>
            `
          },
          {
            title: 'Assertiveness',
            descriptionGeneral: `
            Assertiveness is one of the two aspects of extraversion, which is the index of social and
            emotional deftness in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs who score high on assertiveness have no problem stepping into a leadership role
            in any situation. They share their opinions frequently and without reservations. They can be
            influential, charismatic and captivating. They don’t wait for others to lead the way.<br/><br/>
            People who work with them or know them personally report that they are “take charge” types
            who naturally dominate conversations and rooms. They gravitate toward leadership positions
            and intuitively delegate or assign tasks and roles to others.<br/><br/>
            Very assertive people are strong and effective leaders in situations where they are
            knowledgeable and competent, but their tendency to default into leadership behaviors can
            backfire in the (often many) situations where they less suited to lead.<br/><br/>
            `,
            description20Percent: `
            You are exceptionally low in assertiveness, one of the aspects of extraversion in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you have no interest in taking on a leadership role at work or in social
            situations. You prefer to blend into the background in social situations rather than trying to
            influence the group. It can be like pulling your teeth to get you to say what you’re thinking.<br/><br/>
            You can often get stuck in the thinking stage and have difficulty getting to the action stage.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">You never act without thinking it through first (though this sometimes leads to your never acting at all)</p>
            <p class="list">You don’t steamroll the people around you and always listen to the thoughts and
            opinions of others</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Let someone else take the lead almost all the time</p>
            <p class="list">Struggle to share your thoughts and opinions, even if you’re the most knowledgeable
            person in the situation</p>
            <p class="list">Lack the charisma to get people to listen to and act on the things you have to say</p>
            `,
            description20To40Percent: `
            You are moderately low in assertiveness, one of the aspects of extraversion in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you have little interest in taking on a leadership role at work or in social
            situations. You often prefer to blend into the background rather than trying to influence the
            group. It can take some cajoling to get you to say what you’re thinking.<br/><br/>
            You sometimes get stuck in the thinking stage and have difficulty getting to the action stage.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">You rarely act without thinking it through first</p>
            <p class="list">You’re very unlikely to steamroll the people around you and always listen to the thoughts
            and opinions of others</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Let someone else take the lead far too often</p>
            <p class="list">Sometimes struggle to share your thoughts and opinions, even if you’re the most
            knowledgeable person there</p>
            <p class="list">Lack the charisma to get people to listen to you consistently</p>
            `,
            description40To60Percent: `
            You are typical or average in assertiveness, one of the aspects of extraversion in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are about as likely to take the lead as they are to let someone else do it.
            You don’t generally hesitate to share your opinions, but you tend to think carefully before doing
            so. Your impulse control is about as strong as the average person’s.<br/><br/>
            You generally don’t feel the need to be in charge of any situation, but will pick up the mantle if
            necessary.<br/><br/>
            Here are some tendencies you are likely to possess in similar amounts to the average person:<br/><br/>
            <p class="list">The ability to stop and think before you act</p>
            <p class="list">The tendency to influence the people around you</p>
            <br/>
            The following areas will be a comfortable balance for you as an entrepreneur:<br/><br/>
            <p class="list">Dominance vs. submission in group settings where leadership opportunities arise</p>
            <p class="list">Standing up for yourself vs. backing down</p>
            <p class="list">Sharing your thoughts vs. being a silent contributor</p>
            `,
            description60To80Percent: `
            You are moderately high in assertiveness, one of the aspects of extraversion in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are more likely to take the lead in a given situation than the average
            person. You feel compelled to share your opinions and typically have the charisma necessary to
            convince people to follow along.<br/><br/>
            You like to feel like you’re in control, and are most comfortable leading the pack. You can end
            up frustrated or upset when things fall outside your control.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your willingness to lead even in difficult situations</p>
            <p class="list">Your ability to make decisions quickly in challenging times</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Want to be in charge every time</p>
            <p class="list">Occasionally act impulsively without thinking things through</p>
            `,
            description80To100Percent: `
            You are exceptionally high in assertiveness, one of the aspects of extraversion in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are always the first to take the lead, whether the rest of the group wants
            you to or not. You’re a person of action. You feel compelled to share your opinions early and
            always have the charisma necessary to convince people to follow along.<br/><br/>
            You feel a deep need to control every situation that you’re in, and you can end up frustrated or
            upset when things fall outside your control.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your confidence that you’re the best person for the job</p>
            <p class="list">Your ability to make decisions quickly in challenging situations</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Steamroll others so you can be the boss</p>
            <p class="list">Sometimes act impulsively without thinking things through</p>
            `
          }
        ]
      },
      {
        title: 'Neuroticism',
        descriptionGeneral: `
        Trait neuroticism is an index of your mental health and emotional volatility. It measures an
        entrepreneur’s sensitivity to (and experience of) negative emotions—though we all experience
        negative emotions, those who score high in neuroticism have difficulty controlling their reactions
        to these feelings.<br/><br/>
        Trait neuroticism can be thought of as an inverse index of emotional stability, where low
        neuroticism scores equate to high levels of emotional stability.<br/><br/>
        Neurotic, low stability entrepreneurs often have low impulse control, experience frequent
        anxiety, and tend to overreact. They may also have issues with self-esteem, difficulty bouncing
        back after setbacks, or the tendency to focus on the negative in every situation.<br/><br/>
        Entrepreneurs who score low on neuroticism possess effective inner capabilities for dealing with
        adversity. They can self soothe, regulate negative emotions and generally feel calm in times of
        pressure.<br/><br/>
        Trait neuroticism is made up of the aspects of:<br/><br/>
        <p class="list">Withdrawal — An index of worry, anxiety, and doubt about the future, the unknown, and
        the unexpected. Also measures how easily someone bounces back from a stumbling
        block or failure.</p>
        <p class="list">Volatility — Measures the steadiness of someone’s mood and emotions.</p>
        `,
        description20Percent: `
        You are exceptionally low in neuroticism, which is the primary index of mental health and
        emotional volatility in the Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you are not sensitive to difficult emotions—you understand that they are
        inevitable and that they will pass. You rarely experience anxiety, anguish, stress, and low self
        esteem, and when you do, you handle them with level-headedness and equanimity.<br/><br/>
        People on your team think of you as even-keeled and steadfast, even in the stormiest of times.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">You recover quickly from the setbacks that are inevitable when building a company</p>
        <p class="list">Risk doesn’t faze you, and you can easily change strategies, trajectories, or other plans
        without breaking a sweat</p>
        <p class="list">You don’t build negative things up into something bigger than they actually are</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your low neuroticism will give you much better odds
        than most to bounce back, not wallow and generally flatten out the curves of any emotional
        roller coasters.<br/><br/>
        When you are at your best, you have a tremendous appetite for risk and a superpower to
        confront volatility, uncertainty and complexity that turns most people’s hair grey. You thrive in
        chaos and can always build something out of it.
        `,
        description20To40Percent: `
        You are moderately low in neuroticism, which is the primary index of mental health and
        emotional volatility in the Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you are not particularly sensitive to difficult emotions—you understand that
        they are inevitable and that they will pass. You experience anxiety, anguish, stress, and low self
        esteem on a relatively infrequent basis, and when you do, you handle them with
        level-headedness and equanimity.<br/><br/>
        People on your team think of you as almost always even-keeled and steadfast.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">You generally can recover from the setbacks that are inevitable when building a
        company</p>
        <p class="list">You handle risk well, and you can easily change strategies, trajectories, or other plans
        without breaking a sweat</p>
        <p class="list">Negative experiences don’t cause you to spiral</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your lower neuroticism will give you good odds on
        bouncing back and not wallowing. Most of the time you flatten out the curves of any emotional
        roller coasters.<br/><br/>
        When you are at your best, you have an appetite for risk and the ability to confront volatility,
        uncertainty and complexity that turns most people’s hair grey. You can usually thrive in chaos
        and build something out of it, when you need to.
        `,
        description40To60Percent: `
        You are typical or average in neuroticism, which is the primary index of mental health and
        emotional volatility in the Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you tend to experience negative motions about as frequently and intensely
        as the average person. You’re not particularly prone to episodes of depression, anxiety, and
        unhappiness, though you may experience them from time to time. When you do, you recover
        about as quickly as the average person. Worries about the past, present, and future may come
        up sometimes, but you can dismiss them with relative ease.<br/><br/>
        You often consider the negative aspects of a situation, but you’re likely to be able to balance
        those thoughts with the positive aspects, too.<br/><br/>
        Here are some tendencies you are likely to possess in similar amounts to the average person:<br/><br/>
        <p class="list">The ability to move past difficult setbacks without emotional turmoil</p>
        <p class="list">Worrying about your personal and professional life</p>
        <p class="list">Coping with negative emotions</p>
        <br/>
        The following areas will be a comfortable balance for you as an entrepreneur:<br/><br/>
        <p class="list">Risk-taking vs. risk-aversion</p>
        <p class="list">Negativity vs. optimism</p>
        <p class="list">Negative emotions vs. positive emotions</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your average neuroticism may drive you to worry about
        the effect the rut you’re in will have on your business.<br/><br/>
        When you are at your best, you’re able to channel your emotions into action signals, driving
        changes in your life and business to protect you in the present and prepare for the future.
        `,
        description60To80Percent: `
        You are moderately high in neuroticism, which is the primary index of mental health and
        emotional volatility in the Commit Action entrepreneurial traits model.<br/><br/>
        You experience negative emotions more frequently and intensely than the average person. You
        are prone to occasional episodes of depression, anxiety, and unhappiness, from which it is
        sometimes difficult for you to recover. Worries about the past, present, and future can pop up
        often for you.<br/><br/>
        You have a tendency to focus on the negative aspects of many situations. When good things
        occur, you can sometimes be skeptical of them—always looking for a catch and wondering
        whether it’s real, will last or if you deserve it.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">You’re conscious of potential negative outcomes and can plan accordingly</p>
        <p class="list">Your worries about the future can help you prepare for potential issues</p>
        <p class="list">You react strongly to emotional triggers and wear your heart on your sleeve</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
        <p class="list">Be burdened with worry and negativity often</p>
        <p class="list">Dwell on negative emotions, unable to bounce back quickly</p>
        <p class="list">Be vulnerable to bad actors who want to manipulate or take advantage of you</p>
        <p class="list">Give in to your impulses</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your neuroticism may leave you feeling paralyzed and
        unable to make decisions about your life and business.
        `,
        description80To100Percent: `
        You are exceptionally high in neuroticism, which is the primary index of mental health and
        emotional volatility in the Commit Action entrepreneurial traits model.<br/><br/>
        You experience negative emotions frequently and intensely. You are prone to episodes of
        depression, anxiety, and spiraling in unhappiness, from which it can be difficult for you to
        recover. Worries about the past, present, and future can feel all-consuming at times.<br/><br/>
        You have a strong tendency to focus on the negative aspects of any situation. When good
        things occur, you are immediately skeptical of them—always looking for a catch and wondering
        whether it’s true, it’ll last or if you deserve it.<br/><br/>
        Negative emotions frequently derail you, impacting your productivity, health, and relationships.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">You’re extremely conscious of potential negative outcomes and can plan accordingly</p>
        <p class="list">Your worries about the future can make you better at planning for potential issues</p>
        <p class="list">You lash out in reaction to emotional triggers and wear your heart on your sleeve</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
        <p class="list">Be burdened with worry and negativity often</p>
        <p class="list">Dwell on negative emotions, unable to bounce back quickly</p>
        <p class="list">Be vulnerable to bad actors who want to manipulate or take advantage of you</p>
        <p class="list">Give in to your impulses</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your high neuroticism can leave you feeling crushed by
        a spiral of negative feelings, which can impact your ability to do your job.
        `,
        inner: [
          {
            title: 'Withdrawal',
            descriptionGeneral: `
            Withdrawal is one of the two aspects of neuroticism, which is the primary index of mental health
            and emotional volatility in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs who score high on withdrawal experience high levels of anxiety about things that
            may happen in the future. Being forced into unfamiliar situations can paralyze them. They are
            no stranger to a range of negative emotions: sadness, self-consciousness, and loneliness are
            all common experiences for them. These emotions can be very difficult to bounce back from.<br/><br/>
            People who work with them or know them personally report that they’re easily offended and feel
            pain acutely without much provocation.<br/><br/>
            Very withdrawn people are at risk of analysis paralysis: Doing nothing because they are too
            fearful of something bad happening as a result of their actions.<br/><br/>
            `,
            description20Percent: `
            You are exceptionally low in withdrawal, one of the aspects of neuroticism in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you rarely experience anxiety about what’s going to happen—even if you’re
            dealing with new experiences, difficult circumstances, or extreme uncertainty. You rarely feel
            negative emotions, and when you do, you let them pass instead of dwelling on them.<br/><br/>
            You worry much less than the average person, which makes it much easier for you to tackle
            tough situations head on. You’re extremely unlikely to run away from a challenge or roadblock,
            preferring to confront it directly. You’re supremely confident of your ability to face any unknown
            situation in real time.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">You rarely or never feel defeated, even when times are tough</p>
            <p class="list">Uncertainty in the future doesn’t phase you, you thrive by expecting the unexpected</p>
            <p class="list">You don’t experience much anxiety, and when you do, it passes quickly</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Struggle to build rapport with anxious people who do worry about the future</p>
            <p class="list">Sometimes fail to be mentally prepared for improbable future crises</p>
            `,
            description20To40Percent: `
            You are moderately low in withdrawal, one of the aspects of neuroticism in the Commit Action
            entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you don’t often experience anxiety about what’s going to happen—even if
            you’re dealing with new experiences or uncertainty. You feel negative emotions less often than
            the average person, and when you do, you’re able to let them pass relatively quickly.<br/><br/>
            You worry less than the average person, which makes it easier for you to tackle tough situations
            head on. You’re unlikely to run away from a challenge or roadblock, preferring to confront it
            directly. You’re quite confident in your ability to face unknowns in the future.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">You don’t often feel defeated, even when times are tough</p>
            <p class="list">It’s more difficult to hurt your feelings or upset you than it is with the average person</p>
            <p class="list">You don’t experience too much anxiety, and when you do, it generally passes quickly</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Sometimes struggle to build rapport with anxious people who do worry about the future</p>
            <p class="list">Occasionally fail be mentally prepared for improbable future crises</p>
            `,
            description40To60Percent: `
            You are typical or average in withdrawal, one of the aspects of neuroticism in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are about as prone to anxiety as the average person. While that anxiety
            tends to manifest when you’re approaching an unfamiliar, complicated, or surprise situation, you
            can generally overcome it and do what you have to do to move forward. You experience
            negative emotions, but only as much as is typical.<br/><br/>
            You may sometimes worry about things going wrong in the future, but you don’t let it turn into
            dread.<br/><br/>
            Here are some tendencies you are likely to possess in similar amounts to the average person:<br/><br/>
            <p class="list">Likelihood of experiencing negative emotions</p>
            <p class="list">Self-consciousness and getting your feelings hurt</p>
            <p class="list">The ability to recover from negative feelings quickly</p>
            <br/>
            The following areas will be a comfortable balance for you as an entrepreneur:<br/><br/>
            <p class="list">Anxiety about the future vs. willingness to take on challenges</p>
            <p class="list">Feeling embarrassed easily vs. not caring too much about what other people think</p>
            <p class="list">Fear of uncertainty and new experiences vs. a desire to do what you have to do to
            succeed</p>
            `,
            description60To80Percent: `
            You are moderately high in withdrawal, one of the aspects of neuroticism in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you experience frequent, often intense, anxiety—often about things that are
            unlikely to occur. Your fears surrounding the future make you inclined to avoid new experiences,
            unfamiliar circumstances, and other high-risk situations when you can. Social anxiety is also a
            recurring problem for you—you embarrass more easily than the average person and can have
            issues with your self-esteem.<br/><br/>
            You sometimes feel worried about the future and that can make it hard for you to move forward
            and do what you need to do.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Often thinking ahead, anticipating and strategizing around potential disastrous obstacles
            (this is helpful only when married with the diligent execution to act on your forecasting)</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Have difficulty bouncing back from difficult situations and negative emotions</p>
            <p class="list">Dislike trying new things and confronting situations that scare you</p>
            <p class="list">Worrying about future scenarios that may never unfold</p>
            `,
            description80To100Percent: `
            You are exceptionally high in withdrawal, one of the aspects of neuroticism in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are frequently and intensely wracked with anxiety—often about things
            that are unlikely to actually happen. Your fears surrounding the future make you inclined to run
            away from new experiences, unfamiliar circumstances, and other high-risk situations. Social
            anxiety is also a recurring problem for you—you embarrass easily and can have issues with
            your self-esteem.<br/><br/>
            You often feel dread about the future that can make it hard for you to move forward and do what
            you need to do. You’re often paralyzed by imaginative but disastrous “what if” scenarios you
            construct in your mind.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Always thinking ahead, anticipating and strategizing around potential disastrous
            situations you might face (this is helpful only when married with the diligent execution to
            act on your forecasting)</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Find it almost impossible to bounce back from difficult situations and negative emotions</p>
            <p class="list">Hate trying new things and confronting situations that scare you or expose you to
            unknowns.</p>
            `
          },
          {
            title: 'Volatility',
            descriptionGeneral: `
            Volatility is one of the two aspects of neuroticism, which is the primary index of mental health
            and emotional volatility in the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs who score high on volatility are subject to intense highs and lows in mood. They
            experience a depth of negative emotion that the average person likely cannot relate to. Once
            they get going, it can be difficult for them to calm down.<br/><br/>
            People who work with them or know them personally report that they have a short fuse that
            doesn’t take much to set off. They have frequent outbursts that take a long time to recover from
            and have trouble coping with the emotions we all experience. Their volatile can manifest across
            the full range of negative emotions.<br/><br/>
            Very volatile people are at risk of alienating the people around them by over reacting when
            they’re stressed, frustrated, or otherwise under pressure.<br/><br/>
            `,
            description20Percent: `
            You are exceptionally low in volatility, one of the aspects of neuroticism in the Commit Action
            entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are incredibly even-keeled and much more emotionally stable than the
            average person. The strength of your calm nature is such that it often rubs off on the people
            around you. When you do express negative emotions, you do it in a measured, productive way,
            and the feelings always pass quickly.<br/><br/>
            You are incredibly steadfast and composed, even in the stormiest of times when you’re under a
            great deal of stress.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">An ability to weather extreme duress without losing your cool</p>
            <p class="list">The calming effect you have on the people around you</p>
            <p class="list">Your near-immunity to negative emotions</p>
            <p class="list">Your general unflappability</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Often be so emotionally stable that you under-react in situations that an appropriate and
            warranted reaction of negative sentiment would be useful.</p>
            `,
            description20To40Percent: `
            You are moderately low in volatility, one of the aspects of neuroticism in the Commit Action
            entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are generally even-keeled and more emotionally stable than the average
            person. You rarely experience unpredictable mood swings, and other people find you incredibly
            easy to be around. When you do express negative emotions, you do it in a measured,
            productive way, and the feelings always pass quickly.<br/><br/>
            You are steadfast and composed even in stormy times, and if you do lose your cool, you regain
            it almost as quickly.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">An ability to weather difficult times without lashing out</p>
            <p class="list">Your infrequent experience of negative emotions</p>
            <p class="list">Your general unflappability in most circumstances</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Rarely argue with others, even when it might benefit you to do so</p>
            <p class="list">Sometimes be so emotionally stable that you under-react in situations that an
            appropriate and warranted reaction of negative sentiment would be useful</p>
            `,
            description40To60Percent: `
            You are typical or average in volatility, one of the aspects of neuroticism in the Commit Action
            entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you tend not to have tall peaks and deep valleys in their moods. You
            experience anger, hurt and other negative emotions about as much as the average person, and
            you manage them well. When things go wrong, you may get upset, but you only fly off the
            handle at the most extreme of situations.<br/><br/>
            You are generally able to remain composed during most stressful situations and don’t take your
            feelings out on other people.<br/><br/>
            Here are some tendencies you are likely to possess in similar amounts to the average person:<br/><br/>
            <p class="list">Frequency of experiencing annoyance or frustration</p>
            <p class="list">Variations in mood</p>
            <p class="list">Likelihood of lashing out at others in times of stress</p>
            <br/>
            The following areas will be a comfortable balance for you as an entrepreneur:<br/><br/>
            <p class="list">Moodiness vs. steadfastness</p>
            <p class="list">Emotional reactiveness vs. even-keeledness</p>
            <p class="list">Arguing with others vs. letting things go</p>
            `,
            description60To80Percent: `
            You are moderately high in volatility, one of the aspects of neuroticism in the Commit Action
            entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you experience strong moods and often swing between them. You’re more
            prone to negative emotions than the average person, and you can sometimes have trouble
            controlling your emotional responses to events that are frustrating or annoying. Calming down
            after you’ve been struck by these emotions is often difficult.<br/><br/>
            If someone attacks you in some way, your reaction can meet theirs in terms of emotional force.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your openness with your emotions</p>
            <p class="list">Your lack of fear of fighting back and standing up for yourself</p>
            <p class="list">Your mercurial nature can inspire awe and sometimes fear in any subordinates</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Be hot-headed and react emotionally to others</p>
            <p class="list">Have strong negative emotions and have difficulty controlling them</p>
            <p class="list">Your frequent inability to handle stress in a productive way</p>
            `,
            description80To100Percent: `
            You are exceptionally high in volatility, one of the aspects of neuroticism in the Commit Action
            entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you experience intense moods and often swing between them rapidly. You’re
            much more prone to negative emotions than the average person, and you often have trouble
            controlling your emotional responses to events that are triggering. Calming down after you’ve
            been struck by these emotions is exceedingly difficult.<br/><br/>
            If someone attacks you in some way, your reaction will meet or exceed theirs in terms of
            emotional force.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your openness with your emotions</p>
            <p class="list">Your lack of fear of fighting back and standing up for yourself</p>
            <p class="list">Your mercurial nature will inspire terrific fear in your subordinates</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Your hot-headedness and strong emotional reactions</p>
            <p class="list">Your frequency and intensity of negative emotions</p>
            <p class="list">Your inability to handle stress in a productive way</p>
            `
          }
        ]
      },
      {
        title: 'Openness to Experience',
        descriptionGeneral: `
        Trait openness to experience is an interesting index of tendencies that closely align with the
        entrepreneurial spirit. It is a measure of interest in novelty, art, literature, abstract thinking, and
        philosophy, as well as sensitivity to aesthetic emotions and beauty.<br/><br/>
        An orientation toward these aspects of life tends to correlate strongly with abstract problem
        solving, creativity and insight that helps visionary entrepreneurs flourish. Of course,
        entrepreneurship is more complicated than a singular personality trait and many different kinds
        of entrepreneurs exist.<br/><br/>
        It’s worth noting that openness to experience doesn’t predict an entrepreneur’s ability to
        execute their vision. You can think of vision and hustle as distinct traits, and
        openness-to-experience is a measure of the former. At Commit Action, we pay close attention
        to openness to experience and it’s complex interrelationship with other traits.<br/><br/>
        Openness to experience is made up of the aspects of:<br/><br/>
        <p class="list">Intellect — not a measure of IQ, but an index of your orientation toward abstract
        thinking and intellectual curiosity.</p>
        <p class="list">Openness — a (confusingly named) index of the aesthetic, artistic and creative sides
        of this overall trait.</p>
        `,
        description20Percent: `
        You are exceptionally low in openness to experience, which is the primary index of creativity,
        artistic interest, intelligence (particularly verbal intelligence) and intellectual curiosity in the
        Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you are unlikely to be interested in thinking through abstract concepts,
        complex ideas, or philosophical scenarios. Learning about, creating, and otherwise participating
        in arts and cultural activities is not a priority in your life.<br/><br/>
        Your drive to learn and do new things is very low—you’re perfectly happy living in the same
        home, doing the same job, and going to the same places every day. Curiosity, visionary
        thinking, and brainstorming new strategies for how to approach things in your life don’t come
        naturally to you.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">Your tolerance, even preference, for repetitive tasks</p>
        <p class="list">Your ability to work well in partnership with creative visionaries</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
        <p class="list">Prefer to stay in your lane and keep things the same day in and day out, even when
        challenging yourself is necessary to grow</p>
        <p class="list">Rarely or never feel curious or want to dig into complex, abstract concepts</p>
        <p class="list">Have very narrow interests</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your low openness-to-experience may limit your ability
        to come up with creative solutions to move past whatever the roadblock may be.<br/><br/>
        When you are at your best, you can patiently create value through executing on a vision or
        mission you’ve received. If you’re also high in trait conscientiousness you can be a brilliant,
        detail focused operator.
        `,
        description20To40Percent: `
        You are moderately low in openness to experience, which is the primary index of creativity,
        artistic interest, intelligence (particularly verbal intelligence) and intellectual curiosity in the
        Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you are less likely to be described by others as sharp, creative, curious,
        quick-witted and visionary. You’re mildly interested in learning and acquiring new abilities and
        skills. You're less interested than average in abstract thinking and are sometimes curious about
        philosophy and the psychology, beliefs and values of others.<br/><br/>
        Complex, abstract ideas are less interesting to you than the average person. As an
        entrepreneur, those are the kinds of problems you’ll be more frustrated by.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">The ability to perform repetitive tasks without frustration when necessary</p>
        <p class="list">The temperament to put up with bureaucracy when the situation calls for it</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
        <p class="list">Be closed-minded and generally uninterested in stepping outside your comfort zone</p>
        <p class="list">Struggle to communicate you ideas and preferences clearly</p>
        <p class="list">Avoid analysis and creative thinking</p>
        <p class="list">Not feel curious about the world, your sector, or your colleagues</p>
        <p class="list">Vague dislike of learning in general</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your low openness-to-experience may prevent you from
        doing the creative and critical thinking necessary to figure out the problem blocking you and
        move past it.<br/><br/>
        When you are at your best, you can leverage your tolerance for repetition to patiently create
        value through executing on a vision or mission you’ve received. If you’re also high in trait
        conscientiousness you can be an effective detail focused operator.
        `,
        description40To60Percent: `
        You are typical or average in openness to experience, which is the primary index of creativity,
        artistic interest, intelligence (particularly verbal intelligence) and intellectual curiosity in the
        Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you are as sharp, creative, curious, quick-witted and visionary as others.
        You’re sometimes interested in learning and somewhat motivated to acquire new abilities and
        skills. You are not strictly interested in abstract thinking and are only moderately curious about
        philosophy and the psychology, beliefs and values of others.<br/><br/>
        Complex, abstract ideas are about as interesting to you as the average person. As an
        entrepreneur, you can be indifferent about the kinds of problems you seek to find solutions to.<br/><br/>
        Here are some tendencies you are likely to possess in similar amounts to the average person:<br/><br/>
        <p class="list">Interest in reading</p>
        <p class="list">Preference for novelty and newness</p>
        <p class="list">Overall range of interests</p>
        <p class="list">Depth of vocabulary</p>
        <p class="list">Frequency and interest in thinking of new, creative ideas</p>
        <p class="list">Communication skills</p>
        <p class="list">Perceiving old things in new ways (creative, synergistic thinking)</p>
        <br/>
        The following areas will be a comfortable balance for you as an entrepreneur:
        <br/><br/>
        <p class="list">Executing predictable situations or workflows that require “rinse-and-repeat”
        sameness</p>
        <p class="list">Playing well with others who sit above you in perceived (or real) hierarchies
        (managers, bosses and even investors!)</p>
        <p class="list">Working with others in an environment where you cannot—or it isn’t appropriate
        to—shake things up with new ideas.</p>
        <p class="list">Being singularly focused on one career path, developing deep focus and expertise.</p>
        <p class="list">Avoiding distraction from too many bright shiny objects.</p>
        <br/>
        You might be aware that a few people are more creative and see interesting ideas and
        connections before you do. However, when you are at your best, you have the ability to learn
        quickly, be creative and sometimes spot problems and opportunities that lead to interesting
        business possibilities!
        `,
        description60To80Percent: `
        You are moderately high in openness to experience, which is the primary index of creativity,
        artistic interest, intelligence (particularly verbal intelligence) and intellectual curiosity in the
        Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you are more likely to be described by others as sharp, creative, curious,
        quick-witted and visionary. You’re likely a lifelong learner, and motivated to acquire new abilities
        and skills. You're interested in abstract thinking and are often curious about philosophy and the
        psychology, beliefs and values of others.<br/><br/>
        Complex, abstract ideas are more interesting to you than the average person. As an
        entrepreneur, those are the kinds of problems you’ll tend to confront and seek solutions to.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">Deeper interest in reading</p>
        <p class="list">Preference for novelty and newness</p>
        <p class="list">Overall wider range of interests</p>
        <p class="list">Broader and deeper vocabulary</p>
        <p class="list">Skilled at dreaming up new, creative ideas</p>
        <p class="list">Eloquent communication skills</p>
        <p class="list">Ability to perceive old things in new ways (creative, synergistic thinking)</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
        <p class="list">Struggle to execute in predictable situations or workflows that require
        “rinse-and-repeat” sameness</p>
        <p class="list">Not play as well with others who sit above you in perceived (or real) hierarchies
        (managers, bosses and even investors!)</p>
        <p class="list">Work with others in an environment where you cannot—or it isn’t appropriate
        to—shake things up with new ideas.</p>
        <p class="list">Not be singularly focused on one career path — doing many different things can put
        you at risk of being a jack-of-all-trades... and a master of none.</p>
        <p class="list">Be distracted by bright shiny objects (a strong newness preference that either requires tremendous discipline to overcome or can cost you the completion of projects/tasks.)</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself... your openness-to-experience puts you at risk of self
        sabotage through overthinking and overanalyzing. When you’re stuck, it’s often because you’re
        in the clouds or move on to the next new bright shiny object.<br/><br/>
        When you are at your best, you’re a sharp, creative thinker who can spot creative solutions to
        problems that are invisible to almost everyone else. Your lateral and creative thinking has the
        potential to be the seed of enormous value and impact in the world.
        `,
        description80To100Percent: `
        You are exceptionally high in openness-to-experience, which is the primary index of creativity,
        artistic interest, intelligence (particularly verbal intelligence) and intellectual curiosity in the
        Commit Action entrepreneurial traits model.<br/><br/>
        Entrepreneurs like you are highly likely to be described by others as sharp, creative, curious,
        quick-witted and visionary. You’re likely a lifelong learner, and extremely motivated to acquire
        new abilities and skills. You're fascinated by abstract thinking and are eternally curious about
        philosophy and the psychology, beliefs and values of others.<br/><br/>
        Complex, abstract ideas are much more interesting to you than the average person. As an
        entrepreneur, those are the kinds of problems you want to confront and seek solutions to. You
        love mental models and are fascinated by thinking about thinking.<br/><br/>
        Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
        “type” and separate you from the average person:<br/><br/>
        <p class="list">Deep interest in reading</p>
        <p class="list">Strong preference for novelty and newness</p>
        <p class="list">Overall wide range of interests</p>
        <p class="list">Broad, deep vocabulary</p>
        <p class="list">Skilled at dreaming up new, creative ideas</p>
        <p class="list">Eloquent communication skills</p>
        <p class="list">Ability to perceive old things in new ways (creative, synergistic thinking)</p>
        <br/>
        The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
        <p class="list">Struggle to execute in predictable situations or workflows that require
        “rinse-and-repeat” sameness</p>
        <p class="list">Not play well with others who sit above you in perceived (or real) hierarchies
        (managers, bosses, even investors!)</p>
        <p class="list">Work with others in an environment where you cannot—or it isn’t appropriate
        to—shake things up with new ideas</p>
        <p class="list">Not be singularly focused on one career path — doing many different things can put
        you at risk of being a jack-of-all-trades ... and a master of none</p>
        <p class="list">Be distracted by bright shiny objects (a strong newness preference that either requires
          tremendous discipline to overcome or can cost you the completion of projects/tasks)</p>
        <br/>
        When an entrepreneur like you has a “bad day" (or longer period of time) where you do not
        show up as the best version of yourself, your openness-to-experience puts you at risk of
        self-sabotage through excessive analysis and overthinking. You risk throwing so many creative
        ideas around that you accidentally discredit your own foundational principles.<br/><br/>
        When you are at your best, you’re a sharp, creative thinker who thrives on new, unknown
        experiences and can spot the problems and opportunities invisible to everyone else that
        uncover huge business possibilities!
        `,
        inner: [
          {
            title: 'Intellect',
            descriptionGeneral: `
            Intellect is one of the two aspects of openness-to-experience, which is the primary index of
            creativity, artistic interest, intelligence (particularly verbal intelligence) and intellectual curiosity in
            the Commit Action entrepreneurial traits model. It is important to know that this aspect isn’t a
            measure of intelligence or IQ in a traditional sense.<br/><br/>
            Entrepreneurs who score high on intellect are thinky people who love working through complex
            problems and thinking through highly conceptual, abstract ideas. They want to be constantly
            learning new things, regardless of topic or its relevance to their immediate field. They thrive in
            situations where their work is constantly shifting and changing.<br/><br/>
            People who work with them or know them personally report that they communicate challenging
            or confusing topics clearly and easily.<br/><br/>
            Very intellectual people are at risk of becoming bored with their work if it isn’t constantly
            changing, evolving, and developing.<br/><br/>
            `,
            description20Percent: `
            You are exceptionally low in intellect, one of the two aspects of openness-to-experience in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you have no interest in conceptual ideas or complex problems, preferring
            instead to focus on the interests and work that you already understand. Learning new things
            tends to feel like a chore for you, and the idea of being in a situation you’ve never been in
            before is more stressful than exciting. You have a strong preference and affinity for routine and
            sameness.<br/><br/>
            You often have difficulty communicating your thoughts to others, especially verbally.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">You operate well within a set of rules that you know and understand</p>
            <p class="list">The monotonous tasks of daily life tend not to bother you</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Not want to learn new things or put yourself in novel situations where you could grow</p>
            <p class="list">Struggle to communicate complex ideas</p>
            `,
            description20To40Percent: `
            You are moderately low in intellect, one of the two aspects of openness-to-experience in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you have less interest in conceptual ideas or complex problems than the
            average person. Learning new things isn’t particularly enjoyable for you, and the idea of being in
            a situation you’ve never been in before is more stressful than exciting. You enjoy routine and
            sameness.<br/><br/>
            You sometimes have difficulty communicating your thoughts to others, especially verbally.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">You generally operate well within a set of rules that you know and understand</p>
            <p class="list">The monotonous tasks of daily life tend not to bother you much</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Not want to learn new things or put yourself in novel situations where you could grow</p>
            <p class="list">Have difficulty communicating complex ideas</p>
            `,
            description40To60Percent: `
            You are typical or average in intellect, one of the two aspects of openness-to-experience in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are about as interested in conceptual ideas and complex problems as
            the average person. Learning practical knowledge and learning abstract ideas hold about equal
            appeal to you. Your communication skills are adequate, though you may sometimes have
            trouble explaining complex topics well.<br/><br/>
            You’re curious to a point but prefer to spend your time on your known interests. You can thrive
            with routine and sameness but need to mix things up with novel and new experiences every
            now and then.<br/><br/>
            Here are some tendencies you are likely to possess in similar amounts to the average person:<br/><br/>
            <p class="list">Looking for new experiences, hobbies, and ideas</p>
            <p class="list">Talking through complex ideas with others</p>
            <br/>
            The following areas will be a comfortable balance for you as an entrepreneur:<br/><br/>
            <p class="list">Trying new things vs. spending time with more comfortable activities</p>
            <p class="list">Enjoying work that is constantly changing vs. enjoying work that is more straightforward
            and predictable</p>
            `,
            description60To80Percent: `
            You are moderately high in intellect, one of the two aspects of openness-to-experience in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are often hungry to learn new things, think through abstract concepts
            and complex problems, and expose themselves to situations they’ve never been in before. You
            prefer a lifestyle where the challenges your face are changing often, which requires you to think
            through new ideas to solve them.<br/><br/>
            You can communicate complex issues in a clear, understandable way to most audiences. You
            have an orientation towards novelty that shows up in all aspects of your life as you seek new
            inputs, information and experiences.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your curiosity about almost everything</p>
            <p class="list">Your ability to work through unfamiliar problems</p>
            <p class="list">Your adaptive nature</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Have a high threshold for intellectual stimulation that leaves you bored easily</p>
            <p class="list">Dislike the repetitive, tedious work that is almost always part of life</p>
            `,
            description80To100Percent: `
            You are exceptionally high in intellect, one of the two aspects of openness-to-experience in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are constantly hungry to learn new things, think through abstract
            concepts and complex problems, and expose themselves to situations they’ve never been in
            before. You thrive in a lifestyle where the challenges your face are constantly changing,
            requiring you to think through new ideas to solve them.<br/><br/>
            You can communicate even the most complex issues in a clear, understandable way to any
            audience. You have an extreme preference towards novelty that shows up in all aspects of your
            life as you constantly crave new and varied inputs, information and experiences.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your extreme curiosity about anything and everything</p>
            <p class="list">Your ability to work through unfamiliar problems—and enjoy the process</p>
            <p class="list">Your adaptive nature</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Have a really high threshold for intellectual stimulation that leaves you bored easily</p>
            <p class="list">Often move on from tasks, projects or things in pursuit of the newest bright shiny thing</p>
            <p class="list">Hate the repetitive, tedious work that is almost always part of life</p>
            `
          },
          {
            title: 'Openness',
            descriptionGeneral: `
            Openness is one of the two aspects of openness-to-experience, which is the primary index of
            creativity, artistic interest, intelligence (particularly verbal intelligence) and intellectual curiosity in
            the Commit Action entrepreneurial traits model. The closest synonym for the openness aspect
            is “creativity”.<br/><br/>
            Entrepreneurs who score high on openness are extremely creative and need art and other
            aesthetic experiences in their daily lives in order to feel fulfilled and grow. They have a deep
            passion for visual, auditory, and other sensual beauty—these things have a profound emotional
            effect on them.<br/><br/>
            People who work with them or know them personally report that they can be deeply sensitive to
            a world of material experience that others often ignore, and sometimes have their head in the
            clouds. They often bring lateral and creative solutions to otherwise insoluble problems, by
            connecting dots that others cannot.<br/><br/>
            Very open people can struggle to apply their infinite creative energy to a professional pursuit.<br/><br/>
            `,
            description20Percent: `
            You are exceptionally low in openness, one of the two aspects of openness-to-experience in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you have little to no interest in art, music, and other creative mediums.
            Visual, auditory, and other forms of beauty generally don’t register for you. You would never
            characterize yourself as creative, and therefore require no outlets for creative energy.<br/><br/>
            You don’t find beauty important and you’re happy in environments where it’s lacking.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your ability to fit into conventional situations whenever necessary</p>
            <p class="list">Your overall practicality</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Inability to think up creative solutions to problems</p>
            <p class="list">Lack appreciation for the arts</p>
            <p class="list">Difficulty being imaginative</p>
            `,
            description20To40Percent: `
            You are moderately low in openness, one of the two aspects of openness-to-experience in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you have little interest in art, music, and other creative mediums. Visual,
            auditory, and other forms of beauty generally don’t resonate with you strongly. You don’t really
            characterize yourself as creative, and therefore don’t really require outlets for creative energy.<br/><br/>
            You don’t find beauty important and you’re happy in environments where it’s lacking.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your ability to fit into conventional roles</p>
            <p class="list">Your ability to fit into conventional roles</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Be unable to think up creative solutions to problems</p>
            <p class="list">Lack appreciation for the arts</p>
            <p class="list">Lack imagination</p>
            `,
            description40To60Percent: `
            You are typical or average in openness, one of the two aspects of openness-to-experience in
            the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are about as concerned with art, music, and other creative mediums as
            the average person. While you may moderately enjoy both, they aren’t driving forces in your life.
            While you can appreciate visual or other forms of beauty, you may not pick up on the nuances
            of them.<br/><br/>
            You find beauty important but environments where it’s lacking are tolerable for you.<br/><br/>
            Here are some tendencies you are likely to possess in similar amounts to the average person:<br/><br/>
            <p class="list">The need to be creative in your daily life</p>
            <p class="list">A sense of whimsy and imagination</p>
            <br/>
            The following areas will be a comfortable balance for you as an entrepreneur:<br/><br/>
            <p class="list">Appreciating beauty vs. not fixating on beauty</p>
            <p class="list">Daydreaming vs. keeping your feet firmly planted on the ground</p>
            `,
            description60To80Percent: `
            You are moderately high in openness, one of the two aspects of openness-to-experience in the
            Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are invested in the arts, music, and other forms of beauty. They are an
            important part of who you are and how you experience the world. You must feed your sense of
            creativity or you won’t feel fulfilled.<br/><br/>
            You look inward frequently and meaningfully and have a rich inner world. You are sensitive to
            aesthetics and draw inspiration from all five of your senses.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your creative vision</p>
            <p class="list">Your ability to imagine creative solutions to problems</p>
            <p class="list">Your reflective nature, which allows you to generally be intentional about your decisions
            and grow and improve yourself</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Lack practicality on some fronts</p>
            <p class="list">Potentially be too fixated on aesthetics and not focused enough on substance</p>
            `,
            description80To100Percent: `
            You are exceptionally high in openness, one of the two aspects of openness-to-experience in
            the Commit Action entrepreneurial traits model.<br/><br/>
            Entrepreneurs like you are deeply invested in the arts, music, and other forms of beauty. They
            are a critical part of who you are and how you experience the world. You must feed your
            creativity every day or you don’t feel whole.<br/><br/>
            You look inward frequently and meaningfully and have a powerful, rich inner world. Aesthetics
            are one of the most important parts of your life and you need to deeply stimulate your senses to
            power your creativity.<br/><br/>
            Here are some interesting tendencies you are likely to possess, that define your entrepreneurial
            “type” and separate you from the average person:<br/><br/>
            <p class="list">Your incredibly powerful creative vision</p>
            <p class="list">Your ability to imagine solutions to problems others might not be able to come up with</p>
            <p class="list">Your reflective nature, which allows you to be intentional about your decisions and grow
            and improve yourself</p>
            <br/>
            The challenges you’ll face in life and business stem from your tendencies to:<br/><br/>
            <p class="list">Lack practicality on some fronts</p>
            <p class="list">Potentially be too fixated on aesthetics and not focused enough on substance</p>
            `
          }
        ]
      }
    ],
    showWarning: false,
    showResult: false,
    showAssessmentReady: false,
    policyChecked: false,
    quizStarted: false,
    allPartsFinished: false
  },
  reducers: {
    advanceToNext: (state) => {
      if (state.answers[state.currentQuestion] !== undefined) {
        //@ts-ignore
        if (state.currentAnswer >= 0) state.answers[state.currentQuestion] = state.currentAnswer
      } else {
        //@ts-ignore
        if (state.currentAnswer >= 0) state.answers.push(state.currentAnswer)
        state.currentAnswer = -1
      }

      if (state.answers[state.currentQuestion + 1] !== undefined) {
        state.currentAnswer = state.answers[state.currentQuestion + 1]
      } else {
        state.currentAnswer = -1
      }

      if ((state.currentQuestion + 1) % state.questionsPerPart === 0) {
        state.currentPart += 1
      }

      state.currentQuestion += 1
    },
    advanceToNextPersonal: (state) => {
      if (state.personalAnswers[state.currentPersonalQuestion] !== undefined) {
        //@ts-ignore
        if (state.currentPersonalAnswer >= 0) state.personalAnswers[state.currentPersonalQuestion] = state.currentPersonalAnswer
      } else {
        //@ts-ignore
        if (state.currentPersonalAnswer >= 0) state.personalAnswers.push(state.currentPersonalAnswer)
        state.currentPersonalAnswer = -1
      }

      if (state.personalAnswers[state.currentPersonalQuestion + 1] !== undefined) {
        state.currentPersonalAnswer = state.personalAnswers[state.currentPersonalQuestion + 1]
      } else {
        state.currentPersonalAnswer = -1
      }

      state.currentPersonalQuestion += 1

      if (state.currentPersonalQuestion === state.personalQuestions.length) {
        state.parts[state.currentPart - 1].finished = true

        if (state.parts[state.parts.length - 1].finished) {
          state.allPartsFinished = true
        }
      }
    },
    restartProgress: (state, payload) => {
      state.answers = payload.payload.progress
      state.email = payload.payload.email
      state.quizStarted = true
      state.showWarning = true
      state.policyChecked = true
      state.currentAnswer = payload.payload.progress.length
      state.currentQuestion = payload.payload.progress.length

      const parts = Math.floor(payload.payload.progress.length / state.parts.length)

      for (let i = 0; i < parts; i++) {
        state.parts[i].finished = true
      }

      state.currentPart = parts
    },
    fallbackToPrev: (state) => {
      state.currentAnswer = state.answers[state.currentQuestion - 1]
      state.currentQuestion -= 1
    },
    fallbackToPrevPersonal: (state) => {
      state.currentPersonalAnswer = state.personalAnswers[state.currentPersonalQuestion - 1]
      state.currentPersonalQuestion -= 1
    },
    setShowWarning: (state) => {
      state.showWarning = true
    },
    setAssessmentReady: (state) => {
      state.showAssessmentReady = true
    },
    setShowResult: (state) => {
      state.showResult = true
    },
    setPolicyChecked: (state, payload) => {
      state.policyChecked = !!payload.payload
    },
    setQuizStarted: (state) => {
      state.quizStarted = true
    },
    setCurrentAnswer: (state, payload) => {
      state.currentAnswer = parseInt(payload.payload)
    },
    setCurrentPersonalAnswer: (state, payload) => {
      state.currentPersonalAnswer = parseInt(payload.payload)
    },
    setEmail: (state, payload) => {
      state.email = payload.payload
    },
    setPdfCreated: (state) => {
      state.pdfCreated = true
    },
    setPhoneData: (state, payload) => {
      state.phoneNumber = payload.payload.phone
      state.phoneCountryCode = payload.payload.phoneCountryCode
    },
    finishCurrentPart: (state) => {
      state.parts[state.currentPart - 1].finished = true

      if (state.parts[state.parts.length - 1].finished) {
        state.allPartsFinished = true
      }
    },
    calculateResult: (state) => {
      const resultsByCat: any = {}

      state.answers.forEach((answer, i) => {
        const splitted = state.questions[i].split('|')

        if (!resultsByCat[splitted[1]]) {
          resultsByCat[splitted[1]] = []
        }

        if (!resultsByCat[splitted[2]]) {
          resultsByCat[splitted[2]] = []
        }
        let ans = answer + 1

        if (splitted[3] === '-') {
          switch (ans) {
          case 1:
            ans = 5
            break
          case 2:
            ans = 4
            break
          case 3:
            ans = 3
            break
          case 4:
            ans = 2
            break
          case 5:
            ans = 1
            break
          }
        }

        resultsByCat[splitted[1]].push(ans)
        resultsByCat[splitted[2]].push(ans)
      })

      for (const key in resultsByCat) {
        const answersInCat = resultsByCat[key]
        const percent = Math.round(answersInCat.reduce((a: number, b: number) => a + b, 0) / answersInCat.length / 5 * 100)

        state.result.forEach((category) => {
          if (category.title === key) {
            category.percent = percent
            return
          } else if (category.inner[0].title === key) {
            category.inner[0].percent = percent
            return
          } else if (category.inner[1].title === key) {
            category.inner[1].percent = percent
            return
          }
        })
      }
    }
  }
})

export const { advanceToNext, setPdfCreated, restartProgress, setPhoneData, setShowWarning, setPolicyChecked, setQuizStarted, setEmail, setCurrentAnswer, advanceToNextPersonal, fallbackToPrev, fallbackToPrevPersonal, finishCurrentPart, calculateResult, setAssessmentReady, setShowResult, setCurrentPersonalAnswer } = Quiz.actions
export default Quiz.reducer
