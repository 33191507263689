import { useRouter } from 'next/router'

export function useQuery(path: string, query?: any) {
  const router = useRouter()

  // We need to delete [slug], [page]. So it will not be presented in url
  delete router.query.slug
  delete router.query.page

  if (query) {
    return {
      pathname: path,
      query: {...router.query, ...query}
    }
  }

  return {
    pathname: path,
    query: {...router.query}
  }
}

export function buildQueryString(queryObj: any) {
  let string = '?'

  for (const key in queryObj) {
    string += key + '=' + queryObj[key] + '&'
  }

  return string.slice(0, string.length - 1)
}
