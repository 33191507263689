import { fetchWithTimeout, fetchWithTimeoutErrorHandling } from '@/utils/http'
import qs from 'qs'

export class CouponService {
  static async check(coupon: string, planType: string) {
    const res = await fetchWithTimeoutErrorHandling(`${process.env.NEXT_PUBLIC_BACKEND_URL}/coupon/check`, {
      body: JSON.stringify({
        coupon: coupon,
        planType: planType
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST'
    })

    return res
  }
  static async getMeta(coupon: string) {
    const query = qs.stringify({
      filters: {
        slug: {
          $eq: coupon,
        }
      }
    }, {
      encodeValuesOnly: true
    })

    const res = await fetchWithTimeout(`${process.env.NEXT_PUBLIC_CMS_API_URL}/coupons?${query}`, {})
    const data = await res.json()

    if (data.data && data.data.length > 0) {
      return data.data[0].attributes
    }

    return {}
  }
}
