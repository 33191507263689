import { createSlice } from '@reduxjs/toolkit'

const jobApplication = createSlice({
  name: 'jobApplication',
  initialState: {
    activeStep: 1,
    touchedSteps: {
      '1': true,
      '2': false,
      '3': false
    },
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    email: '',
    phone: '',
    city: '',
    state: { value: 'NY', label: 'New York' },
    region: '',
    zip: '',
    country: { value: 'US', label: 'United States' },
    howFound: '',
    hourlyRate: '',
    startDate: '',
    expectations: '',
    dreamJob: '',
    organized: '',
    unwind: '',
    decision: '',
    experiences: '',
    enjoyed: '',
    proud: '',
    managed: '',
    situation: '',
    resume: null
  },
  reducers: {
    resetState: (state) => {
      state.activeStep = 1
      state.touchedSteps = {
        '1': true,
        '2': false,
        '3': false
      }
      state.firstName = ''
      state.lastName = ''
      state.address1 = ''
      state.address2 = ''
      state.email = ''
      state.phone = ''
      state.city = ''
      state.state = { value: 'NY', label: 'New York' }
      state.region = ''
      state.zip = ''
      state.country = { value: 'US', label: 'United States' }
      state.howFound = ''
      state.hourlyRate = ''
      state.startDate = ''
      state.expectations = ''
      state.dreamJob = ''
      state.organized = ''
      state.unwind = ''
      state.decision = ''
      state.experiences = ''
      state.enjoyed = ''
      state.proud = ''
      state.managed = ''
      state.situation = ''
      state.resume = null
    },
    setPersonalData: (state, action) => {
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
      state.address1 = action.payload.address1
      state.address2 = action.payload.address2
      state.region = action.payload.region
      state.phone = action.payload.phone
      state.email = action.payload.email
      state.city = action.payload.city
      state.zip = action.payload.zip
    },
    setQuestionsOneData: (state, action) => {
      state.howFound = action.payload.howFound
      state.hourlyRate = action.payload.hourlyRate
      state.startDate = action.payload.startDate
      state.expectations = action.payload.expectations
      state.dreamJob = action.payload.dreamJob
      state.organized = action.payload.organized
      state.unwind = action.payload.unwind
    },
    setQuestionsTwoData: (state, action) => {
      state.decision = action.payload.decision
      state.experiences = action.payload.experiences
      state.enjoyed = action.payload.enjoyed
      state.proud = action.payload.proud
      state.managed = action.payload.managed
      state.situation = action.payload.situation
      state.resume = action.payload.resume[0]
    },
    setCountry: (state, action) => {
      state.country = action.payload
    },
    setState: (state, action) => {
      state.state = action.payload
    },
    activateStep: (state, action) => {
      state.activeStep = action.payload
    },
    setTouchedStep: (state, action) => {
      state.touchedSteps[action.payload as '1' | '2' | '3'] = true
    }
  }
})

export const { setPersonalData, setCountry, setState, activateStep, setTouchedStep, setQuestionsOneData, setQuestionsTwoData, resetState } = jobApplication.actions
export default jobApplication.reducer
