import styles from '@/styles/components/common/Menu.module.scss'
import { useMobileMenu } from '@/context/MobileMenuContext'
import { useState } from 'react'

interface Props {
  alwaysVisible?: boolean,
  withText?: boolean,
  textColor?: string,
  menuColor?: string,
  smallMenu?: boolean,
  customCallback?: () => void
}

export const Menu: React.FC<Props> = ({
  alwaysVisible = false,
  withText = false,
  smallMenu = false,
  textColor = '',
  menuColor = '',
  customCallback
}) => {
  const { toggle } = useMobileMenu()
  const [hovered, setHovered] = useState('')

  return (
    <>
      { withText &&
        <div className={`${hovered} menu_wrapper h-[50px] items-center flex cursor-pointer`} onMouseLeave={() => {setHovered('')}} onMouseEnter={() => {setHovered('hovered')}}>
          <span className="text-[14px] pr-[10px] font--accent font-semibold" style={{color: textColor}} onClick={customCallback || toggle}>Menu</span>
          <div className={`${styles.menu} ${smallMenu ? styles.menu_small : ''} ${alwaysVisible ? styles.menu_visible : ''}`} onClick={customCallback || toggle}>
            <span style={{backgroundColor: menuColor}}></span>
            <span style={{backgroundColor: menuColor}}></span>
          </div>
        </div>
      }
      { !withText &&
        <div className={`${styles.menu} ${smallMenu ? styles.menu_small : ''} ${alwaysVisible ? styles.menu_visible : ''}`} onClick={customCallback || toggle}>
          <span></span>
          <span></span>
        </div>
      }
    </>
  )
}
