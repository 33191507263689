import { useState, useEffect } from 'react'

export function usePinable(offsetTop: number): boolean {
  const [isPinned, setPinnedState] = useState(false)

  useEffect(() => {
    const onScroll = () => {
		  if (window.pageYOffset > offsetTop) {
			  setPinnedState(true)
		  } else {
			  setPinnedState(false)
		  }
    }

    window.addEventListener('scroll', onScroll, { passive: true })

    return () => window.removeEventListener('scroll', onScroll)
  }, [offsetTop])

  return isPinned
}
