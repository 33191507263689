import '@/styles/app.scss'
import type { AppProps } from 'next/app'
import { MobileMenuProvider } from '@/context/MobileMenuContext'
import { ToastContainer } from 'react-toastify'
import { PrimaryLayout } from '@/layouts/PrimaryLayout'
import store from '@/store/index'
import { Provider } from 'react-redux'
import Head from 'next/head'
import 'react-toastify/dist/ReactToastify.css'
import { useEffect } from 'react'
import { TagManager } from '@/lib/gtm/TagManager'
import { EventsMediator } from '@/mediators/EventsMediator'
import { emitter } from '@/utils/emitter'
import Cookies from 'js-cookie'
import { Utk } from '@/lib/hbutk'
import Script from 'next/script'
import { useRouter } from 'next/router'
import queryString from 'query-string'

declare const window: any
declare const Matomo: any
declare const Piwik: any

EventsMediator.init()

function initGTM() {
  if (window.gtmDidInit) {
    return false
  }

  window.gtmDidInit = true

  // @ts-ignore
  TagManager.initialize({
    gtmId: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID,
    auth: process.env.NEXT_PUBLIC_GTM_AUTH,
    preview: process.env.NEXT_PUBLIC_GTM_ENV,
    url: process.env.NEXT_PUBLIC_GTM_URL
  })

  emitter.emit('gtm-loaded')
}

function initGTMOnEvent(event: any) {
  initGTM()
  event.currentTarget.removeEventListener(event.type, initGTMOnEvent)
}

function MyApp({ Component, pageProps }: AppProps) {
  // @ts-ignore
  const getLayout = Component.getLayout || ((page) => page)
  const router = useRouter()

  useEffect(() => {
    setTimeout(initGTM, 5000)
    document.addEventListener('scroll', initGTMOnEvent)
    document.addEventListener('mousemove', initGTMOnEvent)
    document.addEventListener('touchstart', initGTMOnEvent)

    setTimeout(() => {
      const hubspotutk = Cookies.get('hubspotutk')

      // If hb was blocked by extension, generate it by own method
      if (!hubspotutk) {
        const utk = new Utk().uuid()
        Cookies.set('hubspotutk', utk, { domain: '.' + process.env.NEXT_PUBLIC_RELATIVE_URL, expires: 183 })
      }
    }, 6000)
  }, [])

  useEffect(() => {
    document.body.className += ' opacity-0'

    setTimeout(() => {
      document.body.classList.remove('opacity-0')
    }, 500)
  }, [])

  useEffect(() => {
    let currentUrl = location.href
    let currentPathname = location.pathname

    router.events.on('routeChangeComplete', (url) => {
      if (url === currentPathname) return

      window._paq.push(['setUserId', Cookies.get('matomoUserID')])
      window._paq.push(['setReferrerUrl', currentUrl])

      currentUrl = window.location.href
      currentPathname = window.location.pathname

      window._paq.push(['setCustomUrl', currentUrl])
      window._paq.push(['setDocumentTitle', document.title])
      window._paq.push(['setCookieDomain', `${process.env.NEXT_PUBLIC_MATOMO_DOMAIN1}`])
      window._paq.push(['setDomains', [`${process.env.NEXT_PUBLIC_MATOMO_DOMAIN1}`,`${process.env.NEXT_PUBLIC_MATOMO_DOMAIN2}`]])
      window._paq.push(['enableLinkTracking'])
      window._paq.push(['enableHeartBeatTimer', 10])

      window._paq.push(['trackPageView'])
    })
  }, [])

  function renderMatomoScript() {
    return <Script id="matomo-script">
      {`
        var _paq = window._paq = window._paq || [];

        function getCookieMatomo(name) {
          var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
          if (match) return match[2];
        }

        function isDoNotTrackMeDudeQueryStringSet() {
          var queryStringArray = window.location.search.substr(1).split('&');
          return queryStringArray.includes("doNotTrackMeDude")
        }

        function isTrackMeDudeQueryStringSet() {
          var queryStringArray = window.location.search.substr(1).split('&');
          return queryStringArray.includes("trackMeDude")
        }

        if (isDoNotTrackMeDudeQueryStringSet()) _paq.push(['optUserOut']);
        if (isTrackMeDudeQueryStringSet()) _paq.push(['forgetUserOptOut']);

        _paq.push(['setUserId', getCookieMatomo('matomoUserID')]);
        _paq.push(['enableLinkTracking']);
        _paq.push(['setDocumentTitle', document.title])
        _paq.push(['setCookieDomain', '${process.env.NEXT_PUBLIC_MATOMO_DOMAIN1}'])
        _paq.push(['setDomains', ['${process.env.NEXT_PUBLIC_MATOMO_DOMAIN1}','${process.env.NEXT_PUBLIC_MATOMO_DOMAIN2}']])
        _paq.push(['enableHeartBeatTimer', 10])

        _paq.push(['trackPageView']);

        (function() {
          var u="${process.env.NEXT_PUBLIC_MATOMO_HOST}";
          _paq.push(['setTrackerUrl', u+'matomo.php']);
          _paq.push(['setSiteId', '1']);
          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
        })();
      `}
    </Script>
  }

  function renderMatomoABTestScriptImperatively() {
    const Experiment = Matomo.AbTesting.Experiment

    new Experiment({
      name: 'A-A-Test',
      percentage: 100,
      includedTargets: [{'attribute':'url','inverted':'0','type':'any','value':''}],
      excludedTargets: [],
      trigger: function () {
        return true // here you can further customize which of your visitors will participate in this experiment
      },
      variations: [
        {
          name: 'original',
          activate: function () {
            //
          }
        },
        {
          name: 'a-variant',
          activate: function() {
            //
          }
        }
      ],
    })
  }

  function renderMatomoTagManager() {
    return <Script id="matomo-tg">
      {`
        var _mtm = window._mtm = window._mtm || [];
        _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='${process.env.NEXT_PUBLIC_MATOMO_HOST}js/${process.env.NEXT_PUBLIC_MATOMO_GT_SCRIPT}'; s.parentNode.insertBefore(g,s);
      `}
    </Script>
  }

  useEffect(() => {
    if ('object' === typeof Piwik && 'object' === typeof Matomo.AbTesting) {
      // if matomo.js was embedded before this code
      renderMatomoABTestScriptImperatively()
    } else {
      // if matomo.js is loaded after this code
      window.matomoAbTestingAsyncInit = renderMatomoABTestScriptImperatively
    }
  }, [])

  // @ts-ignore
  if (Component.getLayout) {
    return (
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
        </Head>
        <Provider store={store}>
          <MobileMenuProvider>
            {getLayout(<Component {...pageProps} />)}
            <ToastContainer theme="dark" position="bottom-right" />
          </MobileMenuProvider>
        </Provider>
        {renderMatomoScript()}
        {renderMatomoTagManager()}
      </>
    )
  }

  // @ts-ignore
  const pageClass = Component.getClass && Component.getClass()

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" />
      </Head>
      <Provider store={store}>
        <MobileMenuProvider>
          <PrimaryLayout className={pageClass}>
            <Component {...pageProps} />
            <ToastContainer theme="dark" position="bottom-right" />
          </PrimaryLayout>
        </MobileMenuProvider>
      </Provider>
      {renderMatomoScript()}
      {renderMatomoTagManager()}
    </>
  )
}

export default MyApp
