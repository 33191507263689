import styles from '@/styles/components/controls/CrossBtn.module.scss'

interface Props {
  color?: string,
  id?: string,
  className?:  string,
  onClick?(): void
}

export const CrossBtn: React.FC<Props> = ({className = '', id = '', color = 'black', onClick }) => {

  const setColor = () => {
    switch (color) {
    case 'black':
      return styles.cross_btn_item_black
    case 'white':
      return styles.cross_btn_item_white
    case 'gold':
      return styles.cross_btn_item_gold
    default:
      return styles.cross_btn_item_black
    }
  }

  return (
    <button
      id={id}
      className={`${styles.cross_btn} ${className || ''}`}
      onClick={onClick}
    >
      <span className={`${styles.cross_btn_item} ${setColor()}`}></span>
      <span className={`${styles.cross_btn_item} ${setColor()}`}></span>
    </button>
  )
}
