import { useState, useEffect } from 'react'
import { fetchWithTimeoutErrorHandling, fetchWithRetries } from '@/utils/http'
import { useDispatch, useSelector } from 'react-redux'
import { setClientLocation } from '@/store/slices/clientLocation'
import { RootState } from '@/store/reducers'

export function useClientLocation() {
  const clientLocationState = useSelector((state: RootState) => state.clientLocation)
  const dispatch = useDispatch()

  useEffect(() => {

    const getLocation = async () => {
      if (clientLocationState.loaded) return

      const result = await fetchWithRetries(`${process.env.NEXT_PUBLIC_BACKEND_URL}/ipdata`, {
        timeout: 4000
      })

      if (!result || result.error || result.statusCode >= 400) {
        console.error('No response', result)
        dispatch(setClientLocation({ country_code: 'US', country_name: '', calling_code: '', is_eu: false, timezone: null }))

        return
      }

      dispatch(setClientLocation(result))
    }

    getLocation()
  }, [])

  return clientLocationState
}
